import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import apiUrl from "../config/apiPath";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, verifyEmail } from '../Utils/utils'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import queryString from 'query-string';
import { Translate, I18n } from "react-redux-i18n";
const Verify = (props) => {
  const history = useHistory();

  useEffect(async () => {
    props.toggleLoader(true);
    const token = queryString.parse(window.location.search);
    console.log(token.reset_token);
    var verify_email = await verifyEmail(token.reset_token)
    console.log(token);
    if (verify_email === true) {
      displaySuccess(I18n.t('Email Verified successfully! Please login to continue'));
      setTimeout(() => history.push("/"), 3000);
    }
    else {
      setTimeout(() => history.push("/register"), 3000);
    }
    props.toggleLoader(false);
  }, [])
  return (
    <>
      <body className="bg-gradient-primary">
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-12 login-bg-img" style={{ backgroundImage: 'url("img/bg15-l.jpg")' }}>
                    <div className="login-intro">
                      <h1>{I18n.t('Welcome To On Global')}</h1>
                      <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada.</p>
                      <div className="login-page-switcher">
                        <Link to="/" className="switcher-text1 ">{I18n.t('Log In')}</Link>
                        <Link to="/register" className="switcher-text1 active">{I18n.t('Register')}</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 login-bg-color">
                    <div className="login-content">
                      <div className="login-header">
                        <a href="#" className="login-logo">
                          <img src="img/logo.png" alt="Logo" />
                        </a>
                      </div>
                      <div className="login-form">
                        <h2>{I18n.t('Register')}</h2>
                        <p>{I18n.t('Register to continue in our website')}</p>
                        <div className="form-group">
                          <input type="text" className="form-control form-control-user" id="exampleInputFirstName"
                            aria-describedby="firstName" placeholder={I18n.t('Enter First Name')} />
                        </div>
                        <div className="form-group">
                          <input type="text" className="form-control form-control-user" id="exampleInputLastName"
                            aria-describedby="lasName" placeholder={I18n.t('Enter Last Name')} />
                        </div>
                        <div className="form-group">
                          <input type="email" className="form-control form-control-user" id="exampleInputEmail"
                            aria-describedby="emailHelp" placeholder={I18n.t('Enter Email Address')} />
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control form-control-user" id="exampleInputPassword"
                            placeholder={I18n.t('Password')} />
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control form-control-user" id="exampleInputPassword"
                            placeholder={I18n.t('Confirm Password')} />
                        </div>
                        <button className="btn btn-primary btn-user btn-block">
                          {I18n.t('Register')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Verify);