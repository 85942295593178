import '../ForgotPassword/forgetpassword.css';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess } from '../Utils/utils'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import queryString from 'query-string';
import { Translate, I18n } from "react-redux-i18n";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [token, setToken] = useState("");



  const resetPassword = async (props) => {
    // props.toggleLoader(true);
    let path = config.siteUrl;
    const token = queryString.parse(window.location.search);
    await axios.post(path + '/password/change', {
      password: password,
      password_confirmation: password_confirmation,
      token: token.token,
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        // props.toggleLoader(false);
        displaySuccess(I18n.t('Password Reset Successfully'));
        setTimeout(() => window.location.href = "/", 3000);
      })
      .catch((error) => {
        // props.toggleLoader(false);
        displayError(error);
      });
  };
  return (
    <>
      <body className="bg-gradient-primary">
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-12 login-bg-img" style={{ backgroundImage: 'url("/img/bg15-l.jpg")' }}>
                    <div className="login-intro">
                      <h1>{I18n.t('Welcome To On Global')}</h1>
                      <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada.</p>
                      <div className="login-page-switcher">
                        <Link to="/" className="switcher-text1">{I18n.t('Log In')}</Link>
                        <Link to="/register" className="switcher-text1">{I18n.t('Register')}</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 login-bg-color">
                    <div className="login-content">
                      <div className="login-header">
                        <a href="#" className="login-logo">
                          <img src="/img/logo.png" alt="Logo" />
                        </a>
                      </div>
                      <div className="login-form">
                        <h2>{I18n.t('Change Password')}</h2>
                        <br />
                        <div className="form-group">
                          <input type="password" className="form-control form-control-user" id="exampleInputEmail"
                            aria-describedby="emailHelp" placeholder={I18n.t('New Password')} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control form-control-user" id="exampleInputEmail"
                            aria-describedby="emailHelp" placeholder={I18n.t('Confirm Password')} onChange={e => setPasswordConfirmation(e.target.value)} />
                        </div>
                        <button className="btn btn-primary btn-user btn-block" onClick={resetPassword}>
                          {I18n.t('Reset')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);