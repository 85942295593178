import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import config from "../config/config";
import axios from "axios";
import { crossSellAssignList, displayError, displaySuccess, getCrossSellEvent } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import Alert from 'react-bootstrap/Alert'
import SweetAlert from 'react-bootstrap-sweetalert';

const CrossSellEvents = (props) => {
  const [list, setList] = useState([]);
  const [tempVarId, setTempVarId] = useState('');
  const [is_delete, setIsDelete] = useState(false);

  useEffect(async () => {
    props.toggleLoader(true);
    var getCross_var = await getCrossSellEvent();
    setList(getCross_var);
    props.toggleLoader(false);
  }, [])

  const deleteCrossSellEvent = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/cross_sell/assignment/delete/" + tempVarId, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess('Cross Sell Assignment Event Deleted Successfully');
        var getCross_var = await getCrossSellEvent();
        setList(getCross_var);
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="row justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Cross Sell Assignments Event')}</h1>
                  </div>
                  <div className="col-auto ml-auto d-none d-sm-inline-block d-block d-sm-none">
                    <div className="form-row">
                      <div className="col">
                        <Link to="/add-cross-sell-assignment-event">
                          <a className=" btn btn-light">
                            <i className="fas fa-plus fa-sm" /> {I18n.t('New Cross Sell Assignments Events')}</a></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card">

                      <div className="card-body">
                        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                        </div>
                        <div className="table-responsive">
                          <Table className="table align-items-center mb-0">
                            <Thead>
                              <Tr>
                                <Th>{I18n.t('Cross Sell')}</Th>
                                <Th>{I18n.t('Live Events')}</Th>
                                <Th>{I18n.t('Action')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(list.length === 0) ?
                                <Tr>
                                  <Td colspan="7" style={{ textAlign: 'center' }}>
                                    <Alert key={'alert'} variant={'info'}>
                                      {I18n.t('No CrossSell Assignments Event')}
                                    </Alert>
                                  </Td>
                                </Tr> : ''}
                              {list.map((info) => (
                                <Tr>
                                  <Td>{info.cross_sell.title}</Td>
                                  <Td>{info.event.title}</Td>
                                  <Td>
                                    <div className>
                                      <Link to={"/edit-cross-sell-assignment-event/" + info.id}title={I18n.t("Edit")}>
                                        <a className="items-center m-1 btn-outline-dark btn btn-sm">
                                          <i className="far fa-edit"></i>
                                        </a>
                                      </Link>
                                      <a className="flex items-center m-1 btn-outline-danger btn btn-sm"
                                        onClick={e => {
                                          setTempVarId(info.id)
                                          setIsDelete(true)
                                        }}title={I18n.t("Delete")}
                                      >
                                        <i className="far fa-trash-alt"></i>
                                      </a>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                      {is_delete === true ? (
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes"
                          confirmBtnBsStyle="danger"
                          title={I18n.t('Are you sure?')}
                          onConfirm={(e) => {
                            deleteCrossSellEvent()
                            setIsDelete(false)
                            // window.location.reload(false)
                          }}
                          onCancel={(e) => {
                            setIsDelete(false)
                            setTempVarId('')
                          }}
                          focusCancelBtn
                        >
                          {I18n.t('Do you want to delete this Cross Sell Assignment Event?')}
                        </SweetAlert>
                      ) : ([]
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body >
    </>
  )
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CrossSellEvents);