import React, { useState, useEffect } from 'react';
import config from "../config/config";
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { displayError, displaySuccess, getApiKeys } from '../Utils/utils'
import { connect } from 'react-redux';
import { Translate, I18n } from "react-redux-i18n";

const Integeration = (props) => {
  const [api_key, setApiKey] = useState('');
  const [api_token, setApiToken] = useState('');
  const [user_id, setUserId] = useState('');
  const [deleteToken, setDeleteToken] = useState(false);

  const addApiKey = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/instructor/token', {
      api_key: api_key,
      api_token: api_token,
      //  user_id: user_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Token Stored Successfully');
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        // if (error.response.status === 401) {
        //   window.location.href = '/';
        // }
        displayError(error);
      });
  }

  useEffect(async () => {
    var get_api_key = await getApiKeys();
    if (get_api_key != null) {
      setApiKey(get_api_key.api_key);
      setApiToken(get_api_key.api_token);
      setDeleteToken(true);
    }
  }, [])

  const deleteKey = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + '/instructor/token/delete', {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async function (response) {
        props.toggleLoader(false);
        displaySuccess('Token Deleted Successfully');
        setTimeout(() => window.location.reload(), 1500);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  return (
    <>
      <div className="">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="card mb-4 radius-0">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-md-6 mb-2">
                    <label className="form-label" for="validationCustom01">{I18n.t('API key')}</label>
                    <input className="form-control" id="validationCustom01" type="text" value={api_key} required=""
                      data-bs-original-title="" title="" onChange={e => setApiKey(e.target.value)} />
                  </div>

                  <div className="col-md-6 mb-2">
                    <label className="form-label" for="validationCustom01">{I18n.t('API Token')}</label>
                    <input className="form-control" id="validationCustom01" type="text" value={api_token} required=""
                      data-bs-original-title="" title="" onChange={e => setApiToken(e.target.value)} />
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-md-12">
                    <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" onClick={e => addApiKey()}>
                      <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}</button>

                    {deleteToken === true ?
                      <button className="btn btn-danger ml-3" type="submit" data-bs-original-title="" title="" onClick={e => deleteKey()}>
                        <i className="fa fa-trash" aria-hidden="true"></i> {I18n.t('Delete Token')}</button>
                      :
                      <button className="btn btn-danger" type="submit" data-bs-original-title="" title="" onClick={e => deleteKey()}
                        hidden > <i className="fa fa-trash" aria-hidden="true"></i> {I18n.t('Delete Token')}</button>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Integeration);