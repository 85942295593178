import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleLoader } from '../../actions/loader';
import { dashboardCards } from '../../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";

const Cards = (props) => {
  const [card, setCard] = useState([]);

  useEffect(async () => {
    props.toggleLoader(true);
    var getCounts_var = await dashboardCards();
    setCard(getCounts_var);
    props.toggleLoader(false);
  }, [])

  return (
    <>

      <div className="form-row mb-3">
        <div className="col-xl-4 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="h1 font-weight-bold text-gray-800 mb-1 mt-3">
                    {card.total_offer_count}
                  </div>
                  <div className="text-xs">{I18n.t('Total Offers')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="h1 font-weight-bold text-gray-800 mb-1 mt-3">
                    {card.total_offer_booking}
                  </div>
                  <div className="text-gray-800">{I18n.t('Live Events')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="h1 font-weight-bold text-gray-800 mb-1 mt-3">
                    {card.total_ratings}
                  </div>
                  <div className="text-gray-800">{I18n.t('Total Ratings')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="h3 font-weight-bold text-gray-800 mb-1 mt-3">{card.total_revenue}</div>
                  <div className="text-xs">
                    {I18n.t('Total Revenue')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Cards);