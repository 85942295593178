import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import TopNav from '../components/TopNav';
import '../Interactivity/interaction.css';
import config from "../config/config";
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { displayError, displaySuccess, getOffer, getModule, getTopic } from '../Utils/utils'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
const AddQuestion = (props) => {
  const [user_id, setUserId] = useState(null);
  const [is_anonymous, setIsAnonymous] = useState(0);
  const [offer_id, setOfferId] = useState('');
  const [offers, setOffers] = useState([]);
  const [module_id, setModuleId] = useState('');
  const [modules, setModules] = useState([]);
  const [topic_id, setTopicId] = useState('');
  const [topics, setTopics] = useState([]);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const addQues = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/instructor/interactivity/overall', {
      offer_id: offer_id,
      module_id: module_id,
      topic_id: topic_id,
      question: question,
      answer: answer,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Question Added Successfully');
        setTimeout(() => window.location.href = "/interactivity/add-question", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  useEffect(async () => {
    props.toggleLoader(true);
    var get_offers = await getOffer();
    setOffers(get_offers);
    console.log(get_offers);
    props.toggleLoader(false);
  }, [])

  const fetchModules = async (offer_id) => {
    props.toggleLoader(true);
    var get_modules = await getModule(offer_id);
    setModules(get_modules);
    setOfferId(offer_id);
    props.toggleLoader(false);
  }
  const fetchTopics = async (module_id) => {
    props.toggleLoader(true);
    var get_topics = await getTopic(module_id);
    setTopics(get_topics);
    setModuleId(module_id);
    props.toggleLoader(false);
  }
  const checked_anonymous = () => {
    setIsAnonymous(1);
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">
          <Leftbar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Interactivity')}</h1>
                  </div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard" data-bs-original-title="" title="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                      </Link>
                    </li>
                    <li className="breadcrumb-item"><Link to="/interactivity">{I18n.t('Interactivity')}</Link></li>
                    <li className="breadcrumb-item active">{I18n.t('Add Question')}</li>
                  </ol>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom04">{I18n.t('Offers')} </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  fetchModules(e.target.value)
                                  setOfferId(e.target.value)
                                }} >
                                <option >{I18n.t('Select Offer')}</option>
                                {offers.map((list) =>
                                  <option value={list.id} /*selected={(list.id === offer_id) ? true : false}*/>{list.name}</option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom04">{I18n.t('Module')} </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  fetchTopics(e.target.value)
                                  setModuleId(e.target.value)
                                }}  >
                                <option>{I18n.t('Select Module')}</option>
                                {modules.map((data) =>
                                  <option value={data.id} selected={(data.id === module_id) ? true : false}>{data.title}</option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom04">{I18n.t('Topic')} </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => { setTopicId(e.target.value) }} >
                                <option>{I18n.t('Select Topic')}</option>
                                {topics.map((top) =>
                                  <option value={top.id} selected={(top.id === topic_id) ? true : false}>{top.title}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-lg-6 mb-2">
                              <label className="form-label" for="exampleFormControlTextarea9">{I18n.t('Question')}</label>
                              <textarea className="form-control mb-2" id="exampleFormControlTextarea9" rows="3"
                                onChange={e => setQuestion(e.target.value)} placeholder={I18n.t("Question")}></textarea>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <label className="form-label" for="exampleFormControlTextarea9">{I18n.t('Response')}</label>
                              <textarea className="form-control mb-2" id="exampleFormControlTextarea9" rows="3"
                                onChange={e => setAnswer(e.target.value)} placeholder={I18n.t("Response")}></textarea>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-5">
                              <button className="btn btn-primary btn-sm mb-2" type="submit" data-bs-original-title="" title=""
                                onClick={addQues} >
                                <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);