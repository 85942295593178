import React from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';

const CrossSellPreview = () => {
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-6">
                      <h3>New Cross Sell</h3>
                    </div>
                    <div className="col-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/cross-sell">Cross Sell</Link></li>
                        <li className="breadcrumb-item">New Cross Sell</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04"> Offer  </label>
                              <select className="form-control" id="validationCustom04" required="">
                                <option>Offer 1</option>
                                <option>Offer 2</option>
                                <option>Offer 3</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">Cross Sell Title</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="Cross Sell Title" required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">Price</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="Price" required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">% Discount</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="% Discount" required="" data-bs-original-title="" title="" />
                            </div>
                          </div>

                          <br />
                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">Payment Terms</label>
                              <select className="form-control" id="validationCustom04" required="">
                                <option >Single Payment</option>
                                <option>Installment</option>
                                <option>Monthly</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">Installment Quantity  </label>
                              <select className="form-control" id="validationCustom04" required="">
                                <option >1x</option>
                                <option>2x</option>
                                <option>3x</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">Interest Mode</label>
                              <select className="form-control" id="validationCustom04" required="">
                                <option>Paid by Customer</option>
                                <option>Paid by Producer</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">Adiantamento Recebíveis</label>
                              <select className="form-control" id="validationCustom04" required="">
                                <option>No</option>
                                <option>Yes</option>
                              </select>
                            </div>
                          </div>

                          <br />
                          <div className="row g-3">
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">Publish Date / Time</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio1">Immediately</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio2">Date / Time</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01"></label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="" required="" data-bs-original-title="" title="" style={{ marginTop: '50px' }} />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">End Date / Time</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio3" type="radio" name="radio2" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio3">Undefined</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio4" type="radio" name="radio2" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio4">Date / Time</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01"></label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="" required="" data-bs-original-title="" title="" style={{ marginTop: '50px' }} />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">Quantity of Customers</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio5" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio5">Unlimited</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio6" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio6">Limited</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01"></label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="" required="" data-bs-original-title="" title="" style={{ marginTop: '50px' }} />
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label className="form-label" for="validationCustom01">Banner Image</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder="Banner Image" required="" data-bs-original-title="" title="" />
                            </div>
                            <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                              <i className="fa fa-search" aria-hidden="true"></i>Preview </button>
                          </div>
                          <Link to="/new-cross-sell">
                            <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                              <i className="fa fa-undo" aria-hidden="true"></i> Close
                            </button></Link>

                          <div className="row g-3">
                            <div className="col-md-3">
                              <img src="img/certificate.jpeg" width="3000px" height="2500px" />                                                        </div>
                          </div>
                          <br />
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                            <i className="fa fa-undo" aria-hidden="true"></i> Return </button>
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                            <i className="fa fa-check" aria-hidden="true"></i> Save </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
export default CrossSellPreview;