import React from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const Content = () => {
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="row justify-content-between mb-3">
                  <div className="col">
                    <h1 className="h4 mb-0 title-top">Content</h1>
                  </div>
                  <div className="col-auto ml-auto d-sm-inline-block">
                    <div className="form-row">
                      <div className="col">
                        <Link to="/content-add">
                          <a className=" btn btn-primary">
                            <i className="fas fa-plus fa-sm" /> Add Content</a></Link>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">

                        <div className="table-responsive">
                          <Table className="table align-items-center">
                            <Thead>
                              <Tr>
                                <Th>S no.</Th>
                                <Th>Title</Th>
                                <Th>InfoProduct Name</Th>
                                <Th>Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                <Td>1</Td>
                                <Td>Title 1 </Td>
                                <Td>Product 1</Td>
                                <Td>
                                  <div className>
                                    <a className="flex items-center mr-2 text-dark btn btn-sm" href>
                                      <i className="far fa-edit" /> Edit</a>
                                    <a className="flex items-center btn btn-sm text-danger" href>
                                      <i className="far fa-trash-alt btn-outline-danger" /> Delete</a>
                                  </div>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>2</Td>
                                <Td>Title 2 </Td>
                                <Td>Product 2</Td>
                                <Td>
                                  <div className>
                                    <a className="flex items-center mr-2 text-dark btn btn-sm" href>
                                      <i className="far fa-edit" /> Edit</a>
                                    <a className="flex items-center btn btn-sm text-danger" href>
                                      <i className="far fa-trash-alt btn-outline-danger" /> Delete</a>
                                  </div>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>3</Td>
                                <Td>Title 3</Td>
                                <Td>Product 3 </Td>
                                <Td>
                                  <div className>
                                    <a className="flex items-center mr-2 text-dark btn btn-sm" href>
                                      <i className="far fa-edit" /> Edit</a>
                                    <a className="flex items-center btn btn-sm text-danger" href>
                                      <i className="far fa-trash-alt btn-outline-danger" /> Delete</a>
                                  </div>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>4</Td>
                                <Td>Title 4 </Td>
                                <Td>Product 4</Td>
                                <Td>
                                  <div className>
                                    <a className="flex items-center mr-2 text-dark btn btn-sm" href>
                                      <i className="far fa-edit" /> Edit</a>
                                    <a className="flex items-center btn btn-sm text-danger" href>
                                      <i className="far fa-trash-alt btn-outline-danger" /> Delete</a>
                                  </div>
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
export default Content;