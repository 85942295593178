import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLocaleWithFallback } from "../../actions/i18n"
const LanguageSwitcher = (props) => {
  const [default_language, setDefaultLanguage] = useState('pt');
  useEffect(() => {
    if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== '') {
      setDefaultLanguage(localStorage.getItem('langauge_selected'));
    } else {
      setDefaultLanguage("pt");
    }
  }, []);

  const handleLanguageLinkClick = (e) => {
    e.preventDefault();
    localStorage.setItem('langauge_selected', e.target.value);
    setDefaultLanguage(e.target.value);
    props.setLocaleWithFallback(e.target.value);
  };
  return (
    <select onChange={handleLanguageLinkClick} value={default_language}>
      <option value="en">English</option>
      <option value="pt">Português</option>
    </select>
  );
}
const mapStateToProps = state => ({ locale: state.i18n.locale });
const mapDispatchToProps = { setLocaleWithFallback };
export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);