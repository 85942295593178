import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import config from "../config/config";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, getModuleListing, moduleActive, reOrderModule } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from 'react-bootstrap/Alert'
import queryString from 'query-string';
import { Translate, I18n } from "react-redux-i18n";
const Module = (props) => {
  const [module_list, setModuleList] = useState([]);
  const [id, setId] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  const [is_active, setIsActive] = useState(0);
  const [product_id, setProductId] = useState('');


  useEffect(async () => {
    props.toggleLoader(true)
    var search_object = queryString.parse(window.location.search);
    var temp_product_id = '';
    if (search_object.product_id !== undefined) {
      temp_product_id = search_object.product_id;
      setProductId(search_object.product_id);
    } else {
      setProductId('');
    }
    var get_module_listing = await getModuleListing(temp_product_id);
    setModuleList(get_module_listing);
    props.toggleLoader(false)
  }, [window.location.search])

  const deleteModule = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + '/product/module/delete/' + id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async function (response) {
        props.toggleLoader(false);
        displaySuccess('Module Deleted Successfully');
        var get_module_listing = await getModuleListing();
        setModuleList(get_module_listing);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  const activeModule = async (id, is_active) => {
    props.toggleLoader(true);
    if (is_active === 1) {
      var active_var = await moduleActive(id, 0);
    }
    else {
      var active_var = await moduleActive(id, 1);
    }
    console.log(active_var);
    setIsActive(active_var);
    var get_module_listing = await getModuleListing();
    setModuleList(get_module_listing);
    props.toggleLoader(false);
  }

  const orderUp = async (order, id) => {
    //alert(order);
    props.toggleLoader(true);
    var order_module = await reOrderModule(order - 5, id);
    var get_module_listing = await getModuleListing(product_id);
    setModuleList(get_module_listing);
    props.toggleLoader(false);
  }
  const orderDown = async (order, id) => {
    //alert(order);
    props.toggleLoader(true);
    var order_module = await reOrderModule(order + 5, id);
    var get_module_listing = await getModuleListing(product_id);
    setModuleList(get_module_listing);
    props.toggleLoader(false);
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">

                <div className="row my-4">
                  <div className="col-6">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Content')}</h1>
                  </div>
                  <div className="col-6 mb-2 text-right">
                    <div className="form-row">
                      <div className="col">
                        {(product_id === '') ?
                          <Link to="/module-add">
                            <a className=" btn btn-light">
                              <i className="fas fa-plus fa-sm" /> {I18n.t('Module')}</a>
                          </Link>
                          :
                          <Link to="/module">
                            <a className=" btn btn-light">
                              {I18n.t('Reset')}</a>
                          </Link>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card m-overflow-h">
                      <div className="card-body m-p-0">

                        <div className="table-responsive">
                          <Table className="table align-items-center table-striped table-hover mb-0">
                            <Thead>
                              <Tr>
                                <th>{I18n.t('Sno.')}</th>
                                <th>{I18n.t('Infoproduct')}</th>
                                <th>{I18n.t('Module Name')}</th>
                                <th>{I18n.t('Free?')}</th>
                                {/* <th>Order</th> */}
                                <th>{I18n.t('Status')}</th>
                                <th>{I18n.t('Action')}</th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(module_list.length === 0) ? <Tr>
                                <Td colspan="7" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No data added')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {module_list.map((list, index) => (
                                <Tr>
                                  <Td>{++index}</Td>
                                  {list.product === null ?
                                    <Td> N/A</Td>
                                    :
                                    <Td className='font-weight-bold text-capitalize'>{list.product.title} </Td>
                                  }
                                  <Td>{list.title}</Td>
                                  <Td className='text-capitalize'>{(list.is_free_content) ? I18n.t('yes') : I18n.t('no')} </Td>
                                  {/* <Td>{list.order} </Td> */}
                                  <Td>{(list.is_active === 1) ? <span className='text-success'> {I18n.t('Active')} </span> : <span className='text-danger'> {I18n.t('Inactive')} </span>}</Td>
                                  <Td>
                                    {(product_id === '')
                                      ?

                                      <div className>
                                        <Link to={'/module-edit/' + list.id}    title={I18n.t("Edit Module")}>
                                          <a className="flex items-center m-1 btn-outline-dark btn btn-sm" href>
                                            <i className="far fa-edit" /></a></Link>
                                        <button className="flex items-center btn btn-sm  btn-outline-danger"
                                          onClick={e => {
                                            setId(list.id)
                                            setIsDelete(true)
                                          }} title={I18n.t("Delete Module")}>
                                          <i className="far fa-trash-alt" /> </button>
                                        <Link to={'new-topic/' + list.id} className="flex items-center btn btn-sm btn-outline-success m-1" title={I18n.t("New Topic")}>
                                          <i className="far fa-plus-square"></i></Link>
                                        <Link to={'/view-topic/' + list.id} className="flex items-center btn btn-sm m-1 btn-outline-info" title={I18n.t("View Topic")}>
                                          <i className="far fa-eye"></i></Link>
                                        <Link to={'/module/?product_id=' + list.product_id} className="flex items-center m-1 btn btn-sm btn-outline-warning" title={I18n.t("Sort Topics")}>
                                          <i class="fas fa-sort"></i></Link>
                                        <a className="btn btn-outline-primary btn-sm m-1" href
                                          onClick=
                                          {e => { activeModule(list.id, list.is_active) }}
                                        >
                                          {(list.is_active === 1) ? <i className="fas fa-pause" title={I18n.t("inactive Module")}/> : <i className="fa fa-play" title={I18n.t("active Module")}/>}
                                        </a>
                                      </div>

                                      : <>
                                        {(index > 1) ? <a
                                          className="btn btn-outline-primary btn-sm m-1"
                                          href="#"
                                          onClick={e => {
                                            var temp_index = index - 2;
                                            orderUp(module_list[temp_index].order, list.id);
                                          }}
                                        >
                                          <i class="fas fa-sort-up"></i>
                                        </a> : ''}
                                        {(index < module_list.length) ?
                                          <a
                                            className="btn btn-outline-primary btn-sm m-1"
                                            href="#"
                                            onClick={e => {
                                              orderDown(module_list[index].order, list.id);
                                            }}
                                          >
                                            <i class="fas fa-sort-down"></i>
                                          </a> : ''}
                                      </>}

                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {is_delete === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title={I18n.t('Are you sure?')}
            onConfirm={(e) => {
              deleteModule()
              setIsDelete(false)
            }}
            onCancel={(e) => {
              setIsDelete(false)
              setId('')
            }}
            focusCancelBtn
          >
            {I18n.t('Do you want to delete this module?')}
          </SweetAlert>
        ) : ([]
        )}
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Module);