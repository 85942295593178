import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import TopNav from '../components/TopNav';

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import config from "../config/config";
import axios from "axios";
import { getOfferListing, offerActive, displayError, displaySuccess, } from '../Utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Translate, I18n } from "react-redux-i18n";
import Alert from 'react-bootstrap/Alert'


const Offers = (props) => {
  const [offers, setOffers] = useState([]);
  const [is_active, setIsActive] = useState(0);
  const [tempVarId, setTempVarId] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  const [copied, setCopied] = useState('');
  const [slug, setSlug] = useState('');
  function copy(id, slug) {
    const el = document.createElement("input");
    el.value = "https://dutraforeducation.com.br/course-detail/" + id + "/" + slug;
    document.body.appendChild(el);
    el.select(id);
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied('');
  }

  useEffect(async () => {
    props.toggleLoader(true);
    var getOffer_var = await getOfferListing();
    console.log(getOffer_var);
    setOffers(getOffer_var);
    props.toggleLoader(false);
  }, [])

  const activeOffer = async (id, is_active) => {
    props.toggleLoader(true);
    if (is_active === 1) {
      var active_var = await offerActive(id, 0);
    }
    else {
      var active_var = await offerActive(id, 1);
    }
    console.log(active_var);
    setIsActive(active_var);
    var getOffer_var = await getOfferListing();
    setOffers(getOffer_var);
    props.toggleLoader(false);
  }

  const deleteOffer = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + "/product/offer/delete/" + tempVarId, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        // props.toggleLoader(false);
        displaySuccess('Deleted Successfully');
        // props.toggleLoader(true);
        var getOffer_var = await getOfferListing();
        setOffers(getOffer_var);
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid ">
                <div className="row">
                  <div className="col-6 mb-3">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Offers')}</h1>
                  </div>

                  <div className="col-6 mb-3">
                    <div className="form-row text-right">
                      <div className="col">
                        <Link to="/offers-add">
                          <a className=" btn btn-light">
                            <i className="fas fa-plus fa-sm" /> {I18n.t('New Offer')}</a></Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* heading */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body m-p-0">
                        <div className="table-responsive">
                          <Table className="table align-items-center mb-0">
                            <Thead>
                              <Tr>

                                <Th>{I18n.t('Offers')}</Th>
                                <Th>{I18n.t('Value')}</Th>
                                <Th>{I18n.t('Method')}</Th>
                                <Th>{I18n.t('Installment')}</Th>
                                <Th>{I18n.t('Renew')}</Th>
                                <Th>{I18n.t('Status')}</Th>
                                <Th>{I18n.t('Action')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(offers.length === 0) ? <Tr>
                                <Td colspan="7" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No Offers')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {offers.map((list) => (
                                <Tr >

                                  <Td className="course-name">
                                    <h5 className="mb-0 font-weight-bold">{list.name}</h5>

                                  </Td>

                                  <Td>
                                    <div className="Instrutor-name">{list.price}</div>
                                  </Td>
                                  <Td className="course-name">
                                    <h5 className="mb-0 font-weight-bold">{list.payment_method}</h5>

                                  </Td>
                                  <Td>
                                    <div className="">{list.installment_quantity}</div>
                                  </Td>
                                  <Td>
                                    <div className="">{(list.renew === 0) ? I18n.t('No') : I18n.t('Yes')}</div>
                                  </Td>
                                  <Td>
                                    {(list.is_active === 1) ? <span className='text-success'> {I18n.t('Active')} </span> : <span className='text-danger'> {I18n.t('Inactive')} </span>}
                                  </Td>
                                  <Td>
                                    <div className="">
                                      <Link to={'/edit-offer/' + list.id} title={I18n.t("Edit")}>
                                        <a className="items-center m-1 btn-outline-dark btn btn-sm">
                                          {<i className="far fa-edit"></i> /* {I18n.t('Edit')} */}
                                        </a>
                                      </Link>
                                      <a className="flex items-center m-1 btn-outline-danger btn btn-sm"
                                        onClick={e => {
                                          setTempVarId(list.id)
                                          setIsDelete(true)
                                        }} title={I18n.t("Delete")}>
                                        <i className="far fa-trash-alt"></i>
                                        {/* {I18n.t('Delete')} */}
                                      </a>
                                      <a className="flex items-center m-1 " title="" onClick={e => {
                                        copy(list.id, list.slug)
                                        setCopied(list.id)
                                        setSlug(list.slug)
                                      }}>
                                        {copied === list.id ? <i className="fas fa-copy btn-outline-success btn btn-sm" title={I18n.t("Link Copied")}></i> : <i className="fas fa-share-square btn-outline-warning btn btn-sm" title={I18n.t("Share")}></i>}
                                        {/* <i class="fas fa-share-alt"></i>                                         */}
                                        {/* {I18n.t('Share')} */}
                                      </a>
                                      <a className="flex items-center m-1 btn-outline-primary btn btn-sm" href
                                        onClick={e => { activeOffer(list.id, list.is_active) }}
                                      >
                                        {(list.is_active === 0) ? <i className="fas fa-play" title={I18n.t("Inactive")} /> : <i className="fa fa-pause" title={I18n.t("Active")} />}
                                      </a>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>

                      </div>
                      {is_delete === true ? (
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes"
                          confirmBtnBsStyle="danger"
                          title={I18n.t('Are you sure?')}
                          onConfirm={(e) => {
                            deleteOffer()
                            setIsDelete(false)
                            // window.location.reload(false)
                          }}
                          onCancel={(e) => {
                            setIsDelete(false)
                            setTempVarId('')
                          }}
                          focusCancelBtn
                        >
                          {I18n.t('Do you want to delete this offer?')}
                        </SweetAlert>
                      ) : ([]
                      )}
                    </div>
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>
      </body>

    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Offers);