import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleLoader } from '../../actions/loader';
import { dashboardReviews } from '../../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";

const Reviews = (props) => {
  const [review, setReview] = useState([]);
  const [rating, setAvgRating] = useState('');

  useEffect(async () => {
    props.toggleLoader(true);
    var getReviews_var = await dashboardReviews();
    if (getReviews_var !== null ){
      console.log(getReviews_var);
      setReview(getReviews_var);
    }
    props.toggleLoader(false);
  }, [])

  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="fas fa-star mr-1 ratting-user"></i>);
      } else {
        print_html.push(<i className="fas fa-star mr-1 ratting-user grey"></i>);
      }
    }
    return print_html;
  }
  return (
    <>
      <div className="col-lg-4">
        <div className="review_list">
          <div className="d-flex justify-content-between review-list-h mb-3">
            <h4 className="font-weight-bold title-top">{I18n.t('My Reviews')}</h4>
            <a href="#" className="btn btn-link">{I18n.t('View all')}</a>
          </div>
          {review.length > 0 ?
            <div className="list-review-user ">
              {review.map((list) => (
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="form-row">
                      
                      <div className="col-auto">
                        {list.user.media !== null ?
                          <img src={list.user.media.url} className="radius-15" width="45px" height="45px" />
                          : <img src="img/avatar-1.jpg" className="radius-15" width="45px" height="45px" />}
                      </div>
                      <div className="col">
                        <h5 className="mb-0 font-weight-bold">
                          {list.user.first_name} {list.user.last_name}
                        </h5>
                        <p className="text-muted mb-0">
                          {list.topic.title}
                        </p>
                        <div className="ratting-user mt-2">
                          {printStars(list.rating)}
                          <span className="number-ratting text-dark font-weight-bold">({list.rating})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            :
            <div className="my-3 mx-auto default_div">
              <img src="img/review-not.svg" className="img-fluid" />
              <p className="text-center">{I18n.t('Review not available')}</p>
            </div>
          }
        </div>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);