import '../ForgotPassword/forgetpassword.css';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { useHistory } from 'react-router-dom'
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess } from '../Utils/utils'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";

const ChangePassword = (props) => {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const changePassword = () => {
    props.toggleLoader(true);
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    axios.post(path + '/user/changepassword', {
      current_password: current_password,
      password: password,
      confirm_password: confirm_password,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Password Changed Successfully');
        setTimeout(() => window.location.href = "/my-profile", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  return (
    <>
      <body id="page-top">
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>{I18n.t('Change Password')}</h3>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">{I18n.t('Change Password')} </li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-md-6">
                            <label className="form-label" for="validationCustom01">{I18n.t('Current Password')}</label>
                            <input type="password" className="form-control form-control-user" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder={I18n.t('Current Password')} onChange={e => setCurrentPassword(e.target.value)} />
                          </div>
                        </div>
                        <br />
                        <div className="row g-3">
                          <div className="col-md-6">
                            <label className="form-label" for="validationCustom01">{I18n.t('New Password')}</label>
                            <input type="password" className="form-control form-control-user" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder={I18n.t('New Password')} onChange={e => setPassword(e.target.value)} />
                          </div>
                        </div>
                        <br />
                        <div className="row g-3">
                          <div className="col-md-6">
                            <label className="form-label" for="validationCustom01">{I18n.t('Confirm Password')}</label>
                            <input type="password" className="form-control form-control-user" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder={I18n.t('Confirm Password')} onChange={e => setConfirmPassword(e.target.value)} />
                          </div>
                        </div>
                        <br />
                        <div className="col-md-2">
                          <button className="btn btn-primary btn-user btn-block" onClick={changePassword}>
                            {I18n.t('Change')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);