import React, { useState } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const LiveInfo = () => {
  const [start_date, setStartDate] = useState(new Date());
  const [start_date1, setStartDate1] = useState(new Date());
  const [start_date2, setStartDate2] = useState(new Date());

  return (
    <>
      <body id="page-top">
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-4">
                      <h3>Live</h3>
                    </div>
                    <div className="col-8">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/live">Live</Link></li>
                        <li className="breadcrumb-item active"> New Live 25</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">

                      <div className="col">
                        <div className="m-t-15 m-checkbox-inline custom-radio-ml">
                          <div className="form-check form-check-inline radio radio-primary">
                            <input className="form-check-input" id="radioinline1" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                            <label className="form-check-label mb-0" for="radioinline1">Active</label>
                          </div>
                          <div className="form-check form-check-inline radio radio-primary">
                            <input className="form-check-input" id="radioinline2" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                            <label className="form-check-label mb-0" for="radioinline2">Old</label>
                          </div>

                        </div>
                      </div>
                      <div className="card-body">
                        <form className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">Live Title</label>
                              <input className="form-control" id="validationCustom01" type="text" value="Offer" required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-3">
                              <div className="mb-3 m-t-15">
                                <div className="form-check checkbox mb-0">
                                  <input className="form-check-input" id="checkbox2" type="checkbox" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="checkbox2">Consider For Certificate</label>
                                </div>
                                <div className="form-check checkbox mb-0">
                                  <input className="form-check-input" id="checkbox3" type="checkbox" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="checkbox3">Send e-mail Notification</label>
                                </div>
                                <div className="form-check checkbox mb-0">
                                  <input className="form-check-input" id="checkbox4" type="checkbox" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="checkbox4">
                                    Free Content
                                  </label>
                                </div>
                                <div className="form-check checkbox mb-0">
                                  <input className="form-check-input" id="checkbox5" type="checkbox" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="checkbox5">
                                    Bonus Content
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div>
                                <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ width: '150px', marginLeft: '100px' }}>
                                  <i className="fa fa-eye fa-sm" aria-hidden="true"></i> Hide</button>
                              </div>
                              <br />
                              <div>
                                <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ width: '150px', marginLeft: '100px' }}>
                                  <i className="fa fa-check" aria-hidden="true"></i> Offers</button>
                              </div>
                              <br />
                              <div>
                                <Link to="/live-preview">
                                  <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ width: '150px', marginLeft: '100px' }}>
                                    <i className="fa fa-search" aria-hidden="true"></i> Preview</button></Link>
                              </div>
                              <br />
                              <div>
                                <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ width: '150px', marginLeft: '100px' }}>
                                  <i className="fa fa-info-circle" aria-hidden="true"></i> Info</button>
                              </div>
                            </div>

                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">Live Link</label>
                              <input className="form-control" id="validationCustom01" type="text" value="Offer" required="" data-bs-original-title="" title="" />
                              <br />
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox6" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox6">
                                  Enable Watermark
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom03">Publish Date / Time</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio1">Immediately</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio2">Date / Time</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-3">
                              <DatePicker
                                selected={start_date}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="yyyy-MM-dd "
                                style={{ marginTop: '90px' }}
                                customInput={
                                  <input type="text" className="form-control" placeholder=" Date" style={{ marginTop: '70px', marginLeft: '-70px' }} />
                                }
                              />
                            </div>
                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">Embedded Link Zoom</label>
                              <input className="form-control" id="validationCustom01" type="text" value="Offer" required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom03">Launch Date / Time</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio1">Immediately</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio2">Date / Time</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <DatePicker
                                selected={start_date1}
                                onChange={(date) => setStartDate1(date)}
                                dateFormat="yyyy-MM-dd "
                                style={{ marginTop: '90px' }}
                                customInput={
                                  <input type="text" className="form-control" placeholder=" Date" style={{ marginTop: '70px', marginLeft: '-70px' }} />
                                }
                              />
                            </div>
                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">Promotional Image</label>
                              <input className="form-control" id="validationCustom01" type="text" value="Offer" required="" data-bs-original-title="" title="" />
                            </div>
                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-3">
                              <img src="img/live-info.png" width="3000px" height="3000px" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
export default LiveInfo;