import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Translate, I18n } from "react-redux-i18n";
import { getActiveInfoProduct, getModuleQuestion, getTopicQuestion } from '../Utils/utils';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess } from '../Utils/utils';

const Responses = (props) => {
	const [product_list, setProductList] = useState([]);
	const [product_id, setProductId] = useState('');
	const [module_title, setModuleTitle] = useState([]);
	const [topic_id, setTopicId] = useState('');
	const [questions, setQuestions] = useState([]);
	const [id, setId] = useState('');
	const [is_delete, setIsDelete] = useState(false);
	const [answer, setAnswer] = useState('');

	useEffect(async () => {
		props.toggleLoader(true)
		var get_product_listing = await getActiveInfoProduct();
		if (get_product_listing !== null) {
			setProductList(get_product_listing);
			console.log(get_product_listing);
			console.log(product_id);
		}
		props.toggleLoader(false)
	}, [])

	const showTopics = async (product_id) => {
		props.toggleLoader(true)
		var get_module_listing = await getModuleQuestion(product_id);
		if (get_module_listing !== null) {
			setModuleTitle(get_module_listing.module)
			setProductId(product_id);
		}
		props.toggleLoader(false)
	}

	const showQuestions = async (topic_id) => {
		props.toggleLoader(true)
		var get_topic_listing = await getTopicQuestion(topic_id);
		if (get_topic_listing !== null) {
			setQuestions(get_topic_listing.question);
			setTopicId(topic_id);
		}
		console.log(get_topic_listing)
		props.toggleLoader(false)
	}

	const deleteQuestion = async () => {
		var token = localStorage.getItem('token');
		props.toggleLoader(true);
		let path = config.siteUrl;
		axios.get(path + '/student/delete/question/' + id, {
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then(async (response) => {
				props.toggleLoader(false);
				displaySuccess('question Deleted Successfully');
				// setTimeout(() => window.location.reload(), 1500);
				var get_topic_listing = await getTopicQuestion(topic_id);
				if (get_topic_listing !== null) {
					setQuestions(get_topic_listing.question);
					setTopicId(topic_id);
				}
			})
			.catch(function (error) {
				props.toggleLoader(false);
				if (error.response.status === 401) {
					window.location.href = '/';
				}
				displayError(error);
			});
	}

	const updateAnswer = (id) => {
		var token = localStorage.getItem('token');
		props.toggleLoader(true);
		let path = config.siteUrl;
		axios.post(path + '/instructor/interactivity/update/answer/' + id, {
			answer: answer,
		}, {
			headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
		})
			.then(function (response) {
				props.toggleLoader(false);
				displaySuccess('Question updated Successfully');
				// setTimeout(() => window.location.href = "/interactivity", 3000);

			})
			.catch(function (error) {
				props.toggleLoader(false);
				if (error.response.status === 401) {
					window.location.href = '/';
				}
				displayError(error);
			});
	}
	return (
		<>
			<div className="container-fluid bg-white">
				<div className="card-body interactivity_card">
					<div className="row mb-5">
						<div className="col-md-3">
							<select onChange={e => showTopics(e.target.value)} className="form-select" id="validationCustom04" required=""	>
								<option >{I18n.t('Select Product')}</option>
								{product_list.map((product) => (
									<option value={product.id} >{product.title}</option>
								))}
							</select>
							<Accordion defaultActiveKey={"product_0"}>
								{module_title.map((products, index) => (
									<Card className="pb-3 mt-4 radius-0">
										<Accordion.Toggle as={Card.Header} eventKey={"product_" + index}
											className="font-weight-bold radius-0" style={{ cursor: 'pointer' }}>
											{products.title}
										</Accordion.Toggle>
										<Accordion.Collapse eventKey={"product_" + index}	>
											<div>
												{products.topic.map((topics) => (
													<Card.Body className="pl-3 pt-3 pb-0" onClick={e => showQuestions(topics.id)}
														style={{ cursor: 'pointer' }}>
														{topics.title}<span className='badge_count_event'>
															{topics.question_answer_count}</span>
													</Card.Body>
												))}
											</div>
										</Accordion.Collapse>
									</Card>
								))}
							</Accordion>
						</div>
						<div className="col-md-9 mt-4 mt-md-0 pl-md-2">
							<div className="card mb-4 radius-0">
								<div className="card-header radius-0 p-1">
									<div className="col-auto">
										<div className="form-row">
											<div className="col-mb-12 ml-auto">
												<Link to="/interactivity/add-question" className=" btn btn-primary">
													<i className="fas fa-plus fa-sm"></i>	{I18n.t('Add Question')}
												</Link>
											</div>
										</div>
									</div>
								</div>
								{module_title.length > 0 ?
									<>
										{questions.length > 0 ?
											<div className="needs-validation" novalidate="">
												{questions.map((ques) => (
													<div className="border-bottom">
														<div className='row m-1'>
														<>
															{questions.length > 0 ?
																<>
																	<div className="col-md-12 mb-1">
																		<div className="box sb3" style={{minWidth:'auto'}}>
																			{ques.question}
																		</div>
																	</div>
																	<div className="col-md-10 mt-2">
																		<label className="form-label" for="exampleFormControlTextarea9">{('Answer')}</label>
																		<div className="form-group position-relative">
																			<textarea className="form-control " id="exampleFormControlTextarea9" rows="2" placeholder="Instructor Answer"
																				onChange={e => setAnswer(e.target.value)} defaultValue={ques.answer} key={ques.id}>
																				{/* {ans.answer} */}
																			</textarea>
																			<button className="btn btn-primary submit_answer" type="submit" data-bs-original-title="" title=""
																				onClick={e => updateAnswer(ques.id)}>
																				{I18n.t('Submit')}
																			</button>
																		</div>
																	</div>
																	<div className="col-md-2 align-self-center">
																		<div className="mt-md-3 mt-2">
																			<button className="flex items-center btn btn-sm text-danger" href=""
																				onClick={e => {
																					setId(ques.id)
																					setIsDelete(true)
																				}}>
																				<i className="far fa-trash-alt btn-outline-danger"></i> {I18n.t('Delete')}
																			</button>
																		</div>
																	</div>
																</>
																:
																<div className="my-3 mx-auto default_div text-center">
																	<img src="img/no-questions-exist.png" className="img-fluid" width="180" />
																	<p className="text-center">No Questions Exist</p>
																</div>
															}
														</>
													</div>
													</div>
												))}
											</div>
											:
											<div className="my-3 mx-auto default_div text-center">
												<img src="img/no-questions-exist.png" className="img-fluid" width="180" />
												<p className="text-center">{I18n.t('No Questions Exist')}</p>
											</div>
										}
									</>
									:
									<div className="my-3 mx-auto default_div text-center">
										<img src="img/select-product-first.png" className="img-fluid" width="180" />
										<p className="text-center">{I18n.t('Please Select Product First')}</p>
									</div>
								}
							</div>
						</div>

					</div>
				</div>

				{is_delete === true ? (
					<SweetAlert
						warning
						showCancel
						confirmBtnText="Yes"
						confirmBtnBsStyle="danger"
						title={I18n.t('Are you sure?')}
						onConfirm={(e) => {
							deleteQuestion()
							setIsDelete(false)
						}}
						onCancel={(e) => {
							setIsDelete(false)
							setId('')
						}}
						focusCancelBtn
					>
						{I18n.t('Do you want to delete this Question')}?
					</SweetAlert>
				) : ([]
				)}
			</div>
		</>
	)
}
const mapDispatchToProps = {
	toggleLoader
}
const mapStateToProps = (state) => {
	return {
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Responses);