import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import config from "../config/config";
import axios from "axios";
import {
  displayError, displaySuccess, getPaymentList, getInstallmentList, getInterestList, getOffer, getOfferEdit,
  instructorProfile
} from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from 'react-datepicker';

const NewCrossSell = (props) => {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [payment_method, setPaymentMethod] = useState('');
  const [payment_list, setPaymentList] = useState([]);
  const [installment_list, setInstallmentList] = useState([]);
  const [installment_quantity, setInstallmentQuantity] = useState('');
  const [interest_list, setInterestList] = useState([]);
  const [interest_mode, setInterestMode] = useState('');
  const [advance, setAdvance] = useState(0);
  const [offer_id, setOfferId] = useState('');
  const [offers, setOffers] = useState([]);
  const [time, setTime] = useState('');

  const [is_selected, setIsSelected] = useState(false);
  const [start_date, setStartDate] = useState(new Date());
  const [Immediately, setImmediately] = useState('');
  const [publish_date, setPublishDate] = useState('');

  const [start_date1, setStartDate1] = useState(new Date());
  const [date_selected, setDateSelected] = useState(false);

  const [customer_value, setCustomerValue] = useState("100");
  const [media_id, setMediaId] = useState('');
  const [is_customer, setIsCustomer] = useState(false);
  const [image, setImage] = useState(false);
  const [course_image_url, setCourseImageUrl] = useState(null);
  const [preview, setPreview] = useState(false);
  const [image_url, setImageUrl] = useState('')
  const [newPrice, setNewPrice] = useState('');

  const addCrossSell = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/cross_sell/create', {
      title: title,
      price: price,
      discount_percentage: discount,
      payment_method: payment_method,
      installment_quantity: installment_quantity,
      interest_mode: interest_mode,
      receivable_advance: advance,
      offer_id: offer_id,
      publish_date: start_date,
      end_date: start_date1,
      quantity_of_customer: customer_value,
      media_id: media_id,
      new_price : newPrice,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('CrossSell Added Successfully');
        setTimeout(() => window.location.href = "/cross-sell", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  useEffect(async () => {
    props.toggleLoader(true)
    var get_payment_list = await getPaymentList();
    setPaymentList(get_payment_list);
    console.log(get_payment_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_install_list = await getInstallmentList();
    setInstallmentList(get_install_list);
    console.log(get_install_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_interest_list = await getInterestList();
    setInterestList(get_interest_list);
    console.log(get_interest_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_offers = await getOffer();
    setOffers(get_offers);
    console.log(get_offers);
    props.toggleLoader(false);
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var user_login = await instructorProfile();
    if (user_login !== null) {
      setTime(user_login.timezone)
    }
    props.toggleLoader(false);
  }, [])
  const is_advance = () => {
    setAdvance(1);
  }
  const selected = () => {
    setIsSelected(true);
    setPublishDate(new Date());
    setStartDate(new Date());
    setImmediately(1);
  }
  const selected2 = () => {
    setIsSelected(false);
    setPublishDate("0");
    setImmediately(0);
  }
  const definedDate = () => {
    setDateSelected(true);
    setStartDate1(null);
  }
  const definedDate2 = () => {
    setDateSelected(false);
    setStartDate1(new Date());
  }
  const quantity = () => {
    setCustomerValue("unlimited");
    setIsCustomer(true);
  }
  const quantity2 = () => {
    setCustomerValue("100");
    setIsCustomer(false);
  }
  const uploadImage = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    // setImageUrl(formData)
    console.log(formData)
    // var reader = new FileReader();
    // var url = reader.readAsDataURL(e.target.files[0]);
    // console.log(url)
    var file = URL.createObjectURL(e.target.files[0])
    console.log(file)
    setImageUrl(file)
    axios.post(path + '/product/topic/image_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
      setImage(true)
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }
  const imagePreview = () => {
    // setImage(uploadImage);
    setPreview(true)
  }
  const close = () => {
    setPreview(false)
  }

  const editOffer = async (id) => {
    props.toggleLoader(true);
    var get_offer_edit = await getOfferEdit(id);
    setTitle(get_offer_edit.name);
    setPrice(get_offer_edit.price);
    setPaymentMethod(get_offer_edit.payment_method);
    setInstallmentQuantity(get_offer_edit.installment_quantity);
    setInterestMode(get_offer_edit.interest_mode);
    setAdvance(get_offer_edit.receivable_advance);
    // setPublishDate(get_offer_edit.publish_date);
    // setStartDate1(get_offer_edit.end_date);
    // setCustomerValue(get_offer_edit.quantity_of_customer);
    if (get_offer_edit.media !== null) {
      setMediaId(get_offer_edit.media_id)
      setCourseImageUrl(get_offer_edit.media.url);
    }
    props.toggleLoader(false);
  }
  const discounted_price = () => {
    var discount_price = price - (price * discount / 100);
    setNewPrice(discount_price);
  }
  useEffect(() => {
    discounted_price();
  }, [discount])
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-6">
                      <h3>{I18n.t('New Cross Sell')}</h3>
                    </div>
                    <div className="col-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/cross-sell">{I18n.t('Cross Sell')}</Link></li>
                        <li className="breadcrumb-item">{I18n.t('New Cross Sell')}</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">

                    <div className="card mb-4">
                      <div className="needs-validation" novalidate="">
                        <div className="card-body">

                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Offer')}   </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  setOfferId(e.target.value)
                                  // editOffer(props.match.params.id)
                                  editOffer(e.target.value)
                                  setDiscount(0)
                                }} >
                                <option value={""} >{I18n.t('Select Offer')}</option>
                                {offers.map((list) =>
                                  <option value={list.id} >{list.name}</option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Cross Sell Title')}</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder={I18n.t('Cross Sell Title')} required="" data-bs-original-title="" title=""
                                onChange={e => setTitle(e.target.value)} value={title} />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01">{I18n.t('Current Price')}</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder={I18n.t('Price')} required="" data-bs-original-title="" title=""
                                onChange={e => setPrice(e.target.value)} value={price} disabled />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01"> {I18n.t('% Discount')}</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder={I18n.t('% Discount')} required="" data-bs-original-title="" title=""
                                onChange={e => {
                                  setDiscount(e.target.value)
                                  // discounted_price()
                                }} value={discount} />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom01">{I18n.t('New Price')}</label>
                              <input className="form-control" id="validationCustom01" type="text"
                                placeholder={I18n.t('Price')} required="" data-bs-original-title="" title=""
                                onChange={e => setNewPrice(e.target.value)} value={newPrice} disabled />
                            </div>
                          </div>

                          <br />
                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Payment Method')}</label>
                              <select className="form-select" id="validationCustom04" required=""
                                onChange={e => setPaymentMethod(e.target.value)} value={payment_method}>
                                <option value={""} required>{I18n.t('Select payment method')}</option>
                                {payment_list.map((pay) => (
                                  <option value={pay.id}>{I18n.t(pay.text)}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Installment Quantity')}  </label>
                              {(payment_method === "single payment") ?
                                <select className="form-disable" id="validationCustom04"
                                  onChange={e => setInstallmentQuantity(e.target.value)}
                                  value={installment_quantity} disabled >
                                  <option value={""} required>{I18n.t('Select Installment Quantity')} </option>
                                  {installment_list.map((list) => (
                                    <option value={list.id}>{I18n.t(list.text)}</option>
                                  ))}
                                </select>
                                : <select className="form-select" id="validationCustom04"
                                  onChange={e => setInstallmentQuantity(e.target.value)}
                                  value={installment_quantity}>
                                  <option value={""} required>{I18n.t('Select Installment Quantity')} </option>
                                  {installment_list.map((list) => (
                                    <option value={list.id}>{I18n.t(list.text)}</option>
                                  ))}
                                </select>
                              }

                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Interest Mode')}</label>
                              {(payment_method === "single payment") ?
                                <select className="form-disable" id="validationCustom04"
                                  onChange={e => setInterestMode(e.target.value)} value={interest_mode} disabled>
                                  <option value={""} required>{I18n.t('Select Interest MOde')}</option>
                                  {interest_list.map((mode) => (
                                    <option value={mode.id}>{I18n.t(mode.text)}</option>
                                  ))}
                                </select>
                                : <select className="form-select" id="validationCustom04"
                                  onChange={e => setInterestMode(e.target.value)} value={interest_mode}>
                                  <option value={""} required>{I18n.t('Select Interest MOde')}</option>
                                  {interest_list.map((mode) => (
                                    <option value={mode.id}>{I18n.t(mode.text)}</option>
                                  ))}
                                </select>
                              }
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Advance Receivables')}</label>
                              <select className="form-select" id="validationCustom04" required="" >
                                <option>{I18n.t('no')}</option>
                                <option onClick={is_advance}>{I18n.t('yes')}</option>
                              </select>
                            </div>
                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Publish Date / Time')}  {'(' + (time) + ')'}</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1"
                                      data-bs-original-title="" title="" onClick={selected} />
                                    <label className="form-check-label" for="radio1">{I18n.t('Immediately')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1"
                                      data-bs-original-title="" title="" onClick={selected2} defaultChecked={true} />
                                    <label className="form-check-label" for="radio2">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <DatePicker
                                disabled={(is_selected) ? true : false}
                                selected={start_date}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                minDate={new Date()}
                                showTimeInput
                                customInput={
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=" Date"
                                    style={{ marginTop: '80px' }}
                                  // value={publish_date}
                                  />
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('End Date / Time')}  {'(' + (time) + ')'}</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio3" type="radio" name="radio2" value="option1"
                                      onClick={definedDate} data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio3">{I18n.t('undefined')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio4" type="radio" name="radio2" value="option1"
                                      onClick={definedDate2} defaultChecked={true} data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio4">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <DatePicker
                                disabled={(date_selected) ? true : false}
                                selected={start_date1}
                                onChange={(date) => setStartDate1(date)}
                                dateFormat="yyyy-MM-dd HH:mm:ss "
                                minDate={new Date()}
                                showTimeInput
                                customInput={
                                  <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                    style={{ marginTop: '80px' }}
                                  // value={start_date1} 
                                  />
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Quantity of Customers')}</label>
                              <div className="col">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio5" type="radio" name="radio3" value="option1"
                                      onClick={quantity} data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio5">{I18n.t('Unlimited')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio6" type="radio" name="radio3" value="option1"
                                      onClick={quantity2} defaultChecked={true} data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio6">{I18n.t('Limited')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <input className="form-control" id="validationCustom01"
                                type="text"
                                data-bs-original-title=""
                                title=""
                                disabled={(is_customer) ? true : false}
                                style={{ marginTop: '80px' }}
                                onChange={e =>
                                  setCustomerValue(e.target.value)}
                                value={customer_value}
                              />
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label className="form-label" for="validationCustom01">{I18n.t('Banner Image')}</label>
                              <input
                                accept=".jpg,.jpeg,.png"
                                id="validationCustom01"
                                type="file"
                                required=""
                                data-bs-original-title=""
                                title=""
                                className="form-control"
                                onChange={e => uploadImage(e)}
                              />
                            </div>

                            {image === true ?
                              <>
                                <button className="btn btn-light" type="submit" data-bs-original-title="" title=""
                                  style={{ margin: "5px", marginTop: '30px' }}
                                  onClick={imagePreview}>
                                  <i className="fa fa-eye" aria-hidden="true"></i>&nbsp; {I18n.t('Preview')}
                                </button>
                              </>
                              :
                              ''
                            }
                          </div>
                          <br />
                          {(preview === true) ?
                            <>
                              <button className="btn btn-primary" type="submit" data-bs-original-title="" title=""
                                style={{ margin: "5px" }}
                                onClick={close}>
                                <i className="fa fa-undo" aria-hidden="true"></i> Close
                              </button>
                              <div className="row g-3">
                                <div className="col-md-3">
                                  <img src={image_url} />
                                </div>
                              </div>
                            </>
                            : ''}
                        </div>
                        <div className="card-footer">
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}
                            onClick={e => addCrossSell()}>
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save')}
                          </button>
                          <Link to="/cross-sell">
                            <button className="btn btn-dark" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                              <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NewCrossSell);