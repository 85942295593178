import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, verifyEmail } from '../Utils/utils'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";

import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher'
const ForgotPassword = (props) => {
  const languageSelected = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt'
  const [email, setEmail] = useState("");

 
  const resetPassword = async (token) => {
    // var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/password/reset', {
      email: email,
      language : languageSelected
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('Password reset email has been sent'));
        props.toggleLoader(false);
        setTimeout(() => window.location.href = "/", 3000);
      })
      .catch((error) => {
        displayError(error);
        props.toggleLoader(false);
      });
  }

  return (
    <>
      <body className="bg-gradient-primary">
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-12 d-none d-sm-block  login-bg-img" style={{ backgroundImage: 'url("img/bg15-l.jpg")' }}>
                    <div className="login-intro">
                      <h1>{I18n.t('Welcome To On Global')}</h1>
                      <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada.</p>
                      <div className="login-page-switcher">
                        <Link to="/" className="switcher-text1 active">{I18n.t('Log In')}</Link>
                        {/* <Link to="/register" className="switcher-text1 ">{I18n.t('Register')}</Link> */}
                        <LanguageSwitcher />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 login-bg-color">
                    <div className="login-content">
                      <div className="login-header">
                        <a href="#" className="login-logo">
                          <img src="img/logo.png" alt="Logo" />
                        </a>
                      </div>
                      <div className="login-form">
                        <h2>{I18n.t('Forgot Password')}</h2>
                        <br />
                        <div className="user">
                          <div className="form-group">
                            <input type="email" className="form-control form-control-user" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder={I18n.t('Enter Email Address')} onChange={e => setEmail(e.target.value)} />
                          </div>
                          <button className="btn btn-primary btn-user btn-block" onClick={resetPassword}>
                            {I18n.t('Reset')}
                          </button>
                        </div>

                      </div>
                      <div className="login-page-switcher d-block d-sm-none text-center">
                        <Link to="/" className="switcher-text1 active">{I18n.t('Log In')}</Link>&nbsp;
                        <Link to="/register" className="switcher-text1 ">{I18n.t('Register')}</Link>&nbsp;
                        <LanguageSwitcher />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);