import React from 'react';
import { NavLink } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
const Leftbar = () => {
  return (
    <>
      <ul className="d-none d-sm-block navbar-nav bg-white sidebar sidebar-dark accordion  " id="accordionSidebar" >
        <a className="sidebar-brand d-flex" href="">
          <div className="sidebar-brand-text"> <img src="/img/logo-2.png" alt="Logo" style={{ maxWidth: '165px' }} /></div>
        </a>
        <hr className="sidebar-divider my-0" />
        <li className={(window.location.pathname === '/dashboard') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/dashboard'}>
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>{I18n.t('Dashboard')}</span>
          </NavLink>
        </li>


        <li className={(window.location.pathname === '/infoproduct' || window.location.pathname === '/add-infoproduct' || window.location.pathname === "/edit-infoproduct/:id") ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/infoproduct'}>
            <i className="fas fa-fw fa-info" />
            <span>{I18n.t('InfoProduct')}</span>
          </NavLink>
        </li>

        {/* <li className={(window.location.pathname === '/content' || window.location.pathname === '/content-add') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/content'}>
            <i className="fas fa-fw fa-table" />
            <span>Content</span>
          </NavLink>
        </li> */}

        <li className={(window.location.pathname === '/module' || window.location.pathname === '/module-add') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/module'}>
            <i className="fas fa-fw fa-table" />
            <span>{I18n.t('Content')}</span>
          </NavLink>
        </li>



        <li className={(window.location.pathname === '/offers' || window.location.pathname === '/offers-add') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/offers'}>
            <i className="fas fa-fw fa-chart-line" />
            <span>{I18n.t('Offers')}</span>
          </NavLink>
        </li>

        <li className={(window.location.pathname === '/live' || window.location.pathname === '/live-new') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/live'}>
            <i className="fas fa-fw fa-wifi" />
            <span>{I18n.t('Live')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/certificates' || window.location.pathname === '/certificates-new') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/certificates'}>
            <i className="fas fa-fw fa-graduation-cap" />
            <span>{I18n.t('Certificates')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/interactivity') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/interactivity'}>
            <i className="fab fa-fw fa-sellsy" />
            <span>{I18n.t('Interactivity')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/cross-sell' || window.location.pathname === '/new-cross-sell') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/cross-sell'}>
            <i className="fab fa-fw fa-sellsy" />
            <span>{I18n.t('Cross Sell')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/cross-sell-assignments' || window.location.pathname === '/cross-sell-assignments') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/cross-sell-assignments'}>
            <i className="fab fa-fw fa-sellsy" />
            <span>{I18n.t('Cross Sell Assignments')}</span>
          </NavLink>
        </li>
        <li className={(window.location.pathname === '/cross-sell-assignments-event' || window.location.pathname === '/cross-sell-assignments-event') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/cross-sell-assignments-event'}>
            <i className="fab fa-fw fa-sellsy" />
            <span>{I18n.t('Cross Sell Assignments Event')}</span>
          </NavLink>
        </li>
        {/*

        <li className={(window.location.pathname === '/manage-team' || window.location.pathname === '/add-team-member') ? 'nav-item active' : 'nav-item'}>
          <NavLink className="nav-link" to={'/manage-team'}>
            <i className="fas fa-fw fa-users" />
            <span>Manage Team</span>
          </NavLink>
        </li> */}

        <hr className="sidebar-divider d-none d-md-block" />
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" />
        </div>
      </ul>
    </>
  )
}
export default Leftbar;