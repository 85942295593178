import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/mainstyle.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './css/form_Componet.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { connect } from 'react-redux';
import { getUserData } from './actions/userdata'
import { userLoggedIn } from './Utils/utils'
import { toggleLoader } from './actions/loader';

import Dashboard from './Dashboard/';
import LoginScreen from './LoginScreen/';
// import Register from "./Register";
import Content from './Content';
import ContentAdd from './ContentAdd';
import Module from './Module';
import ModuleAdd from './ModuleAdd';
import InfoProduct from './InfoProduct';
import AddInfoProduct from "./AddInfoProduct";
import Offers from "./Offers";
import AddOffers from "./AddOffers";
import Live from "./Live";
import NewLive from "./NewLive";
import Certificates from "./Certificates";
import NewCertificate from "./NewCertificate";
import NewTopic from './NewTopic';
import CrossSell from './CrossSell';
import NewCrossSell from './NewCrossSell';
import ManageTeam from './ManageTeam';
import AddTeamMember from './AddTeamMember';
import PreviewCertificate from './PreviewCertificate';
import Interactivity from './Interactivity';
import LivePreview from './LivePreview';
import LiveInfo from './LiveInfo';
import CrossSellPreview from './CrossSellPreview';
import MyProfile from './MyProfile';
import Verify from './Verify';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ProtectedRoute from './ProtectedRoute';
import ChangePassword from './ChangePassword';
import EditInfoProduct from './EditInfoProduct';
import ModuleEdit from './ModuleEdit';
import ViewTopic from "./ViewTopic";
import EditTopic from "./EditTopic";
import EditOffer from "./EditOffer";
import LoadingSpinner from './components/LoadingSpinner';
import EditLive from "./EditLive";
import AddQuestion from "./AddQuestion";
import EditCertificate from "./EditCertificate";
import EditCrossSell from "./EditCrossSell";
import CrossSellAssignments from "./CrossSellAssignments";
import AddCrossSellAssignment from "./AddCrossSellAssignment";
import EditCrossSellAssigment from "./EditCrossSellAssigment";
import CrossSellEvents from "./CrossSellEvents";
import AddCrossSellEvents from "./AddCrossSellEvents";
import EditCrossSellEvent from "./EditCrossSellEvent";

import { setLocaleWithFallback } from "./actions/i18n"
import Notifications from './Notifications';
function App(props) {
  const [default_language, setDefaultLanguage] = useState('pt');
  useEffect(async () => {
    props.toggleLoader(true);
    var auth_me = await userLoggedIn()
    props.getUserData(auth_me);
    props.toggleLoader(false);
    if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== null) {
      setDefaultLanguage(localStorage.getItem('langauge_selected'));
      props.setLocaleWithFallback(localStorage.getItem('langauge_selected'));
    } else {
      setDefaultLanguage("pt");
      props.setLocaleWithFallback("pt");
      localStorage.setItem('langauge_selected', "pt");
    }
    document.dir = props.locale === "en" ? "en" : "pt";
  }, [props.locale])

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginScreen} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          {/* <Route exact path="/register" component={Register} /> */}
          <ProtectedRoute exact path="/content" component={Content} />
          <ProtectedRoute exact path="/content-add" component={ContentAdd} />
          <ProtectedRoute exact path="/module" component={Module} />
          <ProtectedRoute exact path="/module-add" component={ModuleAdd} />
          <ProtectedRoute exact path="/infoproduct" component={InfoProduct} />
          <ProtectedRoute exact path="/add-infoproduct" component={AddInfoProduct} />
          <ProtectedRoute exact path="/offers" component={Offers} />
          <ProtectedRoute exact path="/offers-add" component={AddOffers} />
          <ProtectedRoute exact path="/live" component={Live} />
          <ProtectedRoute exact path="/live-new" component={NewLive} />
          <ProtectedRoute exact path="/new-topic/:id" component={NewTopic} />
          <ProtectedRoute exact path="/cross-sell" component={CrossSell} />
          <ProtectedRoute exact path="/new-cross-sell" component={NewCrossSell} />
          <ProtectedRoute exact path="/manage-team" component={ManageTeam} />
          <ProtectedRoute exact path="/add-team-member" component={AddTeamMember} />
          <ProtectedRoute exact path="/certificates" component={Certificates} />
          <ProtectedRoute exact path="/certificates-new" component={NewCertificate} />
          <ProtectedRoute exact path="/certificates-preview" component={PreviewCertificate} />
          <ProtectedRoute exact path="/interactivity" component={Interactivity} />
          <ProtectedRoute exact path="/live-preview" component={LivePreview} />
          <ProtectedRoute exact path="/live-info" component={LiveInfo} />
          <ProtectedRoute exact path="/cross-sell-preview" component={CrossSellPreview} />
          <ProtectedRoute exact path="/my-profile" component={MyProfile} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/password/:token" component={ResetPassword} />
          <ProtectedRoute exact path="/change-password" component={ChangePassword} />
          <Route exact path="/edit-infoproduct/:id" component={EditInfoProduct} />
          <ProtectedRoute exact path="/module-edit/:id" component={ModuleEdit} />
          <ProtectedRoute exact path="/view-topic/:id" component={ViewTopic} />
          <ProtectedRoute exact path="/edit-topic/:id" component={EditTopic} />
          <ProtectedRoute exact path="/edit-offer/:id" component={EditOffer} />
          <ProtectedRoute exact path="/edit-live/:id" component={EditLive} />
          <ProtectedRoute exact path="/interactivity/add-question" component={AddQuestion} />
          <ProtectedRoute exact path="/certificate-edit/:id" component={EditCertificate} />
          <ProtectedRoute exact path="/cross-sell-edit/:id" component={EditCrossSell} />
          <ProtectedRoute exact path="/cross-sell-assignments" component={CrossSellAssignments} />
          <ProtectedRoute exact path="/add-cross-sell-assignment" component={AddCrossSellAssignment} />
          <ProtectedRoute exact path="/edit-cross-sell-assignment/:id" component={EditCrossSellAssigment} />
          <ProtectedRoute exact path="/cross-sell-assignments-event" component={CrossSellEvents} />
          <ProtectedRoute exact path="/add-cross-sell-assignment-event" component={AddCrossSellEvents} />
          <ProtectedRoute exact path="/edit-cross-sell-assignment-event/:id" component={EditCrossSellEvent} />
<ProtectedRoute exact path="/notifications" component={Notifications}/>
        </Switch>
      </BrowserRouter>
      <LoadingSpinner />
    </React.Fragment >
  );
}

const mapDispatchToProps = {
  getUserData,
  toggleLoader,
  setLocaleWithFallback
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata,
    locale: state.i18n.locale
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);