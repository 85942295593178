import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { getCertificateList, displayError, displaySuccess } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import config from "../config/config";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { Translate, I18n } from "react-redux-i18n";
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert'

const Certificates = (props) => {
  const [listing, setListing] = useState([]);
  const [id, setId] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [temp_id, setTempId] = useState('');
  const handleShow = (id) => {
    setLgShow(true)
    setTempId(id)
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var getCerti_var = await getCertificateList();
    console.log(getCerti_var);
    setListing(getCerti_var);
    props.toggleLoader(false);
  }, [])

  const deleteCertificate = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + '/certificate/delete/' + id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async function (response) {
        props.toggleLoader(false);
        displaySuccess('Certificate Deleted Successfully');
        var getCerti_var = await getCertificateList();
        setListing(getCerti_var);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">
                <div className="row justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Certificates')}</h1>
                  </div>

                  <div className="col-auto">
                    <div className="form-row">
                      <div className="col">
                        <Link to="/certificates-new">
                          <a className=" btn btn-light">
                            <i className="fas fa-plus fa-sm" /> {I18n.t('Certificates')} </a></Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card">

                      <div className="card-body">
                        <div className="table-responsive">
                          <Table className="table align-items-center mb-0">
                            <Thead>
                              <Tr>
                                {/* <Th>{I18n.t('InfoProduct')}</Th> */}
                                <Th>{I18n.t('Offer')}</Th>
                                <Th>{I18n.t('Goal')}</Th>
                                <Th>{I18n.t('Quantity')}</Th>
                                <Th>{I18n.t('Sample PDF')}</Th>
                                <Th>{I18n.t('Sample Image')}</Th>
                                <Th>{I18n.t('Action')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(listing.length === 0) ? <Tr>
                                <Td colspan="7" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No Certificates')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {listing.map((list) => (
                                <Tr>
                                  {/* <Td>{list.product.title}</Td> */}
                                  <Td>{list.offer.name} </Td>
                                  <Td>{list.goal}%</Td>
                                  <Td>{list.quantity}</Td>
                                  <Td>
                                    <Button variant="outline-danger" target="_blank" href={list.sample_pdf.url}>
                                      <i class="fa fa-file-pdf-o" style={{ fontSize: "20px", color: "red" }}></i>
                                    </Button>
                                  </Td>
                                  <Td>
                                    <Button variant="outline-primary" onClick={e => handleShow(list.id)}>
                                      <i class="fa fa-image" style={{ fontSize: "20px" }}></i>
                                    </Button>

                                  </Td>
                                  <Td>
                                    <div className>
                                      <Link to={"/certificate-edit/" + list.id} title={I18n.t("Edit")}>
                                        <a className="items-center m-1 btn-outline-dark btn btn-sm">
                                          {<i className="far fa-edit"></i> /* {I18n.t('Edit')} */}
                                        </a></Link>
                                      <button className="flex items-center btn btn-sm  btn-outline-danger"
                                        onClick={e => {
                                          setId(list.id)
                                          setIsDelete(true)
                                        }} title={I18n.t("Delete")}>
                                        <i className="far fa-trash-alt" /> </button>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>

                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{I18n.t('Sample Image')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {listing.map((list) => (
                        temp_id === list.id ?
                          <img className="Certification-img img-fluid mx-auto d-block"
                            src={list.sample_image.url} /> : ""
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => setLgShow(false)}>
                        {I18n.t('Close')}
                      </Button>
                    </Modal.Footer>
                  </Modal>

                </>
              </div>
            </div>
          </div>
        </div>
        {is_delete === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title={I18n.t('Are you sure?')}
            onConfirm={(e) => {
              deleteCertificate()
              setIsDelete(false)
            }}
            onCancel={(e) => {
              setIsDelete(false)
              setId('')
            }}
            focusCancelBtn
          >
            {I18n.t('Do you want to delete this certificate?')}
          </SweetAlert>
        ) : ([]
        )}
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Certificates);