import React, { useState, useEffect } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Accordion from 'react-bootstrap/Accordion';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import TopNav from '../components/TopNav';
import config from "../config/config";
import axios from "axios";
import Select from 'react-select';
import {
  displayError, displaySuccess, getPaymentList, getInstallmentList, getInterestList, getCategoryListing,
  getLevelListing, getProductWithModules, getOfferEdit, instructorProfile, getInstructorList
} from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const EditOffer = (props) => {
  const [start_date, setStartDate] = useState(new Date());
  const [start_date1, setStartDate1] = useState(new Date());
  const [start_date2, setStartDate2] = useState(new Date());
  const [benefitsFieldAdd, setBenefitFieldAdd] = useState([""]);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [free_content, setFreeContent] = useState(false);
  const [payment_method, setPaymentMethod] = useState('');
  const [installment_quantity, setInstallmentQuantity] = useState('');
  const [interest_mode, setInterestMode] = useState('');
  const [renew, setRenew] = useState(0);
  const [receivable_advance, setReceivableAdvance] = useState(0);
  const [Immediately, setImmediately] = useState('');
  const [publish_date, setPublishDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [valid_until_days, setValidUntilDays] = useState('0');
  const [validImmediately, setValidImmediately] = useState('');
  const [validDate, setValidDate] = useState('');
  const [limitedCustomer, setLimitedCustomer] = useState('');
  const [unlimitedCustomer, setUnlimitedCustomer] = useState('');
  const [quantity_of_customer, setQuantityOfCustomer] = useState('0');
  const [description, setDescription] = useState('');
  const [media_id, setMediaId] = useState('');
  const [payment_list, setPaymentList] = useState([]);
  const [installment_list, setInstallmentList] = useState([]);
  const [interest_list, setInterestList] = useState([]);
  const [is_selected, setIsSelected] = useState(false);
  const [category_list, setCategoryList] = useState([]);
  const [level_list, setLevelList] = useState([]);
  const [category_id, setCategoryId] = useState('');
  const [level_id, setLevelId] = useState('');
  const [date_selected, setDateSelected] = useState(false);
  const [days_selected, setDaysSelected] = useState(false);
  const [valid_date_selected, setValidDateSelected] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [module_list, setModuleList] = useState([]);
  const [module_ids, setModuleIds] = useState([]);
  const [course_image_url, setCourseImageUrl] = useState(null);
  const [hotmart_id, setHotmartId] = useState('');
  const [hotmart_link, setHotmartLink] = useState('');
  const [time, setTime] = useState('');
  const [instructor_list, setInstructorList] = useState([]);
  const [instList, setInstList] = useState([]);
  const [temp_inst, setTempInst] = useState([]);
  const [publicCourse, setPublicCourse] = useState(1);
  const [endImmediate, setEndImmediate] = useState('');
  const [pubImmediate, setPubImmediate] = useState('');
  const [live_quantity, setLiveQuantity] = useState(0);
  // const[is_free_content,setIsFreeContent]=useState('');
  const [is_free, setIsFree] = useState(1);
  const [desc_mob, setDescMob] = useState('');
  const [release_percent, setReleasePercent] = useState('');

  const editOffer = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/offer/update/' + props.match.params.id, {
      name: name,
      price: free_content === true ? 0 : price,
      free_content: is_free,
      payment_method: payment_method,
      installment_quantity: installment_quantity,
      interest_mode: interest_mode,
      renew: renew,
      live_quantity: live_quantity,
      receivable_advance: receivable_advance,
      publish_date: start_date,
      end_date: start_date1,
      quantity_of_customer: quantity_of_customer,
      media_id: media_id,
      category_id: category_id,
      description: description,
      level_id: level_id,
      billing_value: 75,
      customer_value: 75,
      module_ids: module_ids,
      offer_benefit: benefitsFieldAdd,
      hotmart_id: hotmart_id,
      hotmart_link: hotmart_link,
      other_instructor: instructor_list,
      is_public: publicCourse,
      offer_description_mobile: desc_mob,
      release_percentage: release_percent

    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Offer Updated Successfully');
        setTimeout(() => window.location.href = "/offers", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  const is_free_Content = (val) => {
    setIsFree(val);
    console.log(val);
    if (val === 'no') {
      setPrice('');
      setFreeContent(false);
      setPaymentMethod('')
    } else {
      setFreeContent(true);
      setPrice(0);
      setPaymentMethod('single payment')
    }
  }
  useEffect(async () => {
    var get_offer_edit = await getOfferEdit(props.match.params.id);
    // setIsFreeContent(get_offer_edit.free_content)
    setName(get_offer_edit.name);
    setPrice(get_offer_edit.price);
    setFreeContent(get_offer_edit.free_content);
    setIsFree(get_offer_edit.free_content);
    setPaymentMethod(get_offer_edit.payment_method);
    setInstallmentQuantity(get_offer_edit.installment_quantity);
    setInterestMode(get_offer_edit.interest_mode);
    setRenew(get_offer_edit.renew);
    setReceivableAdvance(get_offer_edit.receivable_advance);
    setPublishDate(get_offer_edit.publish_date);
    setEndDate(get_offer_edit.end_date);
    setStartDate(new Date(get_offer_edit.publish_date));
    if (get_offer_edit.end_date === null) {
      setStartDate1(null);
    } else {
      setStartDate1(new Date(get_offer_edit.end_date));
    }
    setValidUntilDays(get_offer_edit.valid_until_days);
    setValidDate(get_offer_edit.validDate);
    setQuantityOfCustomer(get_offer_edit.quantity_of_customer);
    setCategoryId(get_offer_edit.category_id);
    setDescription(get_offer_edit.description);
    setLevelId(get_offer_edit.level_id);
    setModuleIds(get_offer_edit.module_ids);
    setHotmartId(get_offer_edit.hotmart_id);
    setHotmartLink(get_offer_edit.hotmart_link);
    setPublicCourse(get_offer_edit.is_public);
    setLiveQuantity(get_offer_edit.live_quantity);
    if (get_offer_edit.media !== null) {
      setMediaId(get_offer_edit.media_id)
      setCourseImageUrl(get_offer_edit.media.url);
    }
    setDescMob(get_offer_edit.offer_description_mobile);
    if (get_offer_edit.offer_benefits.length > 0) {
      var temp_benefit_array = [...get_offer_edit.offer_benefits];
      //temp_benefit_array.push("");
      setBenefitFieldAdd(temp_benefit_array);
    }
    setReleasePercent(get_offer_edit.release_percentage)
    console.log(start_date1)
    var temp_array = [];
    var temp_array2 = [];
    get_offer_edit.offer_instructor.map((offer) => {
      temp_array.push(offer.user_id);
      if (offer.user !== null) {
        temp_array2.push(
          { 'value': offer.user_id, 'label': offer.user.first_name }
        )
      }
      setTempInst(temp_array2);
    })
    setInstructorList(temp_array);
  }, [])

  useEffect(async () => {
    props.toggleLoader(true)
    var get_payment_list = await getPaymentList();
    setPaymentList(get_payment_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_install_list = await getInstallmentList();
    setInstallmentList(get_install_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_interest_list = await getInterestList();
    setInterestList(get_interest_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_category_list = await getCategoryListing();
    setCategoryList(get_category_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_level_list = await getLevelListing();
    setLevelList(get_level_list);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var product_with_modules = await getProductWithModules();
    setModuleList(product_with_modules);
    props.toggleLoader(false)
  }, [])

  const selectModules = (e, module_id) => {
    var temp_array = [...module_ids];
    if (e.target.checked === true) {
      temp_array.push(module_id);
    } else {
      var index = temp_array.indexOf(module_id);
      if (index > -1) {
        temp_array.splice(index, 1);
      }
    }
    setModuleIds(temp_array);
  }
  const selected = () => {
    setIsSelected(true);
    setPublishDate("0");
    setImmediately(1);
    setPubImmediate(1);

  }
  const selected2 = () => {
    setIsSelected(false);
    setPublishDate("0");
    setImmediately(0);
    setPubImmediate(0);

  }
  const definedDate = () => {
    setDateSelected(true);
    setStartDate1(null);
    setEndImmediate(1);

  }
  const definedDate2 = () => {
    setDateSelected(false);
    setStartDate1(new Date());
    setEndImmediate(0);

  }
  const validDays = () => {
    setDaysSelected(true);
    setValidImmediately(1);
    setValidUntilDays("0");
  }
  const validDays2 = () => {
    setDaysSelected(false);
    setValidImmediately(0);
    setValidUntilDays("0");
  }
  const validDate1 = () => {
    setValidDateSelected(true);
    setStartDate2(null);
  }
  const validDate2 = () => {
    setValidDateSelected(false);
    setStartDate2(new Date());
  }
  const quantityCust = () => {
    setCustomer(true);
    setUnlimitedCustomer(1);
    setLimitedCustomer("0");
    setQuantityOfCustomer("unlimited");
  }
  const quantityCust2 = () => {
    setCustomer(false);
    setUnlimitedCustomer(0);
    setLimitedCustomer("0");
  }
  // const is_free_content = () => {
  //   setFreeContent(true);
  // }
  // const is_not_free_content = () => {
  //   setFreeContent(false);
  // }
  const is_renew = () => {
    setRenew(1);
  }
  const is_not_renew = () => {
    setRenew(0);
  }
  const is_advance = () => {
    setReceivableAdvance(1);
  }
  const is_not_advance = () => {
    setReceivableAdvance(0);
  }
  const isPublic = (val) => {
    setPublicCourse(val);
  }
  // const isNotPublic = () => {
  //   setPublicCourse(0);
  //   alert("32423");

  // }

  // handle click event of the Remove button
  const removeBenefitsField = index => {
    const list = [...benefitsFieldAdd];
    list.splice(index, 1);
    setBenefitFieldAdd(list);
    console.log(list);
  };

  // handle click event of the Add button
  const addBenefitsField = (i) => {
    if (benefitsFieldAdd[i] === "") {
      alert('Please first add value in text box, then add more.');
    } else {
      setBenefitFieldAdd([...benefitsFieldAdd, ""]);
    }
  };

  const addBenefits = (value, index) => {
    var temp_benefits = [...benefitsFieldAdd];
    temp_benefits[index] = value;
    console.log(temp_benefits);
    setBenefitFieldAdd(temp_benefits)
  }

  const uploadImage = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/image_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var user_login = await instructorProfile();
    if (user_login !== null) {
      setTime(user_login.timezone)
    }
    props.toggleLoader(false);
  }, [])
  useEffect(async () => {
    props.toggleLoader(true)
    var get_instructor_list = await getInstructorList();
    setInstList(get_instructor_list);
    // console.log(instList);
    // console.log(get_instructor_list);
    // setValue(get_instructor_list);
    props.toggleLoader(false)
  }, [])
  // const instructorListing = (e) => {
  //   var temp_array = [];
  //   e.map(inst => {
  //     temp_array.push(inst.value);
  //   })
  //   setInstructorList(temp_array);
  //   console.log(temp_array);
  // }
  const options = [
    { value: 'a4253a6a-0146-4ccf-b58b-3d507b8f3d2d', label: 'Aastha Gupta' },
    { value: 'bdb0fcf4-ecbb-4955-b729-1294967600ca', label: 'Danny Lalwani' },
  ]

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>{I18n.t('Offers')}</h3>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/offers">{I18n.t('Offers')}</Link></li>
                        <li className="breadcrumb-item active">{I18n.t('Edit Offers')}</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 ">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Offer Name')}</label>
                              <input className="form-control" id="validationCustom01" type="text" required=""
                                data-bs-original-title="" title="" value={name}
                                placeholder={I18n.t('Offer Name')} onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Instructor')}</label>
                              <Select
                                isMulti
                                name="content-type"
                                options={instList}
                                onChange={e => {
                                  console.log(e);
                                  setInstructorList([]);
                                  var temp_array = []
                                  e.map((e_var) => {
                                    temp_array.push(e_var.value)
                                  })
                                  setInstructorList(temp_array)
                                }}
                                value={instList.filter(obj => instructor_list.includes(obj.value))}

                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Offer Price')}</label>
                              <input className="form-control" id="validationCustom01" type="text" required=""
                                data-bs-original-title="" title=""
                                value={free_content === true ? 0 : price}
                                readOnly={free_content === true ? true : false}
                                onChange={e => setPrice(e.target.value)} placeholder={I18n.t('Offer Price')} />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Free Content')}</label>
                              <select className="form-select" id="validationCustom04" required="" onChange={e => is_free_Content(e.target.value)}>
                                <option selected={(is_free == 1) ? true : false} value='1'>{I18n.t('no')}</option>
                                <option selected={(is_free == 0) ? true : false} value='0'>{I18n.t('yes')}</option>
                                <option selected={(is_free == 2) ? true : false} value='2'>{I18n.t('yes with passcode')}</option>
                              </select>
                            </div>
                          </div>
                          {free_content === true ? "" :
                            <div className="row">
                              <div className="col-md-3 mb-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Payment method')}</label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setPaymentMethod(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select payment method')}</option>
                                  {payment_list.map((pay) => (
                                    <option value={pay.id} selected={(pay.id === payment_method) ? true : false}>{I18n.t(pay.text)}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Installment Quantity')}  </label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setInstallmentQuantity(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select Installment Quantity')} </option>
                                  {installment_list.map((list) => (
                                    <option value={list.id} selected={(list.id === installment_quantity) ? true : false}>{list.text}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3 mb-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Interest Mode')}</label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setInterestMode(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select Interest MOde')}</option>
                                  {interest_list.map((mode) => (
                                    <option value={mode.id} selected={(mode.id === interest_mode) ? true : false}>{I18n.t(mode.text)}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Live Quantity')}</label>
                                <input className="form-control" id="validationCustom01" type="number" required="" data-bs-original-title="" title=""
                                  placeholder={I18n.t('Live Quantity')}
                                  onChange={e => setLiveQuantity(e.target.value)}
                                  value={live_quantity} />
                              </div>
                              {/* <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Renew')}</label>
                              <select className="form-select" id="validationCustom04" required="" >
                                <option selected={(renew === 0) ? true : false} onClick={is_not_renew}>{I18n.t('no')}</option>
                                <option selected={(renew === 1) ? true : false} onClick={is_renew}>{I18n.t('yes')}</option>
                              </select>
                            </div> */}

                            </div>
                          }
                          <div className="row">
                            {/* <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Advance')}</label>
                              <select className="form-select" id="validationCustom04" required="">
                                <option selected={(receivable_advance === 0) ? true : false} onClick={is_not_advance}>{I18n.t('no')}</option>
                                <option selected={(receivable_advance === 1) ? true : false} onClick={is_advance}>{I18n.t('yes')}</option>
                              </select>
                            </div> */}
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Categories')}</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setCategoryId(e.target.value)}>
                                <option value={""} required>{I18n.t('Select Category')}</option>
                                {category_list.map((list) => (
                                  <option value={list.id} selected={(list.id === category_id) ? true : false}>{I18n.t(list.name)}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Level')}</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setLevelId(e.target.value)}>
                                <option value={""} required>{I18n.t('Select Level')}</option>
                                {level_list.map((list) => (
                                  <option value={list.id} selected={(list.id === level_id) ? true : false}>{I18n.t(list.name)} </option>
                                ))}
                              </select>
                            </div>
                            {/* {free_content === 1 ?
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Live Quantity')}</label>
                                <input className="form-control" id="validationCustom01" type="number" required="" data-bs-original-title="" title=""
                                  placeholder={I18n.t('Live Quantity')} 
                                  onChange={e => setLiveQuantity(e.target.value)} 
                                  value={live_quantity}/>
                              </div>
                              : ""} */}
                            <div className="col-md-3 mb-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Image')}</label>
                              <div className="user__profile">
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  name="profile_image"
                                  onChange={e => uploadImage(e)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mb-3">
                              {(course_image_url !== null) ? <>
                                <label>
                                  {I18n.t('Image Download')}
                                </label>
                                <a download
                                  href={course_image_url} target="_blank"
                                  className="btn btn-light ml-2 btn-sm"
                                  data-toggle="tooltip" data-original-title="download url">
                                  <i className="fa fa-download" />
                                </a>
                              </> : ''}
                            </div>

                          </div>
                          <div className="row">

                            <div className="col-md-8 mb-3 ul-list">
                              <label className="form-label" for="validationCustom04">{I18n.t('Description')} </label>

                              <CKEditor
                                editor={ClassicEditor}
                                config={{
                                  toolbar: ['heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    '|',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'insertTable',
                                    '|',
                                    'undo',
                                    'redo', '|', 'link']
                                }}
                                data={description}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDescription(data)
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label" for="">{I18n.t('Is Public')} <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{I18n.t('if you want to make offer public set 1 otherwise 0')} </Tooltip>}
                              >
                                <i class="fas fa-info-circle"></i>
                              </OverlayTrigger></label>
                              <select onChange={e => isPublic(e.target.value)} className="form-select" id="validationCustom04" required="" >
                                <option selected={(publicCourse == 1) ? true : false} value="1" >{I18n.t('1')}</option>
                                <option selected={(publicCourse == 0) ? true : false} value="0" >{I18n.t('0')}</option>
                              </select>
                            </div>
                          </div>
                          {/* <div className="row">
                           

                          </div> */}
                          <div className="row g-3 mt-4">
                            <div className="col-md-8 ul-list">
                              <label className="form-label" for="validationCustom04">{I18n.t('Offer Description Mobile')} </label>
                              <textarea className="form-control" placeholder='Offer Description Mobile'
                                value={desc_mob} onChange={e => setDescMob(e.target.value)}></textarea>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label" for="">{I18n.t('% Release Before 7 Days')} </label>
                              <input className="form-control" id="validationCustom01" type="number"
                                placeholder={I18n.t('% Release Before 7 Days')} onChange={e => setReleasePercent(e.target.value)} value={release_percent}
                                min={10} max={100}
                                onKeyUp={(event) => {
                                  if (!/^([1-9][0-9]?|100)$/gm.test(event.key)) {
                                    event.preventDefault();
                                  }

                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-2 mb-3">
                              <label className="form-label" for="validationCustom03">{I18n.t('Publish Date / Time')} {'(' + (time) + ')'}</label>
                              <div className="">
                                <div className="mb-3 m-t-15">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1"
                                      data-bs-original-title="" title=""
                                      // defaultChecked={(Immediately !== undefined) ? true : false}
                                      checked={pubImmediate}
                                      onClick={selected} />
                                    <label className="form-check-label" for="radio1">{I18n.t('Immediately')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1"
                                      data-bs-original-title="" title=""
                                      // defaultChecked={(publish_date !== undefined) ? true : false}
                                      onClick={selected2}
                                      checked={!pubImmediate} />
                                    <label className="form-check-label" for="radio2">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {is_selected === true ? <DatePicker
                                disabled
                                showTimeSelect
                                dateFormat="yyyy-MM-dd h:mm aa"
                                customInput={
                                  <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                  />
                                }
                              /> :
                                <DatePicker
                                  selected={start_date}
                                  onChange={(date) => setStartDate(date)}
                                  timeInputLabel="Time:"
                                  dateFormat="yyyy-MM-dd HH:mm:ss"
                                  showTimeInput
                                  customInput={
                                    <input type="text" className="form-control" placeholder={I18n.t('Date')} onChange={e => setPublishDate(e.target.value)}
                                      value={publish_date} />
                                  }
                                />
                              }
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('End Date / Time')} {'(' + (time) + ')'}</label>
                              <div className="">
                                <div className="mb-3 m-t-15">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio3" type="radio" name="radio2" value="option1"
                                      defaultChecked={(date_selected !== undefined) ? true : false}
                                      onClick={definedDate}
                                    // checked={end_date === null ? true : false} 
                                    />
                                    <label className="form-check-label" for="radio3">{I18n.t('undefined')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio4" type="radio" name="radio2" value="option1"
                                      data-bs-original-title="" title=""
                                      defaultChecked={(days_selected !== undefined) ? true : false}
                                      onClick={definedDate2}
                                      checked={!endImmediate}
                                    />
                                    <label className="form-check-label" for="radio4">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mb-3 align-self-center">
                              {date_selected === true ?
                                <DatePicker
                                  disabled
                                  onChange={(date) => setStartDate1(date)}
                                  dateFormat="yyyy-MM-dd "
                                  customInput={
                                    <input type="text" className="form-control" placeholder={I18n.t('Date')} />
                                  }
                                  x
                                /> :
                                <DatePicker
                                  selected={start_date1}
                                  onChange={(date) => setStartDate1(date)}
                                  timeInputLabel="Time:"
                                  dateFormat="yyyy-MM-dd HH:mm:ss"
                                  showTimeInput
                                  customInput={
                                    <input type="text" className="form-control" placeholder={I18n.t('Date')} onChange={e => setEndDate(e.target.value)}
                                      value={end_date} />
                                  }
                                />}
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Quantity of customer')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio9" type="radio" name="radio5" value="option1"
                                      data-bs-original-title="" title="" defaultChecked={(unlimitedCustomer !== undefined) ? true : false}
                                      onClick={quantityCust} />
                                    <label className="form-check-label" for="radio9">{I18n.t('unlimited')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio10" type="radio" name="radio5" value="option1"
                                      data-bs-original-title="" title="" defaultChecked={(limitedCustomer !== undefined) ? true : false}
                                      onClick={quantityCust2} />
                                    <label className="form-check-label" for="radio10">{I18n.t('limited')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {customer === true ?
                                <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                  value={quantity_of_customer}
                                  title="" disabled onChange={e => setQuantityOfCustomer(e.target.value)}
                                /> :
                                <input className="form-control" id="validationCustom01" type="text" value={quantity_of_customer} required="" data-bs-original-title=""
                                  title="" onChange={e => setQuantityOfCustomer(e.target.value)}
                                />}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              {module_list.map((list, index) => (
                                <Accordion className="accordion-top-set mb-3">
                                  <Card >
                                    <Card.Header style={{ height: '50px' }}>
                                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <div className="row g-3">
                                          <i className="fas fa-plus-circle" style={{ marginRight: '10px' }} />
                                          {/* <div className="form-check checkbox mb-0">
                                            <input className="form-check-input" id={"header" + index} type="checkbox" data-bs-original-title="" title="" />
                                          </div> */}
                                          <label className="form-check-label" for={"header" + index}>
                                            {list.title}
                                          </label>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                      <Card.Body>
                                        <div className="mb-3 m-t-15">
                                          {list.module.map((listing, index2) => (
                                            <div className="form-check checkbox mb-0">
                                              <input className="form-check-input" id={"module-" + index + '-' + index2}
                                                type="checkbox" data-bs-original-title="" title=""
                                                checked={(module_ids !== undefined) ? (module_ids.includes(listing.id)) ?
                                                  true : false : false}
                                                onClick={e => { selectModules(e, listing.id) }}
                                              />
                                              <label className="form-check-label" for={"module-" + index + '-' + index2}>{listing.title}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              ))}
                            </div>


                            <div className="col-md-6">
                              {benefitsFieldAdd.map((x, i) => {
                                return (
                                  <div className="row" key={"benefits_" + i}>
                                    <div className="col-10 mb-2">
                                      {(i === 0) ? <label className="form-label">{I18n.t('Explain Course Benefits')}</label> : ""}
                                      <input
                                        key={"benefits_textbox_" + i}
                                        className={(i === 0) ? "form-control" : "form-control mb-2"}
                                        type="text"
                                        placeholder="Benefits"
                                        onChange={e => addBenefits(e.target.value, i)}
                                        value={benefitsFieldAdd[i]}
                                      />
                                    </div>

                                    <div className="col-2 align-self-center">
                                      {benefitsFieldAdd.length !== 1 &&
                                        <i className="fas fa-minus-circle mr-1 text-danger"
                                          onClick={() => removeBenefitsField(i)}></i>}
                                      {benefitsFieldAdd.length - 1 === i && <i className="fas fa-plus-circle text-success" onClick={e => addBenefitsField(i)}></i>}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                          </div>

                          <div className="row" disabled>
                            {/* <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Quantity of customer')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio9" type="radio" name="radio5" value="option1"
                                      data-bs-original-title="" title="" defaultChecked={(unlimitedCustomer !== undefined) ? true : false}
                                      onClick={quantityCust} />
                                    <label className="form-check-label" for="radio9">{I18n.t('unlimited')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio10" type="radio" name="radio5" value="option1"
                                      data-bs-original-title="" title="" defaultChecked={(limitedCustomer !== undefined) ? true : false}
                                      onClick={quantityCust2} defaultChecked={true} />
                                    <label className="form-check-label" for="radio10">{I18n.t('limited')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {customer === true ?
                                <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                  title="" disabled
                                /> :
                                <input className="form-control" id="validationCustom01" type="text" value={quantity_of_customer} required="" data-bs-original-title=""
                                  title="" onChange={e => setQuantityOfCustomer(e.target.value)}
                                />}
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <button className="btn btn-primary" type="submit" data-bs-original-title="" title=""
                          onClick={editOffer} >
                          <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}</button>
                        &nbsp;
                        <Link to="/offers">
                          <button className="btn btn-dark" type="submit" data-bs-original-title="" title="">
                            <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}</button>
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);