const translations = {
  pt: {
    "yes with passcode": "sim com senha",
    "if you want to make offer public set 1 otherwise 0": "se você quiser fazer uma oferta pública defina 1 caso contrário 0",
    "Inactive": "Inativa",
    "Active": "Ativa",
    "Delete": "Excluir",
    "Edit": "Editar",
    "% Release Before 7 Days": "% Liberar antes de 7 dias",
    "Live Quantity": "Quantidade ao vivo",
    "Is Public": "É público",
    "Offer Description Mobile": "Descrição da Oferta Celular",
    "Share": "Compartilhado",
    "Edit InfoProduct": "Editar informações do produto",
    "Delete InfoProduct": "Excluir InfoProduto",
    "Inactive InfoProduct": "Infoproduto inativo",
    "Active InfoProduct": "Infoproduto ativo",
    "Governance": "Governança",
    "Innovation": " Inovação",
    "Technology": "Tecnologia",
    "Management": "Gestão",
    "Marketing": "Marketig",
    "Legal": " Direito",
    "Accounting": "Contabilidade",
    "Finance": "Finanças",
    "Sort Topic": "Classificar tópico",
    "active Topic": "Tópico ativo",
    "inactive Topic": "Tópico inativo",
    "Delete Topic": "Excluir tópico",
    "Edit Topic": "Editar tópico",
    "active Module": "Módulo ativo",
    "inactive Module": "Módulo inativo",
    "Sort Topics": "Classificar tópicos",
    "View Topic": "Ver tópico",
    "New Topic": "Novo topico",
    "Delete Module": "Excluir módulo",
    "Edit Module": "Editar módulo",
    "Log In": "Conecte-se",
    "Register": "Registro",
    "Login": "Conecte-se",
    "Forgot Password?": "Esqueceu sua senha?",
    "Password": "Senha",
    "Enter Email Address": "Insira o endereço de e-mail",
    "Log in to continue in our website": "Faça login para continuar em nosso site",
    "Welcome To On Global": "Bem-vindo ao On Global",
    "Confirm Password": "Confirme a Senha",
    "Enter Last Name": "Insira o último nome",
    "Enter First Name": "Introduza o primeiro nome",
    "Register to continue in our website": "Cadastre-se para continuar em nosso site",
    "New Password": "Nova Senha",
    "Change Password": "Mudar senha",
    "Forgot Password": "Esqueceu sua senha",
    "Question answer": "Resposta recebida",
    "Save": "Salvar",
    "Specialist In": "Especialista em",
    "About You": "Sobre você",
    "Select State": "Selecione o estado",
    "Select City": "Selecione a cidade",
    "Select Country": "Selecione o pais",
    "Address": "Endereço do Evento",
    "Address": "Endereço",
    "User Image": "Imagem do usuário",
    "Zip Code": "CEP",
    "Email Address": "e-mail",
    "First Name": "Primeiro nome",
    "Last Name": "Último nome",
    "My Profile": "Meu Perfil",
    "There was some error in image upload": "Ocorreu algum erro no upload da imagem",
    "Logout Successfull": "Logout bem sucedido",
    "Login Successfull": "Login bem sucedido",
    "Hello": "Olá",
    "Generate Report": "Gerar Relatório",
    "Let's explore": "Vamos explorar",
    "by popular": "por popular",
    "Course": "Curso",
    "All Category": "Todas as categorias",
    "Filters": "Filtros",
    "Profile": "Perfil",
    "Activity Log": "Registro de atividade",
    "Earnings (Annual)": "Lucro (anual)",
    "Course Completed": "Curso Concluído",
    "Offers": "Ofertas",
    "Content": "Conteúdo",
    "InfoProduct": "Infoproduto",
    "Dashboard": "Painel",
    "Pricing": "Preços",
    "Module": "Módulo",
    "Live": "Live",
    "Certificates": "Certificados",
    "Cross Sell": "Venda cruzada",
    "Interactivity": "Interatividade",
    "Read More Messages": "Leia mais mensagens",
    "Message Center": "Centro de Mensagens",
    "Alerts Center": "Centro de Alertas",
    "Search for...": "Procurar por...",
    "My Reviews": "Minhas Avaliações",
    "View all": "Ver tudo",
    "Copyright": "Direito autoral",
    "Action": "Ação",
    "Status": "Status",
    "Durability": "Durabilidade",
    "Validity in days": "Validade em dias",
    "Old": "Velho",
    "Active": "Ativo",
    "New InfoProduct": "Novo infoproduto",
    "Inactive": "Inativo",
    "Return": "Retornar",
    "Save Changes": "Salvar alterações",
    "Date": "Data",
    "undefined": "indefinido",
    "Purchase Durability": "Durabilidade de Compra",
    "number of days": "número de dias",
    "Immediately": "Imediatamente",
    "Purchase Validity In Days": "Validade de compra em dias",
    "Infoproduct Title": "Título do infoproduto",
    "Add Infoproduct": "Adicionar infoproduto",
    "Edit Infoproduct": "Editar infoproduto",
    "Edit Offers": "Editar Ofertas",
    "limited": "limitado",
    "unlimited": "ilimitado",
    "Quantity of customer": "Quantidade de cliente",
    "Valid until Date": "Válido até a data",
    "Days Quantity": "Quantidade de dias",
    "Valid until Days": "Válido até dias",
    "End Date / Time": "Data / hora de término",
    "Date / Time": "Data / hora",
    "Publish Date / Time": "Data / hora de publicação",
    "Select Level": "Selecione o nível",
    "Level": "Nível",
    "Select Category": "Selecione a Categoria",
    "Categories": "Categorias",
    "Description": "Descrição",
    "Image": "Imagem",
    "yes": "sim",
    "no": "não",
    "Renew": "Renovar",
    "Select Interest MOde": "Selecione o modo de interesse",
    "Interest MOde": "Modo de interesse",
    "Select Installment Quantity": "Selecione a quantidade de parcelas",
    "Select payment method": "Selecione o método de pagamento",
    "Payment method": "Forma de pagamento",
    "Please select a valid state.": "Selecione um estado válido.",
    "Free Content": "Conteúdo Gratuito",
    "Offer Price": "Preço de oferta",
    "Offer Name": "Nome da oferta",
    "Add Offers": "Adicionar ofertas",
    "Advance": "Avançar",
    "Share": "Compartilhar",
    "Installment": "Parcelas",
    "Installments": "Parcelas",
    "Monthly Installments": "parcelas mensais",
    "Method": "Método",
    "Value": "Valor",
    "Do you want to delete this offer?": "Você quer deletar esta oferta?",
    "New Offer": "Nova oferta",
    "Team Member": "Membro da Equipe",
    "S no.": "S no.",
    "User": "Usuário",
    "Edit": "Editar",
    "Delete": "Excluir",
    "Anonymous": "Anônima",
    "Do you want to delete this module?": "Você quer deletar este módulo?",
    "Are you sure?": "Tem certeza?",
    "Re-order": "Reordenar",
    "View Topic": "Ver Tópico",
    "No data added": "Sem dados adicionados",
    "Free?": "Gratuitamente?",
    "Module Name": "Nome do módulo",
    "Infoproduct": "Infoproduto",
    "Add Module": "Adicionar Módulo",
    "Reset": "Redefinir",
    "Submit": "Enviar",
    "There are no Offers at the moment to add to this offer You can add them later!!": "No momento, não há ofertas a serem adicionadas a esta oferta. Você pode adicioná-los mais tarde!!",
    "Select Offer": "Selecione a oferta",
    "Select Product": "Selecione o produto",
    "Select Infoproduct": "Selecione o infoproduto",
    "Module Add": "Adicionar Módulo",
    "Module Edit": "Editar Modulo",
    "Order": "Pedido",
    "Preview": "Visualizar",
    "Settings": "Configurações",
    "Certificate File": "Arquivo de Certificado",
    "Quantity Lives for Certificate": "Quantidade de Lives para certificado",
    "Certificate Goal": "Meta de certificado",
    "Freedom": "Liberdde",
    "digital Marketing": "Marketing Digital",
    "Offer": "Oferta",
    "New Certificate": "Novo Certificado",
    "Certificate": "Certificado",
    "Banner Image": "Imagem de banner",
    "Limited": "Limitado",
    "Unlimited": "Ilimitado",
    "Quantity of Customers": "Quantidade de clientes",
    "No": "Não",
    "Yes": "Sim",
    "Paid by Producer": "Pago pelo produtor",
    "Paid by Customer": "Pago pelo cliente",
    "Paid By Producer": "Pago pelo produtor",
    "Paid By Customer": "Pago pelo cliente",
    "Educational": "Educacional",
    "Personal Development": "Desenvolvimento pessoal",
    "Business and Career": "Negócios e Carreira",
    "Health and Sports": "Saúde e Esportes",
    "Finance and Investments": "Finanças e Investimentos",
    "Beginner": "Principiante",
    "Intermediate": "Intermediário",
    "Expert": "Especialista",
    "Interest Mode": "Modo de Interesse",
    "Installment Quantity": "Quantidade de Parcela",
    "Monthly": "Mensal",
    "Single Payment": "Pagamento único",
    "Payment Terms": "Termos de pagamento",
    "% Discount": "% Desconto",
    "Cross Sell Title": "Título de Cross Sell",
    "New Cross Sell": "Novo Cross Sell",
    "Enter Start Date": "Insira a data de início",
    "starting From": "Começando em",
    "Bonus Content": "Conteúdo bônus",
    "Send e-mail Notification": "Enviar notificação por e-mail",
    "Consider For Certificate": "Considerar para certificado",
    "Promotional Image": "Imagem Promocional",
    "Launch Date / Time": "Data / hora de lançamento",
    "Embedded Link Zoom": "Link do Zoom",
    "Enable Watermark": "Habilitar marca d'água",
    "Live Link": "Link da Live",
    "Info": "Informações",
    "Hide": "Ocultar",
    "Question answer": "Resposta recebida",
    "Course not available": "Curso não disponível",
    "Review not available": "Revisão não disponível",
    "Enroll the course": "Inscreva-se no curso",
    "Enroll the student": "Matricule o aluno",
    "Question": "Pergunta",
    "Answer": "Responda",
    "New Question": "Nova pergunta",
    "Student asked a new question": "Aluno fez uma nova pergunta",
    "Live Title": "Título da Live",
    "Start Date": "Data de início",
    "Starting at": "Começando às",
    "From the start": "Do começo",
    "Start Time": "Hora de início",
    "Select": "Selecione",
    "Specific": "Específico",
    "Random": "Aleatório",
    "PDF Resource": "Recurso PDF",
    "Duration (Minutes)": "Duração (minutos)",
    "Text Content": "Conteúdo",
    "Bonus content": "Conteúdo bônus",
    "Send email notifcation": "Enviar notificação por e-mail",
    "Enable watermark for Video": "Habilitar marca d'água para vídeo",
    "Video Url": "URL do vídeo",
    "New Topic": "Novo topico",
    "Modules": "Módulos",
    "% Certificate Goal": "% Meta de certificado",
    "Webhooks": "Webhooks",
    "Manage Team": "Gerenciar Equipe",
    "Afilliates": "Afiliados",
    "CressSell": "Cross Sell",
    "UpSell": "UpSell",
    "Invoice": "Fatura",
    "Sales": "Vendas",
    "Revenue": "Receita",
    "Co-production": "Coprodução",
    "Events": "Eventos",
    "Checkout": "Checkout",
    "Sales Copy": "Cópia de Vendas",
    "Allow access to:": "Permitir acesso a:",
    "New User": "Novo usuário",
    "Add Team Member": "Adicionar membro da equipe",
    "Goal": "Meta",
    "Change": "Mudar",
    "Current Password": "Senha atual",
    "Return to Module List": "Retornar à lista de módulos",
    "Add Topic": "Adicionar Tópico",
    "Email Verified successfully! Please login to continue": "Email verificado com sucesso! Por favor faça o login para continuar",
    "Enable watermark for PDF": "Habilitar marca d'água para PDF",
    "Back": "Voltar",
    "Title": "Título",
    "Total Offers": "Total de ofertas",
    "Total Ratings": "Avaliações totais",
    "Total Bookings": "Total de reservas",
    "Total Revenue": "Rendimento total",
    "Topic": "Tema",
    "Question": "Pergunta",
    "Response": "Resposta",
    "Responses": "Resposta",
    "Sample PDF": "PDF de amostra",
    "Sample Image": "Imagem de amostra",
    "Close": "Fechar",
    "Youtube Embed Link": "Link do Youtube",
    "Vimeo Link": "Link do Vimeo",
    "Explain Course Benefits": "Explique os benefícios do curso",
    "Hotmart Course ID": "ID do curso Hotmart",
    "Hotmart Checkout Link": "Link de checkout do Hotmart",
    "Topic Name": "Nome do tópico",
    "Video": "vídeo",
    "New PDF Resource": "Novo recurso de PDF",
    "Current PDF Resource": "Recurso PDF atual",
    "Enable watermark for pdf": "Habilitar marca d'água para pdf",
    "Image Download": "Download da Imagem",
    "Role": "Função",
    "Select TimeZone": "Selecione TimeZone",
    "Overall Questions": "Perguntas Gerais",
    "Integrations": "Integrações",
    "Add Question": "Adicionar pergunta",
    "API key": "Chave API",
    "API Token": "Token API",
    "Delete Token": "Apagar Token",
    "Delete": "Excluir",
    "Answer": "Responder",
    "Quantity": "Quantidade",
    "Live": "Ao vivo",
    "New Live Event": "Novo Evento ao Vivo",
    "Duration": "Duração",
    "Update": "atualizar",
    "Value Offer": "Proposta de valor",
    "Cross Sell Assignments": "Atribuições de venda cruzada",
    "New Cross Sell Assignments": "Novas atribuições de venda cruzada",
    "Add Cross Sell Assignments": "Adicionar atribuições de venda cruzada",
    "Edit Cross Sell Assignments": "Editar atribuições de venda cruzada",
    "Cross Sell Assignments Event": "Eventos de atribuições de venda cruzada",
    "Live Events": "Eventos ao vivo",
    "New Cross Sell Assignments Events": "Novos eventos de atribuições de venda cruzada",
    "Add Cross Sell Assignment Event": "Adicionar eventos de atribuiçõe de venda cruzada",
    "Edit Cross Sell Assignment Event": "Editar Evento de Atribuição de Venda Cruzada",
    "Select Module": "selecione o módulo",
    "Select Topic": "Selecione o tema",
    "No live event": "Sem evento ao vivo ",
    "Edit Live Event": "Editar evento ao vivo",
    "Please Select Product First": "Selecione o produto primeiro",
    "No Questions Exist": "Não existem perguntas",
    "No CrossSell": "Sem CrossSell",
    "No CrossSell Assignments": "Sem atribuições CrossSell",
    "No CrossSell Assignments Event": "Nenhum evento de atribuições CrossSell",
    "Select Cross Sell": "Selecione Venda Cruzada",
    "Select Events": "Selecione Eventos",
    "Price": "preço",
    "Do you want to delete this Question": "Deseja excluir esta pergunta",
    "Speaker Name": "Nome do Instrutor",
    "Speaker Designation": "Sobre o Instrutor",
    "Event Detail": "Detalhes da Live",
    "Password reset email has been sent": "O e-mail de redefinição de senha foi enviado",
    "Password Reset Successfully": "Senha redefinida com sucesso",
    //400 and 401 error 
    "Invalid login credential": "Credencial de login inválida",
    "Please verify your email address first": "Verifique seu endereço de e-mail primeiro",
    "Token is empty": "O token está vazio",
    "Invalid Token": "Token inválido",
    "Already have an certificate in this offer": "Já tem um certificado nesta oferta",
    "This offer is not associated with you": "Esta oferta não está associada a você",
    "you are not authorized to edit this Certificates": "você não está autorizado a editar este Certificados",
    "Already have an certificate in this offer": "Já tem um certificado nesta oferta",
    "you are not authorized to update this Certificates": "você não está autorizado a atualizar estes Certificados",
    "you are not authorized to delete this Certificates": "você não está autorizado a excluir estes certificados",
    "User id not found": "ID do usuário não encontrado",
    "Course id not found": "ID do curso não encontrado",
    "This offer does not exists": "Esta oferta não existe",
    "This product is not associated with you": "Este produto não está associado a você",
    "you are not authorized to edit this content": "você não está autorizado a editar este conteúdo",
    "you are not authorized to update this content": "você não está autorizado a atualizar este conteúdo",
    "you are not authorized to delete this content": "você não está autorizado a excluir este conteúdo",
    "you are not authorized to edit this Cross Sell": "você não está autorizado a editar esta venda cruzada",
    "you are not authorized to update this Cross Sell": "você não está autorizado a atualizar esta venda cruzada",
    "you are not authorized to delete this Cross Sell": "você não está autorizado a excluir esta venda cruzada",
    "you are not authorized to edit this Cross Sell Events": "você não está autorizado a editar estes eventos de venda cruzada",
    "you are not authorized to update this Cross Sell": "você não está autorizado a atualizar esta venda cruzada",
    "you are not authorized to delete this Cross Sell": "você não está autorizado a excluir esta venda cruzada",
    "this offer is not available any longer": "esta oferta não está mais disponível",
    "you are not authorized to update this answer": "você não está autorizado a atualizar esta resposta",
    "You are not authorized to delete this Token": "Você não está autorizado a excluir este Token",
    "you are not authorized to edit this live content": "você não está autorizado a editar este conteúdo ao vivo",
    "you are not authorized to update this live content": "você não está autorizado a atualizar este conteúdo ao vivo",
    "you are not authorized to delete this live content": "você não está autorizado a excluir este conteúdo ao vivo",
    "This content & product is not associated with you": "Este conteúdo e produto não estão associados a você",
    "you are not authorized to edit this module": "você não está autorizado a editar este módulo",
    "you are not authorized to update this module": "você não está autorizado a atualizar este módulo",
    "you are not authorized to delete this module causes its in used": "você não está autorizado a excluir este módulo faz com que ele seja usado",
    "you are not authorized to delete this module": "você não está autorizado a excluir este módulo",
    "you are not authorized to edit this Offer": "você não está autorizado a editar esta Oferta",
    "you are not authorized to update this offer": "você não está autorizado a atualizar esta oferta",
    "This offer cannot be removed as it is booked by some users": "Esta oferta não pode ser removida porque foi reservada por alguns usuários",
    "you are not authorized to delete this offer": "você não está autorizado a excluir esta oferta",
    "you are not authorized to access this product and offer": "você não está autorizado a acessar este produto e oferecer",
    "you are not authorized to edit this product": "você não está autorizado a editar este produto",
    "you are not authorized to update this product": "você não está autorizado a atualizar este produto",
    "you are not authorized to delete this product causes its in used": "você não está autorizado a excluir este produto faz com que ele seja usado",
    "you are not authorized to delete this product": "você não está autorizado a excluir este produto",
    "you are not authorized to update this product": "você não está autorizado a atualizar este produto",
    "Invalid Email": "E-mail inválido",
    "Token expired, please repeat again": "O token expirou. Repita novamente",
    "Reset Token that you entered is invalid": "O token de redefinição inserido é inválido",
    "Invalid login": "Login inválido",
    "This Course is already enrolled by you": "Este curso já está inscrito por você",
    "This Cross sell is no longer available": "Esta venda cruzada não está mais disponível",
    "This Offer is no longer available": "Essa oferta não está mais disponível",
    "you need to enroll this course for rating": "você precisa se inscrever neste curso para classificação",
    "Course id not found": "ID do curso não encontrado",
    "You need to enroll this course than you can able to see this": "Você precisa se inscrever neste curso do que você pode ver isso",
    "You need to enroll the related course than you can able to see this": "Você precisa se inscrever no curso relacionado do que pode ver isso",
    "This event does not belongs to any offe": "Este evento não pertence a nenhuma oferta",
    "You are not allowed to post question here": "Você não tem permissão para postar perguntas aqui",
    "No Data Found": "Nenhum dado encontrado",
    "This Offer does not contains any certificate in it": "Esta Oferta não contém nenhum certificado nela",
    "you need to enroll this course first": "você precisa se inscrever neste curso primeiro",
    "This product does not exists": "Este produto não existe",
    "This topic does not exists": "Este tópico não existe",
    "You are not authorized to delete this question": "Você não está autorizado a excluir esta pergunta",
    "This Cross sell is no longer available": "Esta venda cruzada não está mais disponível",
    "Customer create on payment gatway have some issues": "A criação do cliente no gateway de pagamento tem alguns problemas",
    "An error occurred while processing your request Please try again later": "Um erro ocorreu durante o processamento do seu pedido Por favor, tente novamente mais tarde",
    "This Module is not associated with you": "Este Módulo não está associado a você",
    "you are not authorized to edit this topic": "você não está autorizado a editar este tópico",
    "This Module is not associated with you": "Este Módulo não está associado a você",
    "you are not authorized to delete this topic": "você não está autorizado a excluir este tópico",
    "you are not authorized to delete this topic video": "você não está autorizado a excluir este vídeo de tópico",
    "you are not authorized to delete this topic file": "você não está autorizado a excluir este arquivo de tópico",


    // error message translation
    "The title field is required": "O campo título é obrigatório",
    "The product id field is required": "O campo ID do produto é obrigatório",
    "The name field is required": "O campo nome é obrigatório",
    "The price field is required": "O campo preço é obrigatório",
    "The media id field is required": "O campo de ID de mídia é obrigatório",
    "The category id field is required": "O campo ID da categoria é obrigatório",
    "The description must be a string": "A descrição deve ser uma string",
    "The level id field is required": "O campo ID do nível é obrigatório",
    "The selected payment method is invalid": "A forma de pagamento selecionada é inválida",
    "The installment quantity field is required": "O campo quantidade da parcela é obrigatório",
    "The interest mode field is required": "O campo do modo de interesse é obrigatório",
    "The offer_benefit0 field is required": "O campo offer_benefit0 é obrigatório",
    "The offer id field is required": "O campo ID da oferta é obrigatório",
    "The goal field is required": "O campo de meta é obrigatório",
    "The quantity field is required": "O campo quantidade é obrigatório",
    "The goal must be a string": "O objetivo deve ser uma string",
    "The question field is required": "O campo de pergunta é obrigatório",
    "The answer field is required": "O campo de resposta é obrigatório",
    "The module id field is required": "O campo ID do módulo é obrigatório",
    "The topic id field is required": "O campo de ID do tópico é obrigatório",
    "The api key field is required": "O campo chave da API é obrigatório",
    "The api token field is required": "O campo de token de API é obrigatório",
    "The discount percentage must be an integer": "A porcentagem de desconto deve ser um número inteiro",
    "The Cross sell id field is required": "O campo ID de venda cruzada é obrigatório",
    "The event id field is required": "O campo ID do evento é obrigatório",



    "The current password field is required": "O campo de senha atual é obrigatório",
    "The password field is required": "O campo de senha é necessária",
    "The confirm password field is required": "O campo de confirmação de senha é obrigatório",
    "The country id must be an integer": "O código do país deve ser um número inteiro",
    "The state id must be an integer": "O ID do estado deve ser um número inteiro",
    "The city id must be an integer": "O código da cidade deve ser um número inteiro",
    "The first name must be a string": "O primeiro nome deve ser uma string",
    "The last name must be a string": "O sobrenome deve ser uma string",
    "The rating field is required": "O campo de avaliação é obrigatório",
    "The email field is required": "O campo de e-mail é obrigatório",
    "The email must be a valid email address": "O e-mail deve ser um endereço de e-mail válido",
    "The first name field is required": "O campo de nome é obrigatório",
    "The last name field is required": "O campo sobrenome é obrigatório",
    "The last name must be at least 3 characters": "O sobrenome deve ter pelo menos 3 caracteres",
    "The first name must be at least 3 characters": "O primeiro nome deve ter pelo menos 3 caracteres",
    "The password must be at least 6 characters": "A senha deve ter pelo menos 6 caracteres",
    "The password confirmation does not match": "A confirmação da senha não corresponde",
    "Invalid login credential": "Credencial de login inválida",
    "The selected email is invalid": "O e-mail selecionado é inválido",
    "The card number field is required": "O campo do número do cartão é obrigatório",
    "The card holder name field is required": "O campo do nome do titular do cartão é obrigatório",
    "The card security code field is required": "O campo do código de segurança do cartão é obrigatório",
    "The card validity field is required": "O campo de validade do cartão é obrigatório",
    "The cpf field is required": "O campo cpf é obrigatório",
    "The address field is required": "O campo de endereço é obrigatório",
    "The state id field is required": "O campo ID do estado é obrigatório",
    "The city id field is required": "O campo ID da cidade é obrigatório",
    "The zip code field is required": "O campo CEP é obrigatório",
    "Customer create on payment gatway have some issues": "A criação do cliente no gateway de pagamento tem alguns problemas",
  },
  es: {

  },
};
export default translations;