import React, { useEffect, useCallback, useState, useRef } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, userLoggedIn, getCountries, getStates, getCities, instructorProfile, getTimezoneDropdown } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import TopNav from '../components/TopNav';

const MyProfile = (props) => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [country_id, setCountryId] = useState('');
  const [state_id, setStateId] = useState('');
  const [city_id, setCityId] = useState('');
  const [cpf, setCPF] = useState('');
  const [role_txt, setRoleTxt] = useState('');
  const [about_you, setAboutYou] = useState('');
  const [speciality_in, setSpecialityIn] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [instagram, setInstagram] = useState('');

  const [timezone, setTimezone] = useState([]);
  const [time, setTime] = useState('');

  const [user_image, setUserImage] = useState('/img/default.jpg');

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageData, setImageData] = useState('/img/default.jpg');
  const previewCanvasRef = useRef(null);
  const [show, setShow] = useState(false);


  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShow(true)
    }
  };

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        var dataURL = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
        const data = new FormData();
        data.append('media', dataURL);
        var token = localStorage.getItem('token');
        props.toggleLoader(true);
        let path = config.siteUrl;
        axios.post(path + '/user/profilepicture', data, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
            props.toggleLoader(false);
            displaySuccess('Profile Picture Uploaded Successfully');
            setImageData(response.data.data.media.url)
            setUpImg(null);
            setShow(false)
            setTimeout(() => window.location.reload(), 1500);
            document.getElementById('profile_picture').value = null;
          })
          .catch(function (error) {
            props.toggleLoader(false);
            if (error.response.status === 401) {
              window.location.href = '/';
            }
            displayError(error);
          });
      },
      'image/png',
      1
    );
  }

  const handleClose = () => {
    setShow(false);
    document.getElementById('profile_picture').value = null;
  }

  useEffect(async () => {
    props.toggleLoader(true)
    var get_countries = await getCountries();
    setCountries(get_countries);
    console.log(get_countries);
    props.toggleLoader(false)
  }, [])

  const fetchStates = async (country_id) => {
    props.toggleLoader(true);
    var get_states = await getStates(country_id);
    setStates(get_states);
    setCountryId(country_id)
    props.toggleLoader(false);
  }

  const fetchCities = async (state_id) => {
    props.toggleLoader(true);
    var get_cities = await getCities(state_id);
    setCities(get_cities);
    setStateId(state_id)
    props.toggleLoader(false);
  }

  const updateUserProfile = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.patch(path + '/user/updateuser/instructor', {
      first_name: first_name,
      last_name: last_name,
      address: address,
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      zip_code: zip_code,
      cpf: cpf,
      role_txt: role_txt,
      speciality_in: speciality_in,
      facebook: facebook,
      twitter: twitter,
      linkedin: linkedin,
      about_you: about_you,
      timezone: time,
      instagram:instagram,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('User Profile Updated Successfully');
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  useEffect(async () => {
    props.toggleLoader(true);
    var user_login = await instructorProfile();
    if (user_login !== null) {
      setFirstName(user_login.first_name);
      setLastName(user_login.last_name);
      setEmail(user_login.email);
      setAddress(user_login.address);
      setCountryId(user_login.country_id);
      setStateId(user_login.state_id);
      setCityId(user_login.city_id);
      setZipCode(user_login.zip_code)
      setCPF(user_login.cpf)
      setRoleTxt(user_login.role_txt)
      setSpecialityIn(user_login.speciality_in)
      setFacebook(user_login.facebook)
      setTwitter(user_login.twitter)
      setLinkedin(user_login.linkedin)
      setAboutYou(user_login.about_you)
      setTime(user_login.timezone)
      setInstagram(user_login.instagram);
      if (user_login.state !== null) {
        var get_states = await getStates(user_login.country_id);
        setStates(get_states);
      }
      if (user_login.city !== null) {
        var get_cities = await getCities(user_login.state_id);
        setCities(get_cities);
      }
      if (user_login.media !== null) {
        setImageData(user_login.media.url);
      }
    }
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    props.toggleLoader(true)
    var get_time = await getTimezoneDropdown();
    setTimezone(get_time);
    console.log(get_time);
    props.toggleLoader(false)
  }, [])
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>{I18n.t('My Profile')}</h3>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item">{I18n.t('My Profile')} </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label" for="validationCustom01">{I18n.t('First Name')}</label>
                                <input value={first_name} className="form-control" id="validationCustom01" type="text"
                                  placeholder={I18n.t('First Name')} required="" data-bs-original-title="" title="" onChange={e =>
                                    setFirstName(e.target.value)} />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label" for="validationCustom01">{I18n.t('Last Name')}</label>
                                <input value={last_name} className="form-control" id="validationCustom01" type="text"
                                  placeholder={I18n.t('Last Name')} required="" data-bs-original-title="" title="" onChange={e =>
                                    setLastName(e.target.value)} />
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label" for="validationCustom01">{I18n.t('Email Address')}</label>
                                <input value={email} className="form-control" id="validationCustom01" type="text" disabled
                                  placeholder={I18n.t('Email Address')} required="" data-bs-original-title="" title="" />
                              </div>

                              <div className="col-md-4 mb-3">
                                <label className="form-label" for="validationCustom01">{I18n.t('Zip Code')}</label>
                                <input value={zip_code} className="form-control" id="validationCustom01" type="text"
                                  placeholder={I18n.t('Zip Code')} required="" data-bs-original-title="" title="" onChange={e =>
                                    setZipCode(e.target.value)} />
                              </div>
                            </div>
                          </div>


                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom01">{I18n.t('User Image')}</label>
                            <div className="user__profile">
                              <img
                                src={imageData}
                                width="110px"
                                height="110px"
                              />
                              <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" onChange={onSelectFile} />
                              {/* <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ marginLeft: "20px" }}>
                              <i className="fa fa-pen" aria-hidden="true"></i> Change </button>*/}
                            </div>
                          </div>
                        </div>
                        <Modal size="lg" show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>{I18n.t('Update Profile Picture')}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>

                            <div className="row">
                              <div className="col-md-6 mt-3">
                                {
                                  (upImg) ? <>
                                    <div className="col-md-12 mt-3">
                                      <center>
                                        <ReactCrop
                                          src={upImg}
                                          onImageLoaded={onLoad}
                                          crop={crop}
                                          onChange={(c) => setCrop(c)}
                                          onComplete={(c) => setCompletedCrop(c)}
                                          className="react-crop-image"
                                        />
                                      </center>
                                    </div>
                                  </> : ""
                                }
                              </div>
                              <div className="col-md-6 mt-3">
                                {
                                  (upImg) ? <>
                                    <canvas
                                      ref={previewCanvasRef}
                                      style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0)
                                      }}
                                    />
                                  </>
                                    : <>
                                    </>
                                }
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn btn-warning mr-2"
                              type="button"
                              disabled={!completedCrop?.width || !completedCrop?.height}
                              onClick={() =>
                                generateDownload(previewCanvasRef.current, completedCrop)
                              }
                            >
                              {I18n.t('Upload cropped image')}
                            </button>
                            <Button variant="secondary" onClick={handleClose}> {I18n.t('Close')} </Button>
                          </Modal.Footer>
                        </Modal>

                        <div className="row g-3">
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom01"> {I18n.t('Address')}</label>
                            <input value={address} className="form-control" id="validationCustom01" type="text"
                              placeholder={I18n.t('Address')} required="" data-bs-original-title="" title="" onChange={e =>
                                setAddress(e.target.value)} />
                          </div>

                          <div className="col-md-4">
                            <label className="form-label" for="validationCustom04">{I18n.t('Select Country')} </label>
                            <select className="form-control" id="validationCustom04" required="" onChange={e => {
                              fetchStates(e.target.value)
                              setCountryId(e.target.value)
                            }}>
                              <option value={""} required>{I18n.t('Select Country')}</option>
                              {countries.map((country) =>
                                <option value={country.id} selected={(country.id === country_id) ? true : false}>{country.name}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Select State')}</label>
                            <select className="form-control" id="validationCustom04" required="" onChange={e => {
                              fetchCities(e.target.value)
                              setStateId(e.target.value)
                            }}>
                              <option value={""} required>{I18n.t('Select State')}</option>
                              {states.map((state) =>
                                <option value={state.id} selected={(state.id === state_id) ? true : false}>{state.name}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Select City')}</label>
                            <select className="form-control" id="validationCustom04" required="" onChange={e =>
                              setCityId(e.target.value)}>
                              <option value={""} required >{I18n.t('Select City')}</option>
                              {cities.map((city) =>
                                <option value={city.id} selected={(city.id === city_id) ? true : false}>{city.name}</option>
                              )}
                            </select>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Select TimeZone')}</label>
                            {/* <select className="form-control" id="validationCustom04" required=""
                            onChange={e => {
                              setTime(e.target.value)
                            }} value={time}>
                            <option value={""} required >{I18n.t('Select Timezone')}</option>
                            {timezone.map((zone) =>
                                <option value={zone.id} selected={(zone.id === timezone) ? true : false}>{zone.name}</option>
                              )}
                            </select> */}
                            <Select
                              name="timezone"
                              className=""
                              classNamePrefix="select"
                              options={timezone}
                              onChange={e => {
                                setTime(e.value)
                              }}
                              value={timezone.filter(({ value }) => value === time)}
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom01">CPF</label>
                            <input value={cpf} className="form-control" id="validationCustom01" type="text"
                              placeholder="CPF" required="" data-bs-original-title="" title="" onChange={e =>
                                setCPF(e.target.value)} />
                          </div>

                        </div>

                        <div className="row g-3">

                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Role')}  <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id="button-tooltip-2">Max 25 characters - This will appear on your public instructor profile.</Tooltip>}
                            >
                              <i class="fas fa-info-circle"></i>
                            </OverlayTrigger>
                            </label>
                            <input type="text" value={role_txt} className="form-control" placeholder={I18n.t('Role')} onChange={e =>
                              setRoleTxt(e.target.value)} />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('About You')} </label>
                            <textarea value={about_you} className="form-control" placeholder={I18n.t('About You')} onChange={e =>
                              setAboutYou(e.target.value)}></textarea>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Specialist In')} </label>
                            <textarea value={speciality_in} className="form-control" placeholder={I18n.t('Specialist in')} onChange={e =>
                              setSpecialityIn(e.target.value)}></textarea>
                          </div>
                        </div>
                        <hr></hr>
                        <div className="row g-3">
                          <div className="col-md-3 mb-3">
                            <label className="form-label" for="validationCustom04">Facebook </label>
                            <input value={facebook} className="form-control" id="validationCustom01" type="text"
                              placeholder="Facebook" required="" data-bs-original-title="" title="" onChange={e =>
                                setFacebook(e.target.value)} />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label" for="validationCustom04">Twitter</label>
                            <input value={twitter} className="form-control" id="validationCustom01" type="text"
                              placeholder="Twitter" required="" data-bs-original-title="" title="" onChange={e =>
                                setTwitter(e.target.value)} />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label" for="validationCustom04">LinkedIn</label>
                            <input value={linkedin} className="form-control" id="validationCustom01" type="text"
                              placeholder="LinkedIn" required="" data-bs-original-title="" title="" onChange={e =>
                                setLinkedin(e.target.value)} />
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="form-label" for="validationCustom04">Instagram</label>
                            <input value={instagram} className="form-control" id="validationCustom01" type="text" placeholder="instagram" onChange={e => setInstagram(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button className="btn btn-primary mr-1" type="submit" data-bs-original-title="" title="" onClick={e => updateUserProfile()}>
                          <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save')}
                        </button>

                        <Link to="/change-password">
                          <button className="btn btn-info ml-1" type="submit" data-bs-original-title="" title=""
                          >
                            <i className="fa fa-key" aria-hidden="true"></i> {I18n.t('Change Password')} </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);