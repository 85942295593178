import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleLoader } from '../../actions/loader';
import { dashboardCourses } from '../../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";

const Courses = (props) => {
  const [course, setCourse] = useState([]);

  useEffect(async () => {
    props.toggleLoader(true);
    var getCourses_var = await dashboardCourses();
    console.log(getCourses_var);
    setCourse(getCourses_var);
    props.toggleLoader(false);
  }, [])

  return (
    <>
      {course.length > 0 ?
        <div className="form-row mt-3 mb-2">
          {course.map((list) => (
            <div className="col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="courses-item">
                <div className="img-part">
                  <img src={list.media.url} alt="" className="img-fluid" />
                </div>
                <div className="content-part">
                  <span><a className="categories" href="#">{list.category.name}</a></span>
                  <h3 className="title"><a href="">{list.name}</a></h3>
                  <div className="bottom-part">
                    <div className="info-meta">
                      <ul>
                        <li className="ratings">
                          <i className="fa fa-star mr-1" />
                          ({list.avg_rating})
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        :
        <div className="my-3 mx-auto default_div">
          <img src="img/course-not-available.svg" className="img-fluid" />
          <p className="text-center">{I18n.t('Course not available')}</p>
        </div>}
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Courses);