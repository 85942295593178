import React from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
const AddTeamMember = () => {
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">
                <div className="page-title">
                  <div className="row">
                    <div className="col-6">
                      <h3>{I18n.t('Add Team Member')}</h3>
                    </div>
                    <div className="col-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </a>
                        </li>
                        <li className="breadcrumb-item"><Link to="/module">{I18n.t('Manage Team')}</Link> </li>
                        <li className="breadcrumb-item active">{I18n.t('Add Team Member')}</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form className="needs-validation" noValidate>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label className="form-label" for="validationCustom03">{I18n.t('New User')} </label>
                              <input className="form-control" id="validationCustom03" type="text"
                                placeholder={I18n.t('New User')} required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-4">
                              <label className="form-label" for="validationCustom03">{I18n.t('Email')}</label>
                              <input className="form-control" id="validationCustom03" type="text"
                                placeholder={I18n.t('Email')} required="" data-bs-original-title="" title="" />
                            </div>
                          </div>
                          <br />
                          <label className="form-label" for="validationCustom03">{I18n.t('Allow access to:')}</label>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox1" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox1">{I18n.t('InfoProduct')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox2" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox2">{I18n.t('Content')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox3" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox3">{I18n.t('Live')}</label>
                              </div>

                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox4" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox4">{I18n.t('Offers')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox5" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox5">{I18n.t('Sales Copy')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">

                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox6" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox6">{I18n.t('Checkout')}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox7" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox7">{I18n.t('Events')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox8" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox8">{I18n.t('Co-production')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox9" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox9">{I18n.t('Certificates')}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox10" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox10">{I18n.t('Revenue')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox11" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox11">{I18n.t('Sales')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox12" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox12">{I18n.t('Unrealized Sales')}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox13" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox13">{I18n.t('Invoice')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox14" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox14">{I18n.t('Status')}Voucher</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox15" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox15">{I18n.t('UpSell')}</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox16" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox16">{I18n.t('CressSell')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox17" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox17">{I18n.t('Afilliates')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox18" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox18">Interatividade</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-3">
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox19" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox19">{I18n.t('Manage Team')}</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-check checkbox mb-0">
                                <input className="form-check-input" id="checkbox20" type="checkbox" data-bs-original-title="" title="" />
                                <label className="form-check-label" for="checkbox20">{I18n.t('Webhooks')}</label>
                              </div>
                            </div>
                          </div>
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                            <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')} </button>
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" style={{ margin: "5px" }}>
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save')} </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
export default AddTeamMember;