import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, getTopicListing, topicActive, reOrderTopic } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import Alert from 'react-bootstrap/Alert'
import { Translate, I18n } from "react-redux-i18n";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const ViewTopic = (props) => {
  const [topic_list, setTopicList] = useState([]);
  const [id, setId] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  const [is_active, setIsActive] = useState('');

  useEffect(async () => {
    props.toggleLoader(true)
    var get_topic_listing = await getTopicListing(props.match.params.id);
    setTopicList(get_topic_listing);
    console.log(get_topic_listing);
    props.toggleLoader(false)
  }, [])

  const deleteModule = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + '/product/topic/delete/' + id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async function (response) {
        props.toggleLoader(false);
        displaySuccess('Topic Deleted Successfully');
        var get_topic_listing = await getTopicListing(props.match.params.id);
        setTopicList(get_topic_listing);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  const activeTopic = async (id, is_active) => {
    props.toggleLoader(true);
    if (is_active === 1) {
      var active_var = await topicActive(id, 0);
    }
    else {
      var active_var = await topicActive(id, 1);
    }
    console.log(active_var);
    setIsActive(active_var);
    var get_topic_listing = await getTopicListing(props.match.params.id);
    setTopicList(get_topic_listing);
    props.toggleLoader(false);
  }

  const orderUp = async (order, id) => {
    props.toggleLoader(true);
    var order_module = await reOrderTopic(order - 5, id);
    var get_topic_listing = await getTopicListing(props.match.params.id);
    setTopicList(get_topic_listing);
    props.toggleLoader(false);
  }
  const orderDown = async (order, id) => {
    props.toggleLoader(true);
    var order_module = await reOrderTopic(order + 5, id);
    var get_topic_listing = await getTopicListing(props.match.params.id);
    setTopicList(get_topic_listing);
    props.toggleLoader(false);
  }
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">

                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6 mb-2">
                      <h1 className="h3 mb-0 title-top">{I18n.t('View Topic')}</h1>
                    </div>
                    <div className="col-sm-6 text-sm-right">
                      <div className="form-row">
                        <div className="col">
                          <Link to={"/new-topic/" + props.match.params.id}>
                            <a className=" btn btn-light">
                              <i className="fas fa-plus fa-sm" /> {I18n.t('Add Topic')}</a>
                          </Link>
                          <Link to="/module">
                            <a className="btn-outline-dark btn ml-2">
                              <i class="fas fa-long-arrow-alt-left"></i>  {I18n.t('Back')} </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card m-overflow-h">
                      <div className="card-body  m-p-0">
                        <div className="table-responsive">
                          <Table className="table align-items-center table-striped table-hover mb-0">
                            <Thead>
                              <Tr>
                                <th>{I18n.t('S no.')}</th>
                                <th style={{ width: '20%' }}> {I18n.t('Module Name')}</th>
                                <th style={{ width: '40%' }}>{I18n.t('Topic Name')}</th>
                                <th>{I18n.t('Status')}</th>
                                <th>{I18n.t('Action')}</th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(topic_list.length === 0) ? <Tr>
                                <Td colspan="6" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No data added')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {topic_list.map((list, index) => (
                                <Tr>
                                  <Td>{++index}</Td>
                                  {list.product === null ?
                                    <Td> N/A</Td>
                                    :
                                    <Td>{list.module.title} </Td>
                                  }
                                  <Td>{list.title}</Td>
                                  {/* {list.video === null ?
                                    <Td> N/A</Td>
                                    :
                                    <Td>{list.video.url} </Td>
                                  } */}
                                  <Td> {(list.is_active === 1) ? <span className="text-success"> {I18n.t('Active')} </span> :
                                    <span className="text-danger"> {I18n.t('Inactive')} </span>}
                                  </Td>
                                  <Td>
                                    <div className>
                                      <Link to={'/edit-topic/' + list.id} title={I18n.t("Edit Topic")}>
                                        <a className="flex items-center m-1 btn-outline-dark btn btn-sm" href>
                                          <i className="far fa-edit" /></a>
                                      </Link>
                                      <button className="flex items-center btn btn-sm btn-outline-danger"
                                        onClick={e => {
                                          setId(list.id)
                                          setIsDelete(true)
                                        }} title={I18n.t("Delete Topic")}
                                      >
                                        <i className="far fa-trash-alt" />
                                      </button>
                                      <a className="flex btn btn-sm btn-outline-primary m-1" href
                                        onClick=
                                        {e => { activeTopic(list.id, list.is_active) }}
                                      >
                                        {(list.is_active === 0) ? <i className="fas fa-play" title={I18n.t("inactive Topic")} /> : <i className="fa fa-pause"  title={I18n.t("active Topic")}/>}
                                      </a>
                                      <>
                                        {(index > 1) ? <a
                                          className="btn btn-outline-primary btn-sm m-1"
                                          href="#"
                                          onClick={e => {
                                            var temp_index = index - 2;
                                            orderUp(topic_list[temp_index].order, list.id);
                                          }}
                                        >
                                          <i class="fas fa-sort-up"></i>
                                        </a> : ''}
                                        {(index < topic_list.length) ?
                                          <a
                                            className="btn btn-outline-primary btn-sm m-1"
                                            href="#"
                                            onClick={e => {
                                              orderDown(topic_list[index].order, list.id);
                                            }}
                                          >
                                            <i class="fas fa-sort-down" title={I18n.t("Sort Topic")} ></i>
                                          </a> : ''}
                                      </>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {is_delete === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title="Are you sure?"
            onConfirm={(e) => {
              deleteModule()
              setIsDelete(false)
            }}
            onCancel={(e) => {
              setIsDelete(false)
              setId('')
            }}
            focusCancelBtn
          >
            Do you want to delete this topic?
          </SweetAlert>
        ) : ([]
        )}
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewTopic);