import React, { useState, useEffect } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import TopNav from '../components/TopNav';
import config from "../config/config";
import axios from "axios";
import Select from 'react-select';
import moment from 'moment'

import {
  displayError, displaySuccess, getPaymentList, getInstallmentList, getInterestList, getCategoryListing, getLevelListing,
  getProductWithModules, instructorProfile, getInstructorList
} from '../Utils/utils';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
const AddOffers = (props) => {
  // const [start_date, setStartDate] = useState(setHours(setMinutes(new Date(), 30), 16));
  const [start_date, setStartDate] = useState(new Date());
  const [start_date1, setStartDate1] = useState(new Date().setDate(new Date().getDate() + 30));
  // start_date1.setMonth(2);
  const [start_date2, setStartDate2] = useState(new Date());
  const [benefitsFieldAdd, setBenefitFieldAdd] = useState([""]);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('0');
  const [free_content, setFreeContent] = useState(false);
  const [payment_method, setPaymentMethod] = useState('');
  const [installment_quantity, setInstallmentQuantity] = useState('');
  const [interest_mode, setInterestMode] = useState('');
  const [renew, setRenew] = useState(0);
  const [receivable_advance, setReceivableAdvance] = useState(0);
  const [Immediately, setImmediately] = useState('');
  const [publish_date, setPublishDate] = useState('');
  const [valid_until_days, setValidUntilDays] = useState('0');
  const [validImmediately, setValidImmediately] = useState('');
  const [validDate, setValidDate] = useState('');
  const [limitedCustomer, setLimitedCustomer] = useState('');
  const [unlimitedCustomer, setUnlimitedCustomer] = useState('');
  const [quantity_of_customer, setQuantityOfCustomer] = useState("5");
  const [description, setDescription] = useState('');
  const [publicCourse, setPublicCourse] = useState(1);

  const [payment_list, setPaymentList] = useState([]);
  const [installment_list, setInstallmentList] = useState([]);
  const [interest_list, setInterestList] = useState([]);
  const [is_selected, setIsSelected] = useState(false);
  const [category_list, setCategoryList] = useState([]);
  const [level_list, setLevelList] = useState([]);
  const [category_id, setCategoryId] = useState('');
  const [level_id, setLevelId] = useState('');
  const [date_selected, setDateSelected] = useState(false);
  const [days_selected, setDaysSelected] = useState(false);
  const [valid_date_selected, setValidDateSelected] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [module_list, setModuleList] = useState([]);
  const [module_ids, setModuleIds] = useState([]);
  const [media_id, setMediaId] = useState('');
  const [benefits, setBenefits] = useState([]);
  const [hotmart_id, setHotmartId] = useState('');
  const [hotmart_link, setHotmartLink] = useState('');
  const [time, setTime] = useState('');
  const [instructor_list, setInstructorList] = useState([]);
  const [instList, setInstList] = useState([]);
  const [value, setValue] = useState('');
  const [temp_list, setTempList] = useState([]);
  const [pubImmediate, setPubImmediate] = useState('');
  const [endImmediate, setEndImmediate] = useState('');
  const [live_quantity, setLiveQuantity] = useState(0);
  const [is_free, setIsFree] = useState("0");
  const [desc_mob, setDescMob] = useState('');
  const [release_percent, setReleasePercent] = useState('20');

  const addOffer = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/offer/create', {
      name: name,
      // price: price.inputtxt,
      price: free_content === true ? 0 : price.inputtxt,
      free_content: is_free,
      payment_method: payment_method,
      installment_quantity: installment_quantity,
      interest_mode: interest_mode,
      // renew: renew,
      // receivable_advance: receivable_advance,
      publish_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: start_date1 !== null ? moment(start_date1).format("YYYY-MM-DD HH:mm:ss") : start_date1,
      quantity_of_customer: quantity_of_customer,
      media_id: media_id,
      category_id: category_id,
      description: description,
      level_id: level_id,
      billing_value: 75,
      customer_value: 75,
      module_ids: module_ids,
      offer_benefit: benefitsFieldAdd,
      hotmart_id: hotmart_id,
      hotmart_link: hotmart_link,
      other_instructor: instructor_list,
      is_public: publicCourse,
      live_quantity: live_quantity,
      offer_description_mobile: desc_mob,
      release_percentage: release_percent
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Offer Added Successfully');
        setTimeout(() => window.location.href = "/offers", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  useEffect(async () => {
    props.toggleLoader(true)
    var get_payment_list = await getPaymentList();
    setPaymentList(get_payment_list);
    console.log(get_payment_list[0].id)
    var get_install_list = await getInstallmentList();
    setInstallmentList(get_install_list);
    var get_interest_list = await getInterestList();
    setInterestList(get_interest_list);
    var get_category_list = await getCategoryListing();
    setCategoryList(get_category_list);
    var get_level_list = await getLevelListing();
    setLevelList(get_level_list);
    var product_with_modules = await getProductWithModules();
    setModuleList(product_with_modules);
    var get_instructor_list = await getInstructorList();
    setInstList(get_instructor_list);
    // setValue(get_instructor_list);
    var user_login = await instructorProfile();
    if (user_login !== null) {
      setTime(user_login.timezone)
    }
    props.toggleLoader(false)
  }, [])
  const instructorListing = (e) => {
    var temp_array = [];
    e.map(inst => {
      temp_array.push(inst.value);
    })
    setInstructorList(temp_array);
  }
  const selectModules = (e, module_id) => {
    var temp_array = module_ids;
    if (e.target.checked === true) {
      temp_array.push(module_id);
    } else {
      var index = temp_array.indexOf(module_id);
      if (index > -1) {
        temp_array.splice(index, 1);
      }
    }
    setModuleIds(temp_array);
  }
  const selected = () => {
    setIsSelected(true);
    setPublishDate(new Date());
    setStartDate(new Date());
    setImmediately(1);
    setPubImmediate(1);

  }
  const selected2 = () => {
    setIsSelected(false);
    setPublishDate("0");
    setImmediately(0);
    setPubImmediate(0);

  }
  const definedDate = () => {
    setDateSelected(true);
    setStartDate1(null);
    setEndImmediate(1);
    console.log(start_date1);
  }

  const definedDate2 = () => {
    setDateSelected(false);
    setStartDate1(new Date());
    // start_date1.setMonth(2);
    setEndImmediate(0);
    console.log(start_date1)
  }
  const validDays = () => {
    setDaysSelected(true);
    setValidImmediately(1);
    setValidUntilDays("0");
  }
  const validDays2 = () => {
    setDaysSelected(false);
    setValidImmediately(0);
    setValidUntilDays("0");
  }
  const validDate1 = () => {
    setValidDateSelected(true);
    setStartDate2(null);
  }
  const validDate2 = () => {
    setValidDateSelected(false);
    setStartDate2(new Date());
  }
  const quantityCust = () => {
    setCustomer(true);
    setUnlimitedCustomer("1");
    setLimitedCustomer("0");
    setQuantityOfCustomer("unlimited");

  }
  const quantityCust2 = () => {
    setCustomer(false);
    setUnlimitedCustomer(0);
    setLimitedCustomer("0");
  }
  // const is_free_content = () => {
  //   setFreeContent(true);
  //   setPrice(0);
  //   setPaymentMethod('single payment')
  // }
  // const is_not_free_content = () => {
  //   setPrice('');
  //   setFreeContent(false);
  //   setPaymentMethod('')
  // }
  // const is_renew = () => {
  //   setRenew(1);
  // }
  // const is_advance = () => {
  //   setReceivableAdvance(1);
  // }

  const isPublic = () => {
    setPublicCourse(0);
    console.log(publicCourse);
  }
  const uploadImage = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/image_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }
  // handle click event of the Remove button
  const removeBenefitsField = index => {
    const list = [...benefitsFieldAdd];
    list.splice(index, 1);
    setBenefitFieldAdd(list);
  };

  // handle click event of the Add button
  const addBenefitsField = (i) => {
    if (benefitsFieldAdd[i] === "") {
      alert('Please first add value in text box, then add more.');
    } else {
      setBenefitFieldAdd([...benefitsFieldAdd, ""]);
    }
  };

  const addBenefits = (value, index) => {

    // var temp_benefits = benefits;
    var temp_benefits = [...benefitsFieldAdd];
    temp_benefits[index] = value;
    //setBenefits(temp_benefits);
    setBenefitFieldAdd(temp_benefits)
  }

  const acceptNumber = (e) => {
    if (free_content === true) {
      setPrice(0);
    } else {
      setPrice({ inputtxt: e.target.value.replace(/[^0-9,.]/ig, '') });

    }
  }
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]
  const is_free_Content = (val) => {
    setIsFree(val);
    if (val == 1) {
      setFreeContent(true);
      setPrice(0);
      setPaymentMethod('single payment')
    } else if (val == 2) {
      setFreeContent(true);
      setPrice(0);
      setPaymentMethod('single payment')
    }
    else {
      setPrice('');
      setFreeContent(false);
      setPaymentMethod('')
    }
  }
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>{I18n.t('Offers')}</h3>
                    </div>
                    <div className="col-sm-6 text-md-right">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/offers">{I18n.t('Offers')}</Link></li>
                        <li className="breadcrumb-item active">{I18n.t('Add Offers')}</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Offer Name')}</label>
                              <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title="" title=""
                                placeholder={I18n.t('Offer Name')} onChange={e => setName(e.target.value)} />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Instructor')}</label>
                              <Select
                                isMulti
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={instList}
                                onChange={e => instructorListing(e)}
                              />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Offer Price')}</label>
                              <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title="" title=""
                                onChange={e => acceptNumber(e)}
                                // value={price.inputtxt}
                                value={free_content === true ? 0 : price.inputtxt}
                                placeholder={I18n.t('Offer Price')}
                                readOnly={free_content === true ? true : false} />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Free Content')}</label>
                              <select className="form-select" id="validationCustom04" required="" onChange={e => is_free_Content(e.target.value)}>
                                <option value='0'>{I18n.t('no')}</option>
                                <option value='1'>{I18n.t('yes')}</option>
                                <option value='2'>{I18n.t('yes with passcode')}</option>
                              </select>
                              <div className="invalid-feedback">{I18n.t('Please select a valid state.')}</div>
                            </div>
                          </div>
                          {free_content === true ? "" :
                            <div className="row g-3 mt-4">
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Payment method')}</label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setPaymentMethod(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select payment method')}</option>
                                  {payment_list.map((pay) => (

                                    <option value={pay.id}>{I18n.t(pay.text)}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Installment Quantity')}  </label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setInstallmentQuantity(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select Installment Quantity')} </option>
                                  {installment_list.map((list) => (
                                    <option value={list.id}>{list.text}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Interest MOde')}</label>
                                <select className="form-select" id="validationCustom04" required=""
                                  onChange={e => setInterestMode(e.target.value)}>
                                  <option value={""} required>{I18n.t('Select Interest MOde')}</option>
                                  {interest_list.map((mode) => (
                                    <option value={mode.id}>{I18n.t(mode.text)}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Live Quantity')}</label>
                                <input className="form-control" id="validationCustom01" type="number" required="" data-bs-original-title="" title=""
                                  placeholder={I18n.t('Live Quantity')} onChange={e => setLiveQuantity(e.target.value)} />
                              </div>
                            </div>
                          }
                          <div className="row g-3 mt-4">

                            {/* <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Advance')}</label>
                              <select className="form-select" id="validationCustom04" required="">
                                <option>{I18n.t('no')}</option>
                                <option onClick={is_advance}>{I18n.t('yes')}</option>
                              </select>
                            </div> */}


                            <div className={free_content === true ? "col-md-3" : "col-md-4"}>
                              <label className="form-label" for="validationCustom04">{I18n.t('Categories')}</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setCategoryId(e.target.value)}>
                                <option value={""} required>{I18n.t('Select Category')}</option>
                                {category_list.map((list) => (
                                  <option value={list.id}>{I18n.t(list.name)} </option>
                                ))}
                              </select>
                            </div>

                            <div className={free_content === true ? "col-md-3" : "col-md-4"}>
                              <label className="form-label" for="validationCustom04">{I18n.t('Level')}</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setLevelId(e.target.value)}>
                                <option value={""} required>{I18n.t('Select Level')}</option>
                                {level_list.map((list) => (
                                  <option value={list.id}>{I18n.t(list.name)} </option>
                                ))}
                              </select>
                            </div>
                            {free_content === true ?
                              <div className="col-md-3">
                                <label className="form-label" for="validationCustom04">{I18n.t('Live Quantity')}</label>
                                <input className="form-control" id="validationCustom01" type="number" required="" data-bs-original-title="" title=""
                                  placeholder={I18n.t('Live Quantity')} onChange={e => setLiveQuantity(e.target.value)} />
                              </div>
                              : ""}
                            <div className={free_content === true ? "col-md-3" : "col-md-4"}>
                              <label className="form-label" for="validationCustom01">{I18n.t('Image')}</label>
                              <div className="user__profile">
                                <input
                                  accept=".jpg,.jpeg,.png"
                                  id="validationCustom01"
                                  type="file"
                                  required=""
                                  data-bs-original-title=""
                                  title=""
                                  className="form-control"
                                  onChange={e => uploadImage(e)}
                                />
                              </div>
                            </div>

                          </div>
                          <div className="row g-3 mt-4">

                            <div className="col-md-8 ul-list">
                              <label className="form-label" for="validationCustom04">{I18n.t('Description')} </label>

                              <CKEditor
                                editor={ClassicEditor}
                                config={{
                                  toolbar: ['heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    '|',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'insertTable',
                                    '|',
                                    'undo',
                                    'redo', '|', 'link']
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setDescription(data)
                                }}
                              />

                            </div>


                            <div className="col-md-4">
                              <label className="form-label" for="">{I18n.t('Is Public')} <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">{I18n.t('if you want to make offer public set 1 otherwise 0')} </Tooltip>}
                              >
                                <i class="fas fa-info-circle"></i>
                              </OverlayTrigger></label>
                              <select className="form-select" id="validationCustom04" required="" >
                                <option>{I18n.t('1')}</option>
                                <option onClick={isPublic}>{I18n.t('0')}</option>
                              </select>
                            </div>

                          </div>
                          <div className="row g-3 mt-4">
                            <div className="col-md-8 ul-list">
                              <label className="form-label" for="validationCustom04">{I18n.t('Offer Description Mobile')} </label>
                              <textarea className="form-control" placeholder='Offer Description Mobile'
                                value={desc_mob} onChange={e => setDescMob(e.target.value)}></textarea>
                            </div>
                            <div className="col-md-4">
                              <label className="form-label" for="">{I18n.t('% Release Before 7 Days')} </label>
                              <input className="form-control" id="validationCustom01" type="number"
                                placeholder={I18n.t('% Release Before 7 Days')} onChange={e => setReleasePercent(e.target.value)} value={release_percent}
                                min={10} max={100}
                                onKeyUp={(event) => {
                                  if (!/^([1-9][0-9]?|100)$/gm.test(event.key)) {
                                    event.preventDefault();
                                  }

                                }}
                              />
                            </div>
                          </div>
                          <div className="row g-3 mt-4">
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Publish Date / Time')}  {'(' + (time) + ')'}</label>
                              <div className="">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1" data-bs-original-title="" title=""
                                      onClick={selected}
                                      checked={pubImmediate}
                                    />
                                    <label className="form-check-label" for="radio1">{I18n.t('Immediately')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1" data-bs-original-title="" title=""
                                      onClick={selected2}
                                      defaultChecked={true}
                                      checked={!pubImmediate}
                                    />
                                    <label className="form-check-label" for="radio2">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              <DatePicker
                                disabled={(is_selected) ? true : false}
                                selected={start_date}
                                onChange={(date) => setStartDate(date)}
                                minDate={new Date()}
                                timeInputLabel="Time:"
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                showTimeInput
                                customInput={
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=" Date"
                                  />
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('End Date / Time')} {'(' + (time) + ')'}</label>
                              <div className="">
                                <div className="mb-3 m-t-15">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio3" type="radio" name="radio2" value="option1" data-bs-original-title="" title=""
                                      onClick={definedDate}
                                      checked={endImmediate}
                                    />
                                    <label className="form-check-label" for="radio3">{I18n.t('undefined')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio4" type="radio" name="radio2" value="option1" data-bs-original-title="" title=""
                                      onClick={definedDate2} defaultChecked={true}
                                      checked={!endImmediate}
                                    />
                                    <label className="form-check-label" for="radio4">{I18n.t('Date / Time')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {date_selected === true ?
                                <DatePicker
                                  disabled
                                  onChange={(date) => setStartDate1(date)}
                                  timeInputLabel="Time:"
                                  dateFormat="yyyy-MM-dd HH:mm:ss"
                                  showTimeInput
                                  minDate={new Date()}
                                  customInput={
                                    <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                    />
                                  }
                                /> :
                                <DatePicker
                                  minDate={new Date()}
                                  selected={start_date1}
                                  onChange={(date) => setStartDate1(date)}
                                  timeInputLabel="Time:"
                                  dateFormat="yyyy-MM-dd HH:mm:ss"
                                  showTimeInput
                                  customInput={
                                    <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                      value={new Date().setDate(
                                        new Date().getDate() + 30
                                      )} />
                                  }
                                />}
                            </div>
                            <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Quantity of customer')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio9" type="radio" name="radio5" data-bs-original-title="" title=""
                                      onClick={quantityCust}
                                      value={unlimitedCustomer} />
                                    <label className="form-check-label" for="radio9">{I18n.t('unlimited')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio10" type="radio" name="radio5" data-bs-original-title="" title=""
                                      onClick={quantityCust2} value={limitedCustomer}
                                      defaultChecked={true} />
                                    <label className="form-check-label" for="radio10">{I18n.t('limited')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {customer === true ?
                                <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                  title="" disabled
                                  value={quantity_of_customer}
                                  onChange={e => setQuantityOfCustomer(e.target.value)}
                                /> :
                                <input className="form-control" id="validationCustom01" type="text"
                                  value={quantity_of_customer} required="" data-bs-original-title=""
                                  title="" onChange={e => setQuantityOfCustomer(e.target.value)}
                                />}
                            </div>
                          </div>

                          <div className="row g-3 mt-4">
                            <div className="col-md-6">
                              {module_list.map((list, index) => (
                                <Accordion style={{ marginBottom: '20px' }} className="accordion-top-set">
                                  <Card >
                                    <Card.Header style={{ height: '50px' }}>
                                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <div className="row g-3">
                                          <i className="fas fa-plus-circle" style={{ marginRight: '10px' }} />
                                          {/* <div className="form-check checkbox mb-0">
                                            <input
                                              className="form-check-input"
                                              id={"header" + index}
                                              type="checkbox"
                                              data-bs-original-title=""
                                              title="" />
                                           
                                          </div> */}
                                          <label className="form-check-label" for={"header" + index}>
                                            {list.title}
                                          </label>
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                      <Card.Body>
                                        <div className="mb-3 m-t-15">
                                          {list.module.map((listing, index2) => (
                                            <div className="form-check checkbox mb-0">
                                              <input className="form-check-input" id={"module-" + index + '-' + index2}
                                                type="checkbox" data-bs-original-title="" title=""
                                                onClick={e => { selectModules(e, listing.id) }}
                                              />
                                              <label className="form-check-label" for={"module-" + index + '-' + index2}>{listing.title}</label>
                                            </div>
                                          ))}
                                        </div>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                </Accordion>
                              ))}
                            </div>

                            <div className="col-md-6">
                              {benefitsFieldAdd.map((x, i) => {
                                return (
                                  <div className="row g-3" key={"benefits_" + i}>
                                    <div className="col-10">
                                      {(i === 0) ? <label className="form-label">{I18n.t('Explain Course Benefits')}</label> : ""}
                                      <input
                                        key={"benefits_textbox_" + i}
                                        className={(i === 0) ? "form-control" : "form-control mt-4"}
                                        type="text"
                                        placeholder="Benefits"
                                        onChange={e => addBenefits(e.target.value, i)}
                                        value={benefitsFieldAdd[i]}
                                      />
                                    </div >

                                    <div className="col-1">
                                      {benefitsFieldAdd.length !== 1 &&
                                        <i className="fas fa-minus-circle mt-5"
                                          onClick={() => removeBenefitsField(i)}></i>}
                                      {benefitsFieldAdd.length - 1 === i && <i className="fas fa-plus-circle mt-5" onClick={e => addBenefitsField(i)}></i>}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                          </div>

                          <div className="row g-3 mt-4" disabled>

                            {/* <div className="col-md-2">
                              <label className="form-label" for="validationCustom03">{I18n.t('Quantity of customer')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio9" type="radio" name="radio5" value="option1" data-bs-original-title="" title=""
                                      onClick={quantityCust} defaultChecked={true} />
                                    <label className="form-check-label" for="radio9">{I18n.t('unlimited')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio10" type="radio" name="radio5" value="option1" data-bs-original-title="" title=""
                                      onClick={quantityCust2} />
                                    <label className="form-check-label" for="radio10">{I18n.t('limited')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 align-self-center">
                              {customer === true ?
                                <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                  title="" disabled
                                /> :
                                <input className="form-control" id="validationCustom01" type="text" value={quantity_of_customer} required="" data-bs-original-title=""
                                  title="" onChange={e => setQuantityOfCustomer(e.target.value)}
                                />}
                            </div> */}
                          </div>

                        </div>
                      </div>
                      <div className="card-footer">
                        <button className="btn btn-primary" type="submit" data-bs-original-title="" title=""
                          onClick={e => addOffer()}>
                          <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')} </button>
                        &nbsp;
                        <Link to="/offers">
                          <button className="btn btn-dark" type="submit" data-bs-original-title="" title="">
                            <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddOffers);