import React, { useState } from 'react';
import './loginPage.css';
import { Link } from "react-router-dom";
import apiUrl from "../config/apiPath";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess } from '../Utils/utils'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher'

const LoginScreen = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const LoginUser = (e) => {
    e.preventDefault();
    props.toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/auth/login/';
    axios.post(path, {
      email: email,
      password: password
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Login Successfull');
        localStorage.setItem('token', response.data.data.access_token);
        setTimeout(() => window.location.href = "/dashboard");
      })
      .catch(function (error) {
        props.toggleLoader(false);
        displayError(error);
      })
  }
  return (
    <>
      <body className="bg-gradient-primary">
        <div id="wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <div className="container-fluid">
                <div className="row justify-content-center">


                  <div className="col-md-6 col-12 login-bg-color">
                    <div className="login-content">
                      <div className="login-header">
                        <a href="#" className="login-logo">
                          <img src="img/logo.png" alt="Logo" />
                        </a>
                      </div>
                      <div className="login-form">

                        <h2>{I18n.t('Log In')}</h2>
                        <p>{I18n.t('Log in to continue in our website')}</p>
                        <form className="user">
                          <div className="form-group">
                            <input type="email" className="form-control form-control-user" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder={I18n.t('Enter Email Address')} onChange={e => setEmail(e.target.value)} />
                          </div>
                          <div className="form-group mt-4">
                            <input type="password" className="form-control form-control-user" id="exampleInputPassword"
                              placeholder={I18n.t('Password')} onChange={e => setPassword(e.target.value)} />
                          </div>
                          <div className="form-group mt-4">
                            <div className='row'>
                              <div className='col-sm-7'>
                                <button className="btn btn-dark btn-user btn-block" onClick={LoginUser}>
                                  {I18n.t('Login')}
                                </button>
                              </div>
                              <div className='col-sm-5 align-self-center justify-content-between mt-4 mt-sm-0'>
                                <div className="text-sm-right">
                                  <Link className="small font-weight-bold" to="/forgot-password">{I18n.t('Forgot Password?')}</Link>

                                  <div className="login-page-switcher d-inline d-sm-none float-right" >
                                    {/* <Link to="/register" className="switcher-text1">{I18n.t('Register')}</Link>&nbsp; */}
                                    <LanguageSwitcher />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </form>
                      </div>


                    </div>
                  </div>
                  <div className="col-md-6 col-12 d-none d-sm-flex login-bg-img" style={{ backgroundImage: 'url("img/bg15-l.jpg")' }}>
                    <div className="login-intro align-self-center">
                      <h1>{I18n.t('Welcome To On Dutra')}</h1>
                      <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada.</p>
                      <div className="login-page-switcher">
                        <Link to="/" className="switcher-text1 active">{I18n.t('Log In')}</Link>
                        {/* <Link to="/register" className="switcher-text1 ">{I18n.t('Register')}</Link> */}
                        <LanguageSwitcher />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);