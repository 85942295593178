import React from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import "react-datepicker/dist/react-datepicker.css";
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
const PreviewCertificate = () => {


  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-6">
                      <h3>{I18n.t('Certificate')}</h3>
                    </div>
                    <div className="col-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/certificates">{I18n.t('Certificate')}</Link></li>
                        <li className="breadcrumb-item active"><Link to="/certificates-new"> {I18n.t('New Certificate ')}</Link></li>
                        <li className="breadcrumb-item active">{I18n.t('Preview')}</li>

                      </ol>
                    </div>
                  </div>
                </div>
                {/* content start */}
                <div className="row mb-4">
                  <div className="col-md-9">
                    <div className="card mb-4">
                      <div className="card-header">
                        {/* <h5>Custom styles</h5> */}
                        <div className="col-auto ml-auto d-none d-sm-inline-block">
                          <div className="form-row">
                            <div className="col mb-12">
                              <Link to="/offers-add" className=" btn btn-primary" style={{ float: 'right' }}>
                                <i className="fas fa-plus fa-sm"></i>
                                {I18n.t('New Certificate')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-auto ml-auto d-none d-sm-inline-block">
                                                <div className="form-row">
                                                    <div className="col">
                                                        <Link to="/add-offers" className=" btn btn-primary" >
                                                            <i className="fas fa-plus fa-sm"></i>
                                                            New offers

                                                        </Link>
                                                    </div>

                                                </div>
                                            </div> */}

                      <div className="card-body">
                        <form className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('InfoProduct')}</label>
                              <select className="form-select" id="validationCustom04" required="">
                                <option selected="" disabled="" value="">{I18n.t('Freedom')}digital Marketing</option>
                                <option>{I18n.t('Freedom')}Freedom</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Offer')} </label>
                              <select className="form-select" id="validationCustom04" required="">
                                <option selected="" disabled="" value="">digital Marketing</option>
                                <option>Freedom</option>
                              </select>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('% Certificate Goal')} </label>
                              <input className="form-control" id="validationCustom01" type="text" value="%" required="" data-bs-original-title="" title="" />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('Quantity Lives for Certificate')}</label>
                              <input className="form-control" id="validationCustom01" type="text" value="0" required="" data-bs-original-title="" title="" />

                            </div>
                          </div>


                          <br />
                          <div className="row g-3">
                            <div className="col-md-3">
                              <img src="img/certificate.jpeg" width="2500px" height="2500px" />                                                        </div>
                          </div>



                        </form>
                      </div>
                    </div>



                  </div>
                  <div className="col-md-3">
                    <div className="card-body">
                      <div className="col-md-12">
                        <div>
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="">
                            <i className="fa fa-check" aria-hidden="true"></i>{I18n.t('Save Changes')}
                          </button>
                        </div>
                        <br />
                        <div>
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title="">
                            <i className="fa fa-check" aria-hidden="true"></i>{I18n.t('Settings')}
                          </button>
                        </div>
                        <br />
                        <div>
                          <Link to="/certificates-preview">
                            <button className="btn btn-primary" type="submit" data-bs-original-title="" title="">
                              <i className="fa fa-info-circle" aria-hidden="true"></i>{I18n.t('Preview')}
                            </button></Link>
                        </div>
                        <br />
                        <div>
                          <Link to="/certificates">
                            <button className="btn btn-primary" type="submit" data-bs-original-title="" title="">
                              <i className="fa fa-back" aria-hidden="true"></i>{I18n.t('Return')}
                            </button></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
export default PreviewCertificate;