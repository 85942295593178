import React, { useEffect, useCallback, useState, useRef } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { getNotification, notificationRead } from '../Utils/utils'
import moment from 'moment';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import 'react-image-crop/dist/ReactCrop.css';
import TopNav from '../components/TopNav';
import ReactPaginate from 'react-paginate';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'

const Notification = (props) => {
  const [notification_list, setNotificationList] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [notification_read, setNotificationRead] = useState('');

  useEffect(async () => {
    props.toggleLoader(true);
    var get_noti_var = await getNotification(current_page);
    if (get_noti_var !== null) {
      setNotificationList(get_noti_var.data);
      setPageCount(get_noti_var.last_page);
      console.log(get_noti_var.data)
    }
    props.toggleLoader(false);
  }, [])
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var get_noti_var = await getNotification(selectedPage);
    setNotificationList(get_noti_var.data);
    setPageCount(get_noti_var.last_page);
    props.toggleLoader(false);
  };
  useEffect(async () => {
    props.toggleLoader(true);
    var get_noti_var = await notificationRead();
    setNotificationRead(get_noti_var)
    props.toggleLoader(false);
  }, [])
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossorigin="anonymous" />

                      <div class="container">
                        <div class="row">

                          <div class="col-lg-12">
                            {/* <div class="box shadow-sm rounded bg-white mb-3"> */}
                            {/* <div class="box-title border-bottom p-3">
                                <h6 class="m-0">Recent</h6>
                              </div> */}
                            {(notification_list.length === 0) ? <Tr>
                              <Td colspan="12" style={{ textAlign: 'center' }}>
                                <Alert key={'alert'} variant={'info'}>
                                  {I18n.t('No Notification ')}
                                </Alert>
                              </Td>
                            </Tr> : ''}
                            {notification_list.map((list) => (


                              <div class="box-body p-0">
                                <div class="p-3 d-flex align-items-center bg-light border-bottom osahan-post-header">
                                  <div class="dropdown-list-image mr-3 d-flex align-items-center bg-info justify-content-center rounded-circle text-white" style={{ width: '50px' , height:'50px' }} >

                                    {list.sender !== null ?
                                      <>
                                        {list.sender.media !== null ?
                                          <img class="rounded-circle" src={list.sender.media.url} />
                                          :
                                          "D"}
                                      </>
                                      : "D"}
                                  </div>
                                  <div class="font-weight-bold mr-3">
                                    {list.notification_group !== null ?
                                      <>
                                        <div className="text-truncate">{I18n.t(list.notification_group.title)}</div>
                                        <div className="small">{I18n.t(list.notification_group.description)}</div>
                                        {list.notification_group_id === 8 ?
                                          <>
                                            <div className="small" >{I18n.t('Question')} : {(list.question_text)}</div>
                                          </> : ""}
                                      </>
                                      :
                                      <>
                                        <div className="text-truncate">{"NA"}</div>
                                        <div className="small">{list.receiver.full_name}</div>
                                      </>}
                                  </div>
                                  <span class="ml-auto mb-auto">
                                    {/* <div class="btn-group">
                                      <button type="button" class="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="mdi mdi-dots-vertical"></i>
                                      </button>
                                      <div class="dropdown-menu dropdown-menu-right">
                                        <button class="dropdown-item" type="button"><i class="mdi mdi-delete"></i> Delete</button>
                                        <button class="dropdown-item" type="button"><i class="mdi mdi-close"></i> Turn Off</button>
                                      </div>
                                    </div> */}
                                    <br />
                                    <div class="text-right text-muted pt-1">
                                      {moment(list.created_at).format('DD MMM ')}
                                    </div>
                                  </span>
                                </div>

                              </div>
                            ))}

                            {/* </div> */}

                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
              {(notification_list.length > 0) ?
                <div style={{ marginLeft: '500px' }}>
                  <ReactPaginate

                    previousLabel={I18n.t('prev')}
                    nextLabel={I18n.t('next')}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    style={{ marginLeft: '500px' }}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div>
                : ''}
            </div>
          </div>
        </div>
        {/* </div> */}
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);