import React, { useState, useEffect } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import { displayError, displaySuccess, getActiveInfoProduct, getCertificateEdit, getOffersListing } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import Alert from 'react-bootstrap/Alert'
import config from "../config/config";
import axios from "axios";

const EditCertificate = (props) => {
  const [product_id, setProductId] = useState('');
  const [offer_id, setOfferId] = useState('');
  const [goal, setGoal] = useState("");
  const [quantity, setQuantity] = useState('');
  const [text, setText] = useState('');
  const [media_id, setMediaId] = useState('');
  const [product_list, setProductList] = useState([]);
  const [offer_list, setOfferList] = useState([]);
  const [imageUrl, setImageUrl] = useState('');

  const updateCertificate = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/certificate/update/' + props.match.params.id, {
      product_id: product_id,
      offer_id: offer_id,
      goal: goal,
      quantity: quantity,
      text: text,
      media_id: media_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Certificate Updated Successfully');
        setTimeout(() => window.location.href = "/certificates", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  // useEffect(async () => {
  //   props.toggleLoader(true)
  //   var get_product_listing = await getActiveInfoProduct();
  //   setProductList(get_product_listing);
  //   console.log(get_product_listing);
  //   props.toggleLoader(false)
  // }, [])

  useEffect(async () => {
    props.toggleLoader(true)
    var get_offer_listing = await getOffersListing();
    setOfferList(get_offer_listing);
    console.log(get_offer_listing);
    props.toggleLoader(false)
  }, [])

  const uploadImage = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/image_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }

  useEffect(async () => {
    props.toggleLoader(true)
    var get_certificate_edit = await getCertificateEdit(props.match.params.id);
    setOfferId(get_certificate_edit.offer_id)
    setGoal(String(get_certificate_edit.goal))
    setQuantity(get_certificate_edit.quantity)
    setText(get_certificate_edit.text)
    if (get_certificate_edit.media !== null) {
      setMediaId(get_certificate_edit.media_id);
      setImageUrl(get_certificate_edit.media.url);
    }
    props.toggleLoader(false)
  }, [])

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-6">
                      <h3>{I18n.t('Certificate')}</h3>
                    </div>
                    <div className="col-6">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/certificates">{I18n.t('Certificate')}</Link></li>
                        <li className="breadcrumb-item active">{I18n.t('New Certificate')}</li>
                      </ol>
                    </div>
                  </div>
                </div>
                {/* content start */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="needs-validation" novalidate="">
                          <div className="row">
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-4 mb-3">
                                  <label className="form-label" for="validationCustom01">{I18n.t('Offer')} </label>
                                  <select className="form-select" id="validationCustom04" required="" onChange={e => setOfferId(e.target.value)}>
                                    <option selected="" disabled="" value="">{I18n.t('select offer')}</option>
                                    {offer_list.map((offer) => (
                                      <option value={offer.id} selected={(offer.id === offer_id) ? true : false}>{offer.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label className="form-label" for="validationCustom04">% {I18n.t('Certificate Goal')}</label>
                                  <input value={goal} className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                    title="" onChange={e => setGoal(e.target.value)} />
                                </div>
                                <div className="col-md-4 mb-3">
                                  <label className="form-label" for="validationCustom01">{I18n.t('Quantity Lives for Certificate')}</label>
                                  <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title=""
                                    title="" onChange={e => setQuantity(e.target.value)} value={quantity} />

                                </div>
                                <div className="col-md-12">
                                  <label className="form-label" for="exampleFormControlTextarea9">Example textarea</label>
                                  <textarea className="form-control " id="exampleFormControlTextarea9" rows="3"
                                    onChange={e => setText(e.target.value)} value={text}></textarea>
                                  <label className="form-label mt-2" for="validationCustom01"> Dynamic tags: {"{studentname}, {offername}, {certificatedate}"}.</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom01"> {I18n.t('Certificate File')}</label>
                              <input
                                accept=".jpg,.jpeg,.png"
                                id="validationCustom01"
                                type="file"
                                required=""
                                data-bs-original-title=""
                                title=""
                                className="form-control"
                                onChange={e => uploadImage(e)}
                              />

                              <div className="mt-3">
                                {(imageUrl !== null) ? <>
                                  <label>
                                    {I18n.t('Image Download')}
                                  </label>
                                  <a download
                                    href={imageUrl} target="_blank"
                                    className="btn btn-light ml-2 btn-sm"
                                    data-toggle="tooltip" data-original-title="download url">
                                    <i className="fa fa-download" />
                                  </a>
                                </> : ''}
                              </div>
                            </div>
                            {/* <div className="col-md-4 mb-3">
                              <label className="form-label" for="validationCustom01">{I18n.t('InfoProduct')}</label>
                              <select className="form-select" id="validationCustom04" required="" onChange={e => setProductId(e.target.value)}>
                                <option selected="" disabled="" value="">{I18n.t('select product')}</option>
                                {product_list.map((product) => (
                                  <option value={product.id} selected={(product.id === product_id) ? true : false}>{product.title}</option>
                                ))}
                              </select>
                            </div> */}

                            {/* <div className="col-md-4 mb-3">
                              <label className="form-label" for="validationCustom01"> {I18n.t('Certificate File')}</label>
                              <input
                                accept=".jpg,.jpeg,.png"
                                id="validationCustom01"
                                type="file"
                                required=""
                                data-bs-original-title=""
                                title=""
                                className="form-control"
                                onChange={e => uploadImage(e)}
                              />
                            </div>
                            <div className="col-md-4 mb-3" style={{ marginTop: 35 }}>
                              {(imageUrl !== null) ? <>
                                <label>
                                  Image Download
                                </label>
                                <a download
                                  href={imageUrl} target="_blank"
                                  className="btn btn-light ml-2 btn-sm"
                                  data-toggle="tooltip" data-original-title="download url">
                                  <i className="fa fa-download" />
                                </a>
                              </> : ''}
                            </div> */}
                          </div>
                        </div>
                        <div className="card-footer p-3 mt-3">
                          <button className="btn btn-primary" type="submit" style={{ minWidth: '150px' }} data-bs-original-title="" title=""
                            onClick={updateCertificate}  >
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Update')}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="card-body">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <button className="btn btn-primary" type="submit" style={{ width: '150px' }} data-bs-original-title="" title=""
                            onClick={updateCertificate}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}</button>
                        </div>

                        <div className="mb-3">
                          <button className="btn btn-warning" style={{ width: '150px' }} type="submit" data-bs-original-title="" title="">
                            <i className="fa fa-cog" aria-hidden="true"></i> {I18n.t('Settings')}</button>
                        </div>
                        <div className="mb-3">
                          <Link to="/certificates-preview">
                            <button className="btn btn-light" type="submit" style={{ width: '150px' }} data-bs-original-title="" title="">
                              <i className="fa fa-info-circle" aria-hidden="true"></i> {I18n.t('Preview')}</button></Link>
                        </div>
                        <div className="mb-3">
                          <Link to="/certificates">
                            <button className="btn btn-dark" type="submit" style={{ width: '150px' }} data-bs-original-title="" title="">
                              <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}</button></Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCertificate);