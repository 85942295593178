import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import Card from '../components/Card';
import Courses from '../components/Courses';
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import config from "../config/config";
import axios from "axios";
import { getUserData, } from '../actions/userdata';

const Dashboard = (props) => {
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid p-4">
                <div className="d-sm-flex align-items-center justify-content-between mb-3">
                  <h1 className="h3 mb-0 title-top">{I18n.t('Hello')}, {props.userdata.userData.first_name}</h1>
                  {/* <a href="#" className="d-none d-sm-inline-block btn btn-info shadow-sm">
                    <i className="fas fa-download fa-sm" /> {I18n.t('Generate Report')}
                  </a> */}
                </div>
                <div className="row">
                  <div className="col-lg-8">
                    <Card />
                    <div className="form-row mb-2  justify-content-between">
                      <h4 className="font-weight-bold title-top col-12">{I18n.t("Let's explore")}</h4>

                      <div className="col-auto">
                        <div className="nav-item dropdown no-arrow">

                          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="#">
                              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                              {I18n.t('Profile')}
                            </a>
                            <a className="dropdown-item" href="#">
                              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                              {I18n.t('Settings')}
                            </a>
                            <a className="dropdown-item" href="#">
                              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                              {I18n.t('Activity Log')}
                            </a>
                            <div className="dropdown-divider" />
                            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                              {I18n.t('Logout')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 mb-2">
                      <Courses />
                      {/* <div className="my-3">
                        <img src="img/course-not-available.svg" className="img-fluid" />
                      </div> */}
                    </div>
                  </div>
                  <Reviews />
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);