import React, { useEffect, useState } from 'react';
import TopNav from '../TopNav';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleLoader } from '../../actions/loader';
import { displayError, displaySuccess, userLoggedIn,notificationCount } from '../../Utils/utils'
import { Translate, I18n } from "react-redux-i18n";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
const Topbar = (props) => {

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [media, setMedia] = useState("");
  const [notification_count, setNotificationCount] = useState('');

  useEffect(async () => {
    //props.toggleLoader(true)
    var user_login = await userLoggedIn();
    setFirstName(user_login.first_name);
    setLastName(user_login.last_name);
    setMedia(user_login.media)
    //props.toggleLoader(false)
  }, [])

  const userLogout = () => {
    localStorage.removeItem('token');
    displaySuccess('Logout Successfull');
    setTimeout(() => window.location.href = "/", 3000);
  }
  useEffect(async () => {
    // props.toggleLoader(true);
    var noti_var = await notificationCount();
    setNotificationCount(noti_var);
    // props.toggleLoader(false);
  }, [])
  return (
    <>

      <nav className="  navbar navbar-expand navbar-light topbar mb-4 static-top">
        <form className="d-none d-sm-block d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          <div className="input-group">
            <input type="text" className="form-control bg-white border-0 small" placeholder={I18n.t('Search for...')} aria-label="Search" aria-describedby="basic-addon2" />
            <div className="input-group-append ml-55">
              <button className="btn bg-white text-primary" type="button">
                <i className="fas fa-search fa-sm" />
              </button>
            </div>
          </div>
        </form>
        <ul className="navbar-nav ml-auto">
          <li className="d-none d-sm-block nav-item dropdown no-arrow d-sm-none">
            {/* <a className="nav-link dropdown-toggle topbar-button" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-search fa-fw" />
            </a> */}
            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input type="text" className="form-control bg-light border-0 small" placeholder={I18n.t('Search for...')} aria-label="Search" aria-describedby="basic-addon2" />
                  <div className="input-group-append ml-55">
                    <button className="btn btn-primary" type="button" style={{ zIndex: 11 }}>
                      <i className="fas fa-search fa-sm" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li className="d-none d-md-block mr-2 language_ language_select">
            <LanguageSwitcher />
          </li>
          <li className="d-sm-block nav-item dropdown no-arrow mx-1">
            <Link to="/notifications">
            <a className="nav-link dropdown-toggle topbar-button" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="far fa-bell" />
              <span className="badge badge-danger badge-counter">{notification_count}</span>
            </a>
            </Link>
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
              <h6 className="dropdown-header">
                {I18n.t('Alerts Center')}</h6>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-primary">
                    <i className="fas fa-file-alt text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 12, 2019</div>
                  <span className="font-weight-bold">A new monthly report is ready to download!</span>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-success">
                    <i className="fas fa-donate text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 7, 2019</div>
                  $290.29 has been deposited into your account!
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="mr-3">
                  <div className="icon-circle bg-warning">
                    <i className="fas fa-exclamation-triangle text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">December 2, 2019</div>
                  Spending Alert: We've noticed unusually high spending for your account.
                </div>
              </a>
              <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
            </div>
          </li>
          <li className="d-sm-block nav-item dropdown no-arrow mx-1">
            {/* <a className="nav-link dropdown-toggle topbar-button" href="#" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="far fa-envelope fa-fw" />
              <span className="badge badge-danger badge-counter">7</span>
            </a> */}
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
              <h6 className="dropdown-header">
                {I18n.t('Message Center')}
              </h6>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img className="rounded-circle" src="img/undraw_profile_1.svg" alt="..." />
                  <div className="status-indicator bg-success" />
                </div>
                <div className="font-weight-bold">
                  <div className="text-truncate">Hi there! I am wondering if you can help me with a
                    problem I've been having.
                  </div>
                  <div className="small text-gray-500">Emily Fowler · 58m</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img className="rounded-circle" src="img/undraw_profile_2.svg" alt="..." />
                  <div className="status-indicator" />
                </div>
                <div>
                  <div className="text-truncate">I have the photos that you ordered last month, how
                    would you like them sent to you?
                  </div>
                  <div className="small text-gray-500">Jae Chun · 1d</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img className="rounded-circle" src="img/undraw_profile_3.svg" alt="..." />
                  <div className="status-indicator bg-warning" />
                </div>
                <div>
                  <div className="text-truncate">Last month's report looks great, I am very happy with
                    the progress so far, keep up the good work!
                  </div>
                  <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                </div>
              </a>
              <a className="dropdown-item d-flex align-items-center" href="#">
                <div className="dropdown-list-image mr-3">
                  <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60" alt="..." />
                  <div className="status-indicator bg-success" />
                </div>
                <div>
                  <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                    told me that people say this to all dogs, even if they aren't good...</div>
                  <div className="small text-gray-500">Chicken the Dog · 2w</div>
                </div>
              </a>
              <a className="dropdown-item text-center small text-gray-500" href="#">{I18n.t('Read More Messages')}</a>
            </div>
          </li>

          {/* <button className="btn btn-primary" style={{ marginLeft: "10px", height: "40px" }} onClick={userLogout}>
            Logout
            {/* <span className="mr-2 d-none d-lg-inline text-dark font-weight-bold">Logout</span> 
          </button> */}
          <button className="nav-link topbar-button btn ml-1 d-md-block" id="searchDropdown" role="button" aria-haspopup="true" title="Logout"
            aria-expanded="false" onClick={userLogout} style={{ height: '45px' }}>
            <i className="fas fa-power-off"></i>
          </button>
          <div className="topbar-divider d-none d-sm-block" />
          <li className="nav-item dropdown no-arrow">
            <Link to="/my-profile">
              <a className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {media == null ?
                  <img className="img-profile mr-2" src="/img/default.jpg" />
                  :
                  <img className="img-profile mr-2" src={media.url} />
                }
                {/* <img className="img-profile mr-2" src={media.url} /> */}
                <span className="mr-2 d-none d-lg-inline text-dark font-weight-bold">{first_name} {last_name}</span>
              </a></Link>
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a className="dropdown-item" href="#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                {I18n.t('Profile')}
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                {I18n.t('Settings')}
              </a>
              <a className="dropdown-item" href="#">
                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400" />
                {I18n.t('Activity Log')}
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                {I18n.t('Logout')}
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);