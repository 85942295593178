import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}

export const displayError = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
    })
    const Msg = ({ closeToast, toastProps }) => (
      row.map((char, i) => {
        return (
          <li>
          {I18n.t(char.replace(/\./g,''))}
          </li>
        );
      })
    )
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(I18n.t((error.response.data.message).replace(/\.|\!/g,'')));
  } else {
    toast.error(error.message);
  }
}

export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}

export const verifyEmail = async (token) => {
  let path = config.siteUrl;
  //var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/verify/" + token, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
  return response_final;
}

export const userLoggedIn = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/me", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return null;
    });
  return response_final;
}

export const instructorProfile = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/user/profile", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getCountries = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/country", {
    headers: { 'Content-Type': 'application/json', Authorization: ` ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getStates = async (country_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/state/" + country_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getCities = async (state_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/city/" + state_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getProductListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/productinfo", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getModuleListing = async (id = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/module?product_id=" + id, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getEditProduct = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/productinfo/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/infoproduct';
      } else {
        return null
      }
    });
  return response_final;
}

export const getInfoProductListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/productinfo", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getModuleEdit = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/module/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/module';
      } else {
        return null
      }
    })
  return response_final;
}

export const reOrderModule = async (order, id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/module/reorder/" + order + '/' + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}

export const reOrderTopic = async (order, id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/topic/reorder/" + order + '/' + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}


export const getTopicListing = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/topic", {
    module_id: id,
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getTopicEdit = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/topic/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/module';
      } else {
        return null
      }
    })
  return response_final;
}

export const productActive = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/product/productinfo/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}

export const moduleActive = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/module/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}

export const topicActive = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/product/topic/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const getActiveInfoProduct = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/content/product_listing", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getOffersListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/offer", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getLevelListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/level", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getPaymentList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/payment_list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getInstallmentList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/installment_list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getInterestList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/interest_list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getCategoryListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/categories", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getOfferListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/offer", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const offerActive = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/product/offer/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}

export const getProductWithModules = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/modules", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getOfferEdit = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/offer/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/offers';
      } else {
        return null
      }
    })
  return response_final;
}
export const dashboardCourses = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/dashboard/courses", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const dashboardReviews = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/dashboard/ratings", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const dashboardCards = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/dashboard/counts", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}

export const getLiveListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/product/livecontent", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const liveActive = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/product/livecontent/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const getLiveEdit = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/livecontent/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/live';
      } else {
        return null
      }
    })
  return response_final;
}

export const getOffer = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/interactivity/getoffers", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getModule = async (offer_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/interactivity/getmodules/" + offer_id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getTopic = async (module_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/interactivity/gettopics/" + module_id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}

export const getQuesListing = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/interactivity/overall?page=" + page, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getCertificateList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/certificate", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const getCertificateEdit = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/certificate/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 400) {
        window.location.href = '/certificate';
      } else {
        return []
      }
    });
  return response_final;
}

export const getTimezoneDropdown = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/timezone/dropdown", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getModuleQuestion = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/module/question/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getTopicQuestion = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/topic/question/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getApiKeys = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/instructor/token/view", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getCrossSellListing = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/cross_sell", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const editCrossSell = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/cross_sell/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const activeCrossSell = async (id, is_active) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');

  var response_final = await axios.get(path + "/cross_sell/status/" + id + "/" + is_active, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    })
  return response_final;
}
export const editCrossSellAssignment = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/cross_sell_assign/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const crossSellAssignList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/cross_sell_assign", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getCrossSellEvent = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/cross_sell/assignment/event/list", {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const editCrossSellEvent = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/cross_sell/assignment/edit/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getInstructorList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/product/offer/instructor", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const notificationCount = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notification/count", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const notificationRead = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notification/read", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotification = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/notification?page=" + page, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}