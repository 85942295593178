import React, { useState, useEffect } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TopNav from '../components/TopNav';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, getEditProduct } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";

const EditInfoProduct = (props) => {
  const [start_date, setStartDate] = useState(new Date());
  const handleChange = date => setStartDate(date);

  const [title, setTitle] = useState('');
  const [validity, setValidity] = useState('');
  const [is_immediate, setIsImmediate] = useState(0);
  const [durability_date, setDurabilityDate] = useState('');
  const [defined, setDefined] = useState('');
  const [days, setDays] = useState('');
  const [is_selected, setIsSelected] = useState(false);
  const [date_selected, setDateSelected] = useState(false);
  const [durable_date, setDurableDate] = useState('');


  const selected = () => {
    setIsSelected(true);
    setValidity(0);
    setIsImmediate(1);

    // for other side
    setDateSelected(false);
    setStartDate(new Date());
    setIsSelected(true);
    setValidity(0);
    setIsImmediate(1);
  }
  const selected2 = () => {
    setIsSelected(false);
    setIsImmediate(0);
    setValidity(0);
    //for other side
    setValidity(365);
    setDateSelected(true);
    setStartDate(null);
  }
  const selectDate = () => {
    setDateSelected(true);
    setStartDate(null);
    setIsSelected(false);
    //for left side
    setIsImmediate(0);
    setValidity(365);
  }
  const selectDate2 = () => {
    setDateSelected(false);
    setStartDate(new Date());
    //for left side
    setIsSelected(true);
    setValidity(0);
    setIsImmediate(1);
  }


  useEffect(async () => {
    props.toggleLoader(true);
    var editProduct_var = await getEditProduct(props.match.params.id)
    setTitle(editProduct_var.title)
    setIsImmediate(editProduct_var.is_immediate)
    setDays(editProduct_var.days)
    setValidity(editProduct_var.validity)
    setDefined(editProduct_var.defined)
    setDurableDate(editProduct_var.durable_date)
    if (editProduct_var.durability_date === null) {
      setStartDate(null);
      setDateSelected(true);
    }
    else {
      setStartDate(new Date(editProduct_var.durability_date));
      setIsSelected(true);
    }
    props.toggleLoader(false);
  }, [])

  const updateProduct = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/productinfo/update/' + props.match.params.id, {
      title: title,
      is_immediate: is_immediate,
      validity: validity,
      durability_date: start_date,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Product Updated Successfully');
        setTimeout(() => window.location.href = "/infoproduct", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h1 className="h3 title-top">{I18n.t('Edit Infoproduct')}</h1>
                    </div>

                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/infoproduct">{I18n.t('Infoproduct')}</Link></li>
                        <li className="breadcrumb-item active">{I18n.t('Edit Infoproduct')}</li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">


                      <div className="needs-validation" novalidate="">
                        <div className="card-body">
                          <div className="row g-3">
                            <div className="col-md-4">
                              <label className="form-label" for="validationCustom01">{I18n.t('Infoproduct Title')}</label>
                              <input className="form-control" id="validationCustom01" type="text" required="" data-bs-original-title="" title=""
                                onChange={e => setTitle(e.target.value)}
                                value={title} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-5 my-3">
                              <label className="form-label font-weight-bold" for="validationCustom03">{I18n.t('Purchase Validity In Days')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    {validity === "0" || validity === null ?
                                      <input className="form-check-input" id="radio1" type="radio" name="radio1" data-bs-original-title="" title=""
                                        //value={is_immediate}
                                        checked={is_immediate}
                                        onClick={selected}
                                      />
                                      : <input className="form-check-input" id="radio1" type="radio" name="radio1" data-bs-original-title="" title=""
                                        onClick={selected}
                                        //value={is_immediate}
                                        checked={is_immediate}
                                      />}

                                    <label className="form-check-label" for="radio1">{I18n.t('Immediately')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    {validity !== null ?
                                      <input className="form-check-input" id="radio2" type="radio" name="radio1" data-bs-original-title="" title=""
                                        onChange={e => setDays(e.target.value)}
                                        onClick={selected2}
                                        value={days}
                                        checked={!is_immediate}
                                      />
                                      : <input className="form-check-input" id="radio2" type="radio" name="radio1" data-bs-original-title="" title=""
                                        onChange={e => setDays(e.target.value)}
                                        onClick={selected2}
                                        value={days}
                                        checked={!is_immediate}
                                      />}
                                    <label className="form-check-label" for="radio2">{I18n.t('number of days')}</label>
                                  </div>
                                </div>

                                <div className="col-md-10">
                                  {is_selected === true ? <input className="form-control" id="validationCustom01" type="text" required=""
                                    data-bs-original-title="" title=""
                                    // onChange={e => setValidity(e.target.value)}
                                    disabled
                                    value={validity}
                                  /> : <input className="form-control" id="validationCustom01" type="text" required=""
                                    data-bs-original-title="" title=""
                                    onChange={e => setValidity(e.target.value)}
                                    value={validity} />}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 my-3">
                              <label className="form-label font-weight-bold" for="validationCustom03">{I18n.t('Purchase Durability')}</label>
                              <div className="">
                                <div className="mb-3 m-t-15 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    {start_date === null ?
                                      <input
                                        className="form-check-input"
                                        id="radio3"
                                        type="radio"
                                        name="radio2"
                                        data-bs-original-title=""
                                        title=""
                                        onChange={e => setDefined(e.target.value)}
                                        onClick={selectDate}
                                        ///value={defined}
                                        checked
                                      /> :
                                      <input
                                        className="form-check-input"
                                        id="radio3"
                                        type="radio"
                                        name="radio2"
                                        data-bs-original-title=""
                                        title=""
                                        onChange={e => setDefined(e.target.value)}
                                        onClick={selectDate}
                                        //value={defined}
                                        defaultChecked={true}
                                        checked={date_selected}
                                      />}

                                    <label className="form-check-label" for="radio3">{I18n.t('undefined')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    {start_date !== null ?
                                      <input
                                        className="form-check-input"
                                        id="radio4"
                                        type="radio"
                                        name="radio2"
                                        data-bs-original-title=""
                                        title=""
                                        onClick={selectDate2}
                                        onChange={e => setDurableDate(e.target.value)}
                                        // value={durable_date}
                                        defaultChecked={true}
                                        checked={is_selected}
                                      /> :
                                      <input className="form-check-input" id="radio4" type="radio" name="radio2" data-bs-original-title="" title=""
                                        onClick={selectDate2}
                                        onChange={e => setDurableDate(e.target.value)}
                                        value={durable_date}
                                        checked={is_selected}
                                        defaultChecked={false}
                                      />}

                                    <label className="form-check-label" for="radio4">{I18n.t('Date')}</label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  {date_selected === true ? <DatePicker
                                    // selected={start_date}
                                    onChange={handleChange}
                                    dateFormat="yyyy-MM-dd "
                                    style={{ marginTop: '90px' }}
                                    disabled
                                    customInput={
                                      <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                        value={durability_date}
                                        onChange={e => setDurabilityDate(e.target.value)} />
                                    }
                                  /> : <DatePicker
                                    selected={start_date}
                                    onChange={handleChange}
                                    dateFormat="yyyy-MM-dd "
                                    style={{ marginTop: '90px' }}
                                    customInput={
                                      <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                        onChange={e => setDurabilityDate(e.target.value)}
                                        value={durability_date}
                                      />
                                    }
                                  />}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title=""
                            onClick={updateProduct}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}</button>
                          &nbsp;
                          <Link to="/infoproduct">
                            <button className="btn btn-dark" type="submit" data-bs-original-title="" title="">
                              <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditInfoProduct);