import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, getTopicEdit } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { I18n } from 'react-redux-i18n';

const EditTopic = (props) => {
  const [videoFieldAdd, setvideoFieldAdd] = useState([{ video: "" }]);
  const [filesFieldAdd, setfilesFieldAdd] = useState([{ file: "" }]);

  const [start_date, setStartDate] = useState(new Date());
  const handleChange = date => setStartDate(date);

  const [module_id, setModuleId] = useState('');
  const [title, setTitle] = useState('');
  const [send_email_notifcation, setEmailNotifcation] = useState(0);
  const [consider_for_certification, setConsiderForCertification] = useState(0);
  const [is_free_content, setFreeContent] = useState(0);
  const [is_bonus_content, setBonusContent] = useState(0);
  const [text_content, setTextContent] = useState('');
  const [video_url, setVideoUrl] = useState('');
  const [url_enable_watermark, setUrlWatermark] = useState(0);
  const [media_id, setMediaId] = useState('');
  const [media_enable_watermark, setMediaWatermark] = useState(0);
  const [pdf, setPdf] = useState('');
  const [pdf_media_url, setPdfMediaUrl] = useState('');
  const [duration_in_minutes, setDurationInMinutes] = useState('');
  const [is_youtube, setIsYoutube] = useState(0);

  useEffect(async () => {
    var get_topic_edit = await getTopicEdit(props.match.params.id);
    console.log(get_topic_edit);
    setModuleId(get_topic_edit.module_id)
    setTitle(get_topic_edit.title);
    setEmailNotifcation(get_topic_edit.send_email_notifcation);
    setFreeContent(get_topic_edit.is_free_content);
    setBonusContent(get_topic_edit.is_bonus_content);
    setDurationInMinutes(get_topic_edit.duration_in_minutes);
    setConsiderForCertification(get_topic_edit.consider_for_certification);
    if (get_topic_edit.video !== null) {
      setUrlWatermark(get_topic_edit.video.enable_watermark);
      setVideoUrl(get_topic_edit.video.url);
      setIsYoutube(get_topic_edit.video.is_youtube);
    }
    if (get_topic_edit.text_content !== null) {
      setTextContent(get_topic_edit.text_content);
    } else {
      setTextContent('');
    }

    setPdf(get_topic_edit.pdf);
    if (get_topic_edit.pdf !== null) {
      if (get_topic_edit.pdf.media !== null) {
        setPdfMediaUrl(get_topic_edit.pdf.media.url);
        setMediaId(get_topic_edit.pdf.media_id);
        setMediaWatermark(get_topic_edit.pdf.enable_watermark);
      }
    }
  }, [])
  const uploadVideo = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/video_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setVideoUrl(success.data.data.url);
      console.log(success.data.data.url);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading video');
    })
  }
  const editNewTopic = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/topic/update/' + props.match.params.id, {
      module_id: module_id,
      title: title,
      send_email_notifcation: send_email_notifcation,
      consider_for_certification: consider_for_certification,
      is_free_content: is_free_content,
      is_bonus_content: is_bonus_content,
      text_content: text_content,
      start_date: start_date,
      url: video_url,
      url_enable_watermark: url_enable_watermark,
      duration_in_minutes: duration_in_minutes,
      media_id: media_id,
      media_enable_watermark: media_enable_watermark,
      is_youtube: is_youtube
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Topic Edited Successfully');
        setTimeout(() => window.location.href = "/view-topic/" + module_id, 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  const email = () => {
    setEmailNotifcation(1);
  }

  const free_content = () => {
    setFreeContent(1);
  }

  const bonus_content = () => {
    setBonusContent(1);
  }

  const certification = () => {
    setConsiderForCertification(1);
  }

  const url_watermark = () => {
    setUrlWatermark(1);
  }

  const media_watermark = () => {
    setMediaWatermark(1);
  }

  // handle click event of the Remove button
  const removeVideoField = index => {
    const list = [...videoFieldAdd];
    list.splice(index, 1);
    setvideoFieldAdd(list);
  };

  // handle click event of the Add button
  const addVideoField = () => {
    setvideoFieldAdd([...videoFieldAdd, { video: "" }]);
  };

  const removeFilesField = index => {
    const list = [...filesFieldAdd];
    list.splice(index, 1);
    setfilesFieldAdd(list);
  };

  const addFilesField = () => {
    setfilesFieldAdd([...filesFieldAdd, { file: "" }]);
  };
  const uploadPdf = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/pdf_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }
  return (
    <>
      <body id="page-top">
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>Edit Topic</h3>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <a href="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </a>
                        </li>
                        <li className="breadcrumb-item"><Link to={"/view-topic/" + module_id}>View Topic</Link> </li>
                        <li className="breadcrumb-item">Edit Topic </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row my-3">
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom01">{I18n.t('Topic Name')}</label>
                            <input className="form-control" id="validationCustom01" type="text"
                              placeholder="New Topic" required="" data-bs-original-title="" title=""
                              value={title}
                              onChange={e => setTitle(e.target.value)} />
                          </div>
                          <div className="col-md-8">

                            {videoFieldAdd.map((x, i) => {
                              return (
                                <div className="row">
                                  <div className="col-md-5 mb-3">
                                    <label className="form-label" for="validationCustom01">{I18n.t('Video')}</label>
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="text"
                                      placeholder="Video"
                                      data-bs-original-title=""
                                      title=""
                                      value={video_url}
                                      onChange={e => setVideoUrl(e.target.value)} />

                                  </div>
                                  <div className='col-md-4 ' style={{ marginTop: '30px' }}>
                                    <input type="file" accept=".mp4,.mov,.avi,.flv,.mkv,.webm" className="form-control"
                                      id="validationCustom01"
                                      required=""
                                      data-bs-original-title=""
                                      title="" onChange={e => uploadVideo(e)} />
                                  </div>
                                  <div className="col-md-3 align-self-center pt-2">
                                    <div className="form-check">
                                      <div className="checkbox p-0">
                                        <input className="form-check-input" id="invalidCheck" type="checkbox"
                                          onChange={e => {
                                            if (e.target.checked) {
                                              setIsYoutube(1)
                                            } else {
                                              setIsYoutube(0)
                                            }
                                          }}
                                          checked={is_youtube} />
                                        <label className="form-check-label" for="invalidCheck">{I18n.t('Youtube Video')}</label>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-6">
                                    {videoFieldAdd.length !== 1 && 
                                  <i className="fas fa-minus-circle"
                                    onClick={() => removeVideoField(i)}></i>}
                                  {videoFieldAdd.length - 1 === i && <i className="fas fa-plus-circle" onClick={addVideoField}></i>}
                                </div> */}
                                </div>
                              );
                            })}
                          </div>
                          <div className="col-md-12">
                            <div className="m-t-15 row">
                              <div className="col-md-2 align-self-center">
                                <div className="form-check">
                                  <div className="checkbox p-0">
                                    <input
                                      className="form-check-input"
                                      id="invalidCheck"
                                      type="checkbox"
                                      required=""
                                      data-bs-original-title=""
                                      title=""
                                      // onClick={url_watermark}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setUrlWatermark(1)
                                        } else {
                                          setUrlWatermark(0)
                                        }
                                      }}
                                      checked={url_enable_watermark} />
                                    <label className="form-check-label" for="invalidCheck">{I18n.t('Enable Watermark')}</label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <div className="form-check checkbox mb-0">
                                  <input
                                    className="form-check-input"
                                    id="checkbox1"
                                    type="checkbox"
                                    data-bs-original-title=""
                                    title=""
                                    onClick={certification}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setConsiderForCertification(1)
                                      } else {
                                        setConsiderForCertification(0)
                                      }
                                    }}
                                    checked={consider_for_certification}
                                  />
                                  <label className="form-check-label" for="checkbox1">{I18n.t('Consider For Certificate')}</label>
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-check checkbox mb-0">
                                  <input
                                    className="form-check-input"
                                    id="checkbox2" type="checkbox"
                                    data-bs-original-title=""
                                    title=""
                                    onClick={email}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setEmailNotifcation(1)
                                      } else {
                                        setEmailNotifcation(0)
                                      }
                                    }}
                                    checked={send_email_notifcation} />
                                  <label className="form-check-label" for="checkbox2">{I18n.t('Send email notifcation')}</label>
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <div className="form-check checkbox mb-0">
                                  <input
                                    className="form-check-input"
                                    id="checkbox3"
                                    type="checkbox"
                                    data-bs-original-title=""
                                    title=""
                                    onClick={free_content}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setFreeContent(1)
                                      } else {
                                        setFreeContent(0)
                                      }
                                    }}
                                    checked={is_free_content}
                                  />
                                  <label className="form-check-label" for="checkbox3">{I18n.t('Free Content')}</label>
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <div className="form-check checkbox mb-0">
                                  <input
                                    className="form-check-input"
                                    id="checkbox4"
                                    type="checkbox"
                                    data-bs-original-title=""
                                    title=""
                                    onClick={bonus_content}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setBonusContent(1)
                                      } else {
                                        setBonusContent(0)
                                      }
                                    }}
                                    checked={is_bonus_content} />
                                  <label className="form-check-label" for="checkbox4">{I18n.t('Bonus content')}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-md-7 mb-2">
                            <div className="form-group ul-list">
                              <label className="form-label" for="validationCustom01">{I18n.t('Description')}</label>
                              <CKEditor
                                editor={ClassicEditor}
                                config={{
                                  toolbar: ['heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    '|',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'insertTable',
                                    '|',
                                    'undo',
                                    'redo', '|', 'link']
                                }}
                                data={text_content}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setTextContent(data)
                                }}
                              />
                            </div>
                          </div>


                          <div className="col-md-3 mb-2">
                            <label className="form-label" for="validationCustom01">{I18n.t('Duration (Minutes)')}</label>
                            <input
                              className="form-control"
                              id="validationCustom01"
                              type="text"
                              placeholder="Duration (Minutes)"
                              required=""
                              title=""
                              value={duration_in_minutes}
                              onChange={e => setDurationInMinutes(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-md-12">
                            {filesFieldAdd.map((file, i) => {
                              return (
                                <div className="row">
                                  <div className="col-md-3 mb-2">
                                    <label className="form-label" for="validationCustom01">{I18n.t('New PDF Resource')}</label>
                                    <input
                                      accept=".pdf"
                                      id="validationCustom01"
                                      type="file"
                                      required=""
                                      data-bs-original-title=""
                                      title=""
                                      onChange={e => uploadPdf(e)}
                                      className="form-control"
                                    />

                                  </div>
                                  <div className="col-md-3 mb-2 align-self-end">
                                    {(pdf_media_url !== '') ?
                                      <>
                                        <label className="form-label" for="validationCustom066">{I18n.t('Current PDF Resource')}</label>
                                        <a download
                                          href={pdf_media_url} target="_blank"
                                          className="btn btn-light"
                                          data-toggle="tooltip"
                                          style={{ fontSize: '12px' }} data-original-title="download url">
                                          <i className="fa fa-download" style={{ fontSize: '10px' }} />
                                        </a></>
                                      : ""}
                                  </div>
                                  <div className="col-md-3 mb-2 align-self-end">
                                    <div className="form-check checkbox mb-0">
                                      <input
                                        className="form-check-input"
                                        id="checkbox6"
                                        type="checkbox"
                                        data-bs-original-title=""
                                        title=""
                                        // onClick={media_watermark}
                                        onChange={e => {
                                          if (e.target.checked) {
                                            setMediaWatermark(1)
                                          } else {
                                            setMediaWatermark(0)
                                          }
                                        }}
                                        checked={media_enable_watermark} />
                                      <label className="form-check-label" for="checkbox6">{I18n.t('Enable watermark for pdf')}</label>
                                    </div>
                                    {/* {filesFieldAdd.length !== 1 && <i className="fas fa-minus-circle"
                                    onClick={() => removeFilesField(i)}></i>}
                                  {filesFieldAdd.length - 1 === i && <i className="fas fa-plus-circle"
                                    onClick={addFilesField}></i>} */}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="row my-2" disabled>
                          <div className="col-md-3 " disabled>
                            <label className="form-label" for="validationCustom03">Cross sell</label>
                            <div className="">
                              <div className="mb-3 m-t-15 custom-radio-ml">
                                <div className="form-check radio radio-primary">
                                  <input className="form-check-input" id="radio5" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="radio5">No</label>
                                </div>
                                <div className="form-check radio radio-primary">
                                  <input className="form-check-input" id="radio6" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="radio6">Random</label>
                                </div>
                                <div className="form-check radio radio-primary">
                                  <input className="form-check-input" id="radio7" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="radio7">Specific</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3" disabled>
                            <label className="form-label" for="validationCustom04">Select</label>
                            <select className="form-control" id="validationCustom04" required="">
                              <option selected="" disabled="" value="">Select</option>
                              <option>Option 1</option>
                              <option>Option 2</option>
                            </select>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label" for="validationCustom03">Start Time</label>
                            <div className="">
                              <div className="mb-3 m-t-15 custom-radio-ml">
                                <div className="form-check radio radio-primary">

                                  <input className="form-check-input" id="radio8" type="radio" name="radio4" value="option1" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="radio8">From the start</label>
                                </div>
                                <div className="form-check radio radio-primary">
                                  <input className="form-check-input" id="radio9" type="radio" name="radio4" value="option1" data-bs-original-title="" title="" />
                                  <label className="form-check-label" for="radio9">Starting at</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <label className="form-label" for="validationCustom03">Start Date</label>
                            <DatePicker
                              selected={start_date}
                              onChange={handleChange}
                              dateFormat="yyyy-MM-dd "
                              style={{ marginTop: '90px' }}
                              customInput={
                                <input type="text" className="form-control" placeholder=" Date"
                                  onChange={e => setStartDate(e.target.value)}
                                />
                              }
                            />
                          </div>
                        </div>

                      </div>
                      <div className="card-footer">
                        <button className="btn btn-primary" type="submit" data-bs-original-title="" title="" onClick={editNewTopic}>
                          <i className="fa fa-check" aria-hidden="true"></i> Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditTopic);