import React from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Responses from './Responses';
import OverallQuestion from "./OverallQuestion";
import Integeration from "./Integeration";
import TopNav from '../components/TopNav';
import './interaction.css';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const Interactivity = (props) => {

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">
          <Leftbar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Interactivity')}</h1>
                  </div>
                  <div className="col-md-3">
                    {/* <select className="form-select" id="validationCustom04" required="" onChange={e => setProductId(e.target.value)}>
                      <option selected="" disabled="" value="">{I18n.t('Select Product')}</option>
                      {product_list.map((product) => (
                        <option value={product.id}>{I18n.t(product.title)}</option>
                      ))}
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Tabs defaultActiveKey="Responses" transition={false} id="noanim-tab-example">
                  <Tab eventKey="Responses" title={I18n.t('Responses')}>
                    <Responses />
                  </Tab>
                  <Tab eventKey="OverallQuestion" title={I18n.t('Overall Questions')}>
                    <OverallQuestion />
                  </Tab>
                  <Tab eventKey="Integeration" title={I18n.t('Integrations')} >
                    <Integeration />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Interactivity);