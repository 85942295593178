import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, getLiveListing, getCrossSellListing } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import DatePicker from 'react-datepicker';

const AddCrossSellEvent = (props) => {
  const [list, setList] = useState([]);
  const [offer_id, setOfferId] = useState('');
  const [offers, setOffers] = useState([]);
  const [cross_sell_id, setCrossSellId] = useState('');
  const [eventId, setEventId] = useState('');
  const [event, setEvent] = useState([]);

  useEffect(async () => {
    props.toggleLoader(true);
    var getCross_var = await getCrossSellListing();
    setList(getCross_var);
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    props.toggleLoader(true);
    var getLive_var = await getLiveListing();
    console.log(getLive_var);
    setEvent(getLive_var);
    props.toggleLoader(false);
  }, [])

  const addCrossSellEvent = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/cross_sell/assignment/add/event', {
      cross_sell_id: cross_sell_id,
      event_id: eventId
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Cross-sell Assignment event Added Successfully');
        setTimeout(() => window.location.href = "/cross-sell-assignments-event", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">
          <Leftbar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('Add Cross Sell Assignment Event')}</h1>
                  </div>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard" data-bs-original-title="" title="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                      </Link>
                    </li>
                    <li className="breadcrumb-item"><Link to="/cross-sell-assignments-event">{I18n.t('Cross Sell Assignment Event')}</Link></li>
                    <li className="breadcrumb-item active">{I18n.t('Add Cross Sell Assignment Event')}</li>
                  </ol>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="needs-validation" novalidate="">
                          <br />
                          <div className="row g-3">
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom04">{I18n.t('Cross Sell')} </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  setCrossSellId(e.target.value)
                                }}>
                                <option value={""} >{I18n.t('Select Cross Sell')}</option>
                                {list.map((info) =>
                                  <option value={info.id} >{info.title}</option>
                                )}
                              </select>
                            </div>
                            <div className="col-md-4 mb-2">
                              <label className="form-label" for="validationCustom04">{I18n.t('Events')} </label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => {
                                  setEventId(e.target.value)
                                }}
                              >
                                <option value={""} >{I18n.t('Select Events')}</option>
                                {event.map((list) =>
                                  <option value={list.id} >{list.title}</option>
                                )}
                              </select>
                            </div>

                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-5">
                              <button className="btn btn-primary btn-sm mb-2" type="submit" data-bs-original-title="" title=""
                                onClick={addCrossSellEvent}
                              >
                                <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCrossSellEvent);