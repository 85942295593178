import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getQuesListing } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";
import ReactPaginate from 'react-paginate';
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess } from '../Utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';

const OverallQuestion = (props) => {
  const [overall, setOverall] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  // const [is_loading, setIsLoading] = useState(true);
  const [answer, setAnswer] = useState('');
  const [id, setId] = useState('');
  const [is_delete, setIsDelete] = useState(false);
  useEffect(async () => {
    props.toggleLoader(true);
    var getQues_var = await getQuesListing(current_page);
    console.log(getQues_var);
    setOverall(getQues_var.data);
    setPageCount(getQues_var.last_page);
    props.toggleLoader(false);
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var getQues_var = await getQuesListing(selectedPage);
    setOverall(getQues_var.data);
    setPageCount(getQues_var.last_page);
  };
  const updateAnswer = (id) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/instructor/interactivity/update/answer/' + id, {
      answer: answer,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Question updated Successfully');
        // setTimeout(() => window.location.href = "/interactivity", 3000);

      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  const deleteQuestion = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.get(path + '/student/delete/question/' + id, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        props.toggleLoader(false);
        displaySuccess('question Deleted Successfully');
        var getQues_var = await getQuesListing(current_page);
        setOverall(getQues_var.data);
        setPageCount(getQues_var.last_page);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  return (
    <>
      <div className="">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="card mb-4 radius-0">
              <div className="card-header radius-0">
                {/* <h5>Add Your Question</h5> */}
                <div className="col-auto">
                  <div className="form-row">
                    <div className="col mb-12">
                      <Link to="/interactivity/add-question" className=" btn btn-primary" style={{ float: 'right' }}>
                        <i className="fas fa-plus fa-sm"></i>
                        {I18n.t('Add Question')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {overall.length > 0 ?
                  <div className="needs-validation" novalidate="">
                    {overall.map((ans) => (
                      <div className="row g-3">
                        <div className="col-md-5">
                          <div className="box sb3">
                            {ans.question}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <label className="form-label" for="exampleFormControlTextarea9">{I18n.t('Response')}</label>
                          <textarea className="form-control mb-2" id="exampleFormControlTextarea9" rows="3"
                            onChange={e => setAnswer(e.target.value)} defaultValue={ans.answer} key={ans.id}>
                            {/* {ans.answer} */}
                          </textarea>

                          <button className="btn btn-primary btn-sm mb-2" type="submit" data-bs-original-title="" title=""
                            onClick={e => updateAnswer(ans.id)}>
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')}
                          </button>
                        </div>
                        <div className="col-md-2 align-self-center">
                          <div>
                            <button className="flex items-center btn btn-sm text-danger" href=""
                              onClick={e => {
                                setId(ans.id)
                                setIsDelete(true)
                              }}>
                              <i className="far fa-trash-alt btn-outline-danger"></i> {I18n.t('Delete')}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  :
                  <div className="my-3 mx-auto default_div">
                    {/* <img src="img/review-not.svg" className="img-fluid" /> */}
                    <p className="text-center">NO Questions Exist </p>
                  </div>
                }
              </div>
            </div>
          </div>

        </div>
        <div className="row">
          <div className="mx-auto d-block">
            <ReactPaginate
              previousLabel={I18n.t('prev')}
              nextLabel={I18n.t('next')}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        {is_delete === true ? (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Yes"
            confirmBtnBsStyle="danger"
            title={I18n.t('Are you sure?')}
            onConfirm={(e) => {
              deleteQuestion()
              setIsDelete(false)
            }}
            onCancel={(e) => {
              setIsDelete(false)
              setId('')
            }}
            focusCancelBtn
          >
            {I18n.t('Do you want to delete this offer?')}
          </SweetAlert>
        ) : ([]
        )}
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OverallQuestion);