import React, { useState, useEffect } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from 'react-router-dom';
import TopNav from '../components/TopNav';
import config from "../config/config";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { displayError, displaySuccess, getActiveInfoProduct, getOffersListing, getModuleEdit } from '../Utils/utils'
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import Alert from 'react-bootstrap/Alert'
import { Translate, I18n } from "react-redux-i18n";

const ModuleEdit = (props) => {
  const [title, setTitle] = useState('');
  const [product_id, setProductId] = useState('');
  const [is_free_content, setFreeContent] = useState('');
  const [offers, setOffers] = useState([]);
  const [order, setOrder] = useState('');

  const [product_list, setProductList] = useState([]);
  const [offer_list, setOfferList] = useState([]);

  const moduleUpdate = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/module/update/' + props.match.params.id, {
      title: title,
      product_id: product_id,
      is_free_content: is_free_content,
      order: order,
      offers: offers
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Module Updated Successfully');
        setTimeout(() => window.location.href = "/module", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }

  useEffect(async () => {
    props.toggleLoader(true)
    var get_product_listing = await getActiveInfoProduct();
    setProductList(get_product_listing);
    console.log(get_product_listing);
    props.toggleLoader(false)
  }, [])

  useEffect(async () => {
    props.toggleLoader(true)

    var get_module_edit = await getModuleEdit(props.match.params.id);
    setTitle(get_module_edit.title);
    setProductId(get_module_edit.product_id);
    setFreeContent(get_module_edit.is_free_content);
    setOffers(get_module_edit.offer_ids);
    setOrder(get_module_edit.order);
    var get_offer_listing = await getOffersListing();
    setOfferList(get_offer_listing);
    console.log(get_offer_listing);
    props.toggleLoader(false)
  }, [])

  const selectOffers = (e, offer_id) => {
    console.log(e.target.checked, offer_id);
    var temp_array = offers;
    if (e.target.checked === true) {
      //push to temp array to add element
      temp_array.push(offer_id);
    } else {
      // remove element from array
      var index = temp_array.indexOf(offer_id);
      if (index > -1) {
        temp_array.splice(index, 1);
      }
    }
    setOffers(temp_array);
  }

  const selectedOffers = () => {
    var array = [offers]
    array.includes()
  }

  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">
                <div className="page-title">
                  <div className="row">
                    <div className="col-sm-6">
                      <h3>{I18n.t('Module Edit')}</h3>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb mt-2">
                        <li className="breadcrumb-item">
                          <a href="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </a>
                        </li>
                        <li className="breadcrumb-item"><Link to="/module">{I18n.t('Module')}</Link> </li>
                        <li className="breadcrumb-item"><Link to="">{I18n.t('Module Edit')}</Link> </li>                                            </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row g-3">
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom03">{I18n.t('Title')}</label>
                            <input className="form-control" id="validationCustom03" type="text" placeholder={I18n.t('Title')} required=""
                              data-bs-original-title="" title="" value={title} onChange={e => setTitle(e.target.value)} />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="form-label" for="validationCustom04">{I18n.t('Select Infoproduct')}</label>
                            <select className="form-select" id="validationCustom04" required="" onChange={e => setProductId(e.target.value)}>
                              <option value={""} required>{I18n.t('Select Product')}</option>
                              {product_list.map((product) => (
                                <option value={product.id} selected={(product.id === product_id) ? true : false}>{product.title}</option>
                              ))}
                            </select>

                          </div>
                          <div className="col-md-4  align-self-center">
                            <div className="form-check checkbox mb-0">
                              {
                                <input
                                  className="form-check-input"
                                  id="checkbox1"
                                  name="checkbox1"
                                  type="checkbox"
                                  defaultChecked={is_free_content}
                                  checked={is_free_content}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      setFreeContent(1)
                                    } else {
                                      setFreeContent(0)
                                    }
                                  }} title="" />
                              }
                              <label className="form-check-label" for="checkbox1">{I18n.t('Free Content')}</label>
                            </div>
                          </div>
                        </div>
                        <div className="row g-3">
                          <div className="col-md-12 col-sm-12">
                            <br />
                            <label className="form-label" for="validationCustom04">{I18n.t('Select Offer')}</label>
                            <div className="mb-3">
                              <div className="row">
                                {
                                  (offer_list.length === 0) ? <div className="col">
                                    <Alert key={'alert'} variant={'danger'}>
                                      {I18n.t('There are no Offers at the moment to add to this offer. You can add them later!!')}
                                    </Alert>
                                  </div> : ''
                                }
                                {offer_list.map((offer, index) => (
                                  <div className="col-lg-3 m-2">
                                    <div className="form-check checkbox mb-0">
                                      <input
                                        className="form-check-input"
                                        id={"offer-list-" + index}
                                        type="checkbox"
                                        data-bs-original-title=""
                                        defaultChecked={(offers !== undefined) ?
                                          (offers.includes(offer.id)) ? true : false : false
                                        }
                                        title=""
                                        onClick={e => { selectOffers(e, offer.id) }}
                                      />
                                      <label className="form-check-label" for={"offer-list-" + index}>{offer.name}</label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="row g-3">
                              <div className="col-md-5">
                                <label className="form-label" for="validationCustom04">{I18n.t('Order')}</label>
                                <input className="form-control" id="validationCustom03" type="text" placeholder={I18n.t('Order')} required=""
                                  data-bs-original-title="" value={order} title="" onChange={e => setOrder(e.target.value)} />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-12 mt-4">
                            <button className="btn btn-primary" type="submit" data-bs-original-title title onClick={moduleUpdate}>{I18n.t('Submit')}</button>
                          </div> */}
                        </div>
                      </div>
                      <div className="card-footer">
                        <button className="btn btn-primary" type="submit" data-bs-original-title title onClick={moduleUpdate} style={{ textTransform: 'capitalize' }}>
                          <i class="fa fa-check mr-2" aria-hidden="true"></i> Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModuleEdit);