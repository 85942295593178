import React, { useEffect, useState } from 'react';
import Leftbar from './../components/Leftbar';
import Topbar from './../components/Topbar';
import { Link } from "react-router-dom";
import TopNav from '../components/TopNav';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { getProductListing } from '../Utils/utils'
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, productActive } from '../Utils/utils';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Translate, I18n } from "react-redux-i18n";
import Alert from 'react-bootstrap/Alert'

const InfoProduct = (props) => {
  const [products, setProducts] = useState([]);
  // const [id] = useState('0');
  const [is_delete, setIsDelete] = useState(false);
  const [tempVarId, setTempVarId] = useState('');
  const [is_active, setIsActive] = useState(0);

  useEffect(async () => {
    props.toggleLoader(true);
    var getProducts_var = await getProductListing();
    console.log(getProducts_var);
    setProducts(getProducts_var);
    props.toggleLoader(false);
  }, [])

  const deleteProduct = async () => {
    let path = config.siteUrl;
    var token = localStorage.getItem('token');
    // var response_final = await 
    axios.get(path + "/product/productinfo/delete/" + tempVarId, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        //props.toggleLoader(false);
        displaySuccess('Deleted Successfully');
        //props.toggleLoader(true);
        var getProducts_var = await getProductListing();
        setProducts(getProducts_var);
        props.toggleLoader(false);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
    // return response_final;
  }

  const activeProduct = async (id, is_active) => {
    props.toggleLoader(true);
    if (is_active === 1) {
      var active_var = await productActive(id, 0);
    }
    else {
      var active_var = await productActive(id, 1);
    }
    console.log(active_var);
    setIsActive(active_var);
    var getProducts_var = await getProductListing();
    setProducts(getProducts_var);
    props.toggleLoader(false);
  }


  return (
    <>
      <body id="page-top">
        <TopNav />

        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <div className="col">
                    <h1 className="h3 mb-0 title-top">{I18n.t('InfoProduct')}</h1>
                  </div>

                  <div className="col-auto ml-auto d-none d-sm-inline-block d-block d-sm-none">
                    <div className="form-row">
                      <div className="col mt-2">
                        <div className="infobutton">
                          <Link to="/add-infoproduct">
                            <a className=" btn btn-light">
                              <i className="fas fa-plus fa-sm" />  {I18n.t('New InfoProduct')}</a></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card m-overflow-h">

                      <div className="card-body m-p-0">
                        <div className="table-responsive">
                          <Table className="table align-items-center">
                            <Thead>
                              <Tr>
                                <Th>{I18n.t('InfoProduct')}</Th>
                                <Th>{I18n.t('Validity in days')}</Th>
                                <Th>{I18n.t('Durability')}</Th>
                                <Th>{I18n.t('Status')}</Th>
                                <Th>{I18n.t('Action')}</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {(products.length === 0) ? <Tr>
                                <Td colspan="7" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No Product added')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {products.map((info) => (
                                <Tr>
                                  <Td className="font-weight-bold">{info.title}</Td>
                                  <Td>{(info.validity) ? info.validity : 'NA'} </Td>
                                  <Td>{(info.durability_date) ? info.durability_date : 'NA'}</Td>
                                  <Td>{(info.is_active === 1) ? <span className='text-success'> {I18n.t('Active')} </span> : <span className='text-danger'> {I18n.t('Inactive')} </span>}</Td>

                                  <Td>
                                    <div className>
                                      <Link to={'/edit-infoproduct/' + info.id} title={I18n.t("Edit")}>
                                        <a className="items-center mr-1 btn-outline-dark btn btn-sm" href>
                                          <i className="far fa-edit" /> </a>
                                      </Link>
                                      <a className="flex mr-1 items-center btn btn-sm btn-outline-danger" title={I18n.t("Delete")}
                                        onClick={e => {
                                          // deleteProduct(info.id)
                                          setTempVarId(info.id)
                                          setIsDelete(true)

                                        }}
                                      >
                                        <i className="far fa-trash-alt" /> </a>
                                      <a className="flex btn btn-sm btn-outline-primary" 
                                        onClick=
                                        {e => { activeProduct(info.id, info.is_active) }}
                                      >
                                        {(info.is_active === 0) ? <i className="fas fa-play" title={I18n.t("Inactive")}/> : <i className="fa fa-pause"  title={I18n.t("Active")}/>}
                                      </a>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                      {is_delete === true ? (
                        <SweetAlert
                          warning
                          showCancel
                          confirmBtnText="Yes"
                          confirmBtnBsStyle="danger"
                          title={I18n.t('Are you sure?')}
                          onConfirm={(e) => {
                            deleteProduct()
                            setIsDelete(false)
                            // window.location.reload(false)
                          }}
                          onCancel={(e) => {
                            setIsDelete(false)
                            setTempVarId('')
                          }}
                          focusCancelBtn
                        >
                          Do you want to delete this product?
                        </SweetAlert>
                      ) : ([]
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </body >

    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoProduct);