import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
const Navs = () => {
  return (
    <>
      <div className="d-block d-sm-none">
        <Navbar collapseOnSelect fixed="top" expand="lg" bg="pink" variant="light">
          <a class="sidebar-brand d-flex" >
            <h3 class="sidebar-brand-text" style={{ color: '#bc972d', fontWeight: '700' }}>On Global </h3>
          </a>
          <span className="d-block d-md-none language_select">
            <LanguageSwitcher />
          </span>

          <div className="ml-auto">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link href="">
                  <Link to="/dashboard">
                    <i className="fas fa-fw fa-tachometer-alt" /> {I18n.t('DashBoard')}
                  </Link>
                </Nav.Link>
                <Nav.Link href="">
                  <Link to="/infoproduct">
                    <i className="fas fa-fw fa-info" />  {I18n.t('InfoProduct')}
                  </Link>
                </Nav.Link>
                {/* <Nav.Link href="">
                  <Link to="/content">
                    <i className="fas fa-fw fa-table" /> {I18n.t('Pricing')}
                  </Link>
                </Nav.Link> */}
                <Nav.Link href="">
                  <Link to="/module">
                    <i className="fas fa-fw fa-table" />  {I18n.t('Module')}
                  </Link>
                </Nav.Link>

                <Nav.Link href="">
                  <Link to="/offers">
                    <i className="fas fa-fw fa-chart-line" /> {I18n.t('Offers')}
                  </Link>
                </Nav.Link>

                <Nav.Link href="">
                  <Link to="/Live">
                    <i className="fas fa-fw fa-wifi" /> {I18n.t('Live')}
                  </Link>
                </Nav.Link>
                <Nav.Link >
                  <Link to="/certificates">
                    <i className="fas fa-fw fa-graduation-cap" />
                    <span>{I18n.t('Certificates')}</span>
                  </Link>
                </Nav.Link>
                <Nav.Link >
                  <Link to="/cross-sell">
                    <i className="fab fa-fw fa-sellsy" />
                    <span>{I18n.t('Cross Sell')}</span>
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link to="/interactivity">
                    <i className="fab fa-fw fa-sellsy" />
                    <span>{I18n.t('Interactivity')}</span>
                  </Link>
                </Nav.Link>
                <Nav.Link  >
                  <Link to={'/cross-sell-assignments'}>
                    <i className="fab fa-fw fa-sellsy" />
                    <span>{I18n.t('Cross Sell Assignments')}</span>
                  </Link>
                </Nav.Link>
                <Nav.Link  >
                  <Link to={'/cross-sell-assignments-event'}>
                    <i className="fab fa-fw fa-sellsy" />
                    <span>{I18n.t('Cross Sell Assignments Event')}</span>
                  </Link>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </>
  )
}
export default Navs;