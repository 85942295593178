import React from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom'
import TopNav from '../components/TopNav';

const ContentAdd = () => {
    return (
        <>
            <body id="page-top">
                <TopNav />
                <div id="wrapper">

                    <Leftbar />

                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">

                            <Topbar />

                            <div className="container-fluid">
                                <div className="page-title">
                                    <div className="row">
                                        <div className="col-6">
                                            <h3>Content Add</h3>
                                        </div>
                                        <div className="col-6 ">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="/dashboard" data-bs-original-title="" title="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                    </a>
                                                </li>
                                                <li className="breadcrumb-item"><Link to="/content">Content</Link> </li>
                                                <li className="breadcrumb-item">Content Add </li>                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <div className="card-body">
                                                <form className="needs-validation" noValidate>
                                                    <div className="row g-3">
                                                        <div className="col-md-4">
                                                            <label className="form-label" htmlFor="validationCustom01">Title</label>
                                                            <input className="form-control" id="validationCustom01" type="text" defaultValue="Title" required data-bs-original-title title />
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="form-label" htmlFor="validationCustom04">Infoproduct</label>
                                                            <select className="form-control" id="validationCustom04" required>
                                                                <option selected disabled value>Choose...</option>
                                                                <option>Infoproduct 1 </option>
                                                                <option>Infoproduct 2 </option>
                                                                <option>Infoproduct 3 </option>
                                                                <option>Infoproduct 4 </option>
                                                                <option>Infoproduct 5 </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row g-3">
                                                        <div className="col-md-4">
                                                            <br />
                                                            <button className="btn btn-primary" type="submit" data-bs-original-title title>Submit form</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}
export default ContentAdd;