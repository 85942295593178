import React, { useState, useEffect } from 'react';
import Leftbar from '../components/Leftbar';
import Topbar from '../components/Topbar';
import { Link } from "react-router-dom";
import Footer from '../components/Footer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TopNav from '../components/TopNav';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, getLiveEdit, getOffersListing, instructorProfile } from '../Utils/utils'
import Alert from 'react-bootstrap/Alert'
import moment from 'moment'
const EditLive = (props) => {
  const [start_date, setStartDate] = useState(new Date());
  const [start_date1, setStartDate1] = useState(new Date());
  const [start_date2, setStartDate2] = useState(new Date());

  const [title, setTitle] = useState('');
  const [consider_for_certificate, setConsiderForCertificate] = useState(0);
  const [send_emai_notification, setSendEmaiNotification] = useState(0);
  const [free_content, setFreeContent] = useState(0);
  const [bonus_content, setBonusContent] = useState(0);
  const [link, setLink] = useState('');
  const [enable_watermark, setEnableWatermark] = useState(0);
  const [lunch_date_time, setLunchDateTime] = useState('');
  const [media_id, setMediaId] = useState('');
  const [start_time, setStartTime] = useState('');
  const [embed_link, setEmbedLink] = useState('');
  const [publication_date, setPublicationDate] = useState('');
  const [pubImmediate, setPubImmediate] = useState('');
  const [pubDate, setPubDate] = useState('');
  const [launchDate, setLaunchDate] = useState('');
  const [launchImmediate, setLaunchImmediate] = useState('');
  const [is_selected, setIsSelected] = useState(false);
  const [launch_selected, setLaunchSelected] = useState(false);
  const [live_image_url, setLiveImageUrl] = useState(null);
  const [youtube_embed_link, setYoutubeEmbedLink] = useState('');
  const [event_duration, setEventDuration] = useState(2);
  const [time, setTime] = useState('');

  const [offer_list, setOfferList] = useState([]);
  const [offers, setOffers] = useState([]);
  const [speaker_name, setSpeakerName] = useState('');
  const [speaker_designation, setSpeakerDesignation] = useState('');
  const [address, setAddress] = useState('');
  const [event_detail, setEventDetail] = useState('');
  useEffect(async () => {
    props.toggleLoader(true)
    var get_live_edit = await getLiveEdit(props.match.params.id);
    setTitle(get_live_edit.title);
    setLink(get_live_edit.link);
    setEnableWatermark(get_live_edit.enable_watermark);
    setConsiderForCertificate(get_live_edit.consider_for_certificate);
    setFreeContent(get_live_edit.free_content);
    setBonusContent(get_live_edit.bonus_content);
    setSendEmaiNotification(get_live_edit.send_emai_notification);
    setEmbedLink(get_live_edit.embed_link);
    setLunchDateTime(get_live_edit.lunch_date_time);
    setStartDate1(new Date(get_live_edit.lunch_date_time));
    setPublicationDate(get_live_edit.publication_date);
    setStartDate(new Date(get_live_edit.publication_date));
    setPubImmediate(get_live_edit.pubImmediate);
    setPubDate(get_live_edit.pubDate);
    setLaunchDate(get_live_edit.launchDate);
    setLaunchImmediate(get_live_edit.lunch_date_time);
    setOffers(get_live_edit.offer_ids);
    setYoutubeEmbedLink(get_live_edit.youtube_embed_link);
    setEventDuration(get_live_edit.event_duration);
    setSpeakerName(get_live_edit.speaker_name);
    setSpeakerDesignation(get_live_edit.speaker_designation);
    setAddress(get_live_edit.address);
    setEventDetail(get_live_edit.event_detail);
    var get_offer_listing = await getOffersListing();
    setOfferList(get_offer_listing);
    console.log(get_offer_listing);
    if (get_live_edit.media !== null) {
      setMediaId(get_live_edit.media_id);
      setLiveImageUrl(get_live_edit.media.url);
    }
    props.toggleLoader(false)
  }, [])

  const email = () => {
    setSendEmaiNotification(1);
  }
  const is_free_content = () => {
    setFreeContent(1);
  }

  const is_bonus_content = () => {
    setBonusContent(1);
  }

  const certification = () => {
    setConsiderForCertificate(1);
  }
  const is_enable_watermark = () => {
    setEnableWatermark(1);
  }
  const selected = () => {
    setPubDate(0);
    setPubImmediate(1);
    setIsSelected(true);
    setStartDate(new Date());
  }
  const selected2 = () => {
    setIsSelected(false);
    setPubImmediate(0);
  }
  const selected3 = () => {
    setLaunchDate(0);
    setLaunchImmediate(1);
    setLaunchSelected(true);
    setStartDate1(new Date());
  }
  const selected4 = () => {
    setLaunchSelected(false);
    setLaunchImmediate(0);
  }

  const uploadImage = (e) => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    const formData = new FormData();
    formData.append(
      "file",
      e.target.files[0],
      e.target.files[0].name
    );
    axios.post(path + '/product/topic/image_upload', formData, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    }).then((success) => {
      props.toggleLoader(false);
      setMediaId(success.data.data.id);
    }).catch((error) => {
      props.toggleLoader(false);
      if (error.response.status === 401) {
        window.location.href = '/';
      }
      alert('There was some error in uploading pdf');
    })
  }

  const selectOffers = (e, offer_id) => {
    console.log(e.target.checked, offer_id);
    var temp_array = offers;
    if (e.target.checked === true) {
      //push to temp array to add element
      temp_array.push(offer_id);
    } else {
      // remove element from array
      var index = temp_array.indexOf(offer_id);
      if (index > -1) {
        temp_array.splice(index, 1);
      }

    }
    console.log(temp_array)
    setOffers(temp_array);
  }
  const selectedOffers = () => {
    var array = [offers]
    array.includes()
  }
  const updateLive = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/product/livecontent/update/' + props.match.params.id, {
      title: title,
      consider_for_certificate: consider_for_certificate,
      send_emai_notification: send_emai_notification,
      free_content: free_content,
      bonus_content: bonus_content,
      link: link,
      enable_watermark: enable_watermark,
      lunch_date_time: moment(start_date1).format("YYYY-MM-DD HH:mm:ss"),
      media_id: media_id,
      start_time: start_time,
      embed_link: embed_link,
      publication_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
      offers: offers,
      youtube_embed_link: youtube_embed_link,
      event_duration: event_duration,
      speaker_name: speaker_name,
      speaker_designation: speaker_designation,
      event_detail: event_detail,
      address: address,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Event Updated Successfully');
        setTimeout(() => window.location.href = "/live", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var user_login = await instructorProfile();
    if (user_login !== null) {
      setTime(user_login.timezone)
    }
    props.toggleLoader(false);
  }, [])
  return (
    <>
      <body id="page-top">
        <TopNav />
        <div id="wrapper">

          <Leftbar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid ">
                <div className="page-title">
                  <div className="row">
                    <div className="col-5 pr-0">
                      <h3>{I18n.t('Edit Live Event')}</h3>
                    </div>
                    <div className="col-7">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard" data-bs-original-title="" title="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                          </Link>
                        </li>
                        <li className="breadcrumb-item"><Link to="/live">{I18n.t('Live')}</Link></li>
                        <li className="breadcrumb-item active"> {I18n.t('Edit Live Event')}</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <form className="needs-validation" novalidate="">
                          <div className="row g-3">
                            <div className="col-md-5">
                              <label className="form-label" for="validationCustom01">{I18n.t('Live Title')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setTitle(e.target.value)} value={title} />
                            </div>
                            <div className="col-md-3">
                              <label className="form-label" for="validationCustom04">{I18n.t('Duration')}</label>
                              <select className="form-control" id="validationCustom04" required=""
                                onChange={e => setEventDuration(e.target.value)} value={event_duration}>
                                <option value="1">1 hour</option>
                                <option selected value="2">2 hour</option>
                                <option value="3">3 hour</option>
                                <option value="4">4 hour</option>
                                <option value="5">5 hour</option>
                                <option value="6">6 hour</option>
                                <option value="7">7 hour</option>
                                <option value="8">8 hour</option>
                                <option value="9">9 hour</option>
                                <option value="10">10 hour</option>
                              </select>
                            </div>
                            <div className="col-md-4 my-1" >
                              <label className="form-label" for="validationCustom01">{I18n.t('Promotional Image')}</label>
                              <input
                                accept=".jpg,.jpeg,.png"
                                id="validationCustom01"
                                type="file"
                                required=""
                                data-bs-original-title=""
                                title=""
                                className="form-control"
                                onChange={e => uploadImage(e)}
                              />
                              <div className="mt-1">
                                {(live_image_url !== null) ? <>
                                  <label>
                                    {I18n.t('Image Download')}
                                  </label>
                                  <a download
                                    href={live_image_url} target="_blank"
                                    className="btn btn-light ml-2 btn-sm"
                                    data-toggle="tooltip" data-original-title="download url">
                                    <i className="fa fa-download" />
                                  </a>
                                </> : ''}
                              </div>
                            </div>


                          </div>

                          <div className="row">
                            <div className="col-xl-10">
                              <div className="row">
                                <div className="col-md-4 my-1">
                                  <div className="form-check checkbox mb-0">
                                    <input className="form-check-input" id="checkbox2" type="checkbox" data-bs-original-title="" title=""
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setConsiderForCertificate(1)
                                        } else {
                                          setConsiderForCertificate(0)
                                        }
                                      }}
                                      checked={consider_for_certificate} />
                                    <label className="form-check-label" for="checkbox2">{I18n.t('Consider For Certificate')}</label>
                                  </div>
                                </div>
                                <div className="col-md-4 my-1">
                                  <div className="form-check checkbox mb-0">
                                    <input className="form-check-input" id="checkbox3" type="checkbox" data-bs-original-title="" title=""
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setSendEmaiNotification(1)
                                        } else {
                                          setSendEmaiNotification(0)
                                        }
                                      }}
                                      checked={send_emai_notification} />
                                    <label className="form-check-label" for="checkbox3">{I18n.t('Send e-mail Notification')}</label>
                                  </div>

                                </div>
                                <div className="col-md-4 my-1">
                                  <div className="form-check checkbox mb-0">
                                    <input className="form-check-input" id="checkbox4" type="checkbox" data-bs-original-title="" title=""
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setFreeContent(1)
                                        } else {
                                          setFreeContent(0)
                                        }
                                      }}
                                      checked={free_content} />
                                    <label className="form-check-label" for="checkbox4">
                                      {I18n.t('Free Content')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4 my-1">
                                  <div className="form-check checkbox mb-0">
                                    <input className="form-check-input" id="checkbox5" type="checkbox" data-bs-original-title="" title=""
                                      onChange={e => {
                                        if (e.target.checked) {
                                          setBonusContent(1)
                                        } else {
                                          setBonusContent(0)
                                        }
                                      }}
                                      checked={bonus_content} />
                                    <label className="form-check-label" for="checkbox5">
                                      {I18n.t('Bonus Content')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4 my-1" >
                                  <div className="form-check checkbox mb-0">
                                    <input className="form-check-input" id="checkbox6" type="checkbox" data-bs-original-title="" title=""

                                      onChange={e => {
                                        if (e.target.checked) {
                                          setEnableWatermark(1)
                                        } else {
                                          setEnableWatermark(0)
                                        }
                                      }}
                                      checked={enable_watermark} />
                                    <label className="form-check-label" for="checkbox6">
                                      {I18n.t('Enable Watermark')}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">{I18n.t('Speaker Name')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setSpeakerName(e.target.value)} placeholder={I18n.t('Speaker Name')}
                                value={speaker_name} />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">{I18n.t('Speaker Designation')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setSpeakerDesignation(e.target.value)} placeholder={I18n.t('Speaker Designation')}
                                value={speaker_designation} />
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">{I18n.t('Address')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setAddress(e.target.value)} placeholder={I18n.t('Address')}
                                value={address} />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" for="validationCustom01">{I18n.t('Event Detail')}</label>
                              <textarea className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setEventDetail(e.target.value)} placeholder={I18n.t('Event Detail')}
                                value={event_detail}></textarea>
                            </div>
                          </div>
                          <div className="row my-3">

                            <div className="col-md-6">
                              <label className="form-label font-weight-bold" for="validationCustom03">{I18n.t('Publish Date / Time') + '(' + (time) + ')'}</label>
                              <div className="row">
                                <div className="col-md-7">
                                  <div className="mb-3 custom-radio-ml">
                                    <div className="form-check radio radio-primary">
                                      <input className="form-check-input" id="radio1" type="radio" name="radio1" value="option1" data-bs-original-title="" title=""
                                        onClick={selected}
                                        checked={pubImmediate} />
                                      <label className="form-check-label" for="radio1">{I18n.t('Immediately')}</label>
                                    </div>
                                    <div className="form-check radio radio-primary">
                                      <input className="form-check-input" id="radio2" type="radio" name="radio1" value="option1" data-bs-original-title="" title=""
                                        defaultChecked={true}
                                        onClick={selected2}
                                        checked={!pubImmediate} />
                                      <label className="form-check-label" for="radio2">{I18n.t('Date / Time')}</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5 align-self-center pl-md-0">
                                  <div className="">
                                    {is_selected === true ? <DatePicker
                                      selected={start_date}
                                      dateFormat="yyyy-MM-dd HH:mm"

                                      customInput={
                                        <input type="text" className="form-control" placeholder={I18n.t('Date')} />
                                      }
                                      disabled
                                    />
                                      : <DatePicker
                                        selected={start_date}
                                        onChange={(date) => setStartDate(date)}

                                        timeInputLabel="Time:"
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showTimeInput
                                        customInput={
                                          <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                            onChange={e => setPublicationDate(e.target.value)}
                                            value={publication_date} />
                                        }
                                      />}
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div className="col-md-6">
                              <label className="form-label font-weight-bold" for="validationCustom03">{I18n.t('Launch Date / Time') + '(' + (time) + ')'}</label>
                              <div className="row">
                                <div className="col-md-7">
                                  <div className="mb-3 custom-radio-ml">
                                    <div className="form-check radio radio-primary">
                                      <input className="form-check-input" id="radio3" type="radio" name="radio3" value="option2" data-bs-original-title="" title=""
                                        onClick={selected3}
                                        checked={launchImmediate} />
                                      <label className="form-check-label" for="radio3">{I18n.t('Immediately')}</label>
                                    </div>
                                    <div className="form-check radio radio-primary">
                                      <input className="form-check-input" id="radio4" type="radio" name="radio3" value="option2"
                                        data-bs-original-title="" title=""
                                        defaultChecked={true}
                                        // onChange={e => setLaunchDate(e.target.value)}
                                        onClick={selected4}
                                        checked={!launchImmediate} />
                                      <label className="form-check-label" for="radio4">{I18n.t('Date / Time')}</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5 align-self-center pl-md-0">
                                  {launch_selected === true ? <DatePicker
                                    selected={start_date1}
                                    onChange={(date) => setStartDate1(date)}
                                    dateFormat="yyyy-MM-dd HH:mm"

                                    customInput={
                                      <input type="text" className="form-control" placeholder={I18n.t('Date')} />
                                    } disabled
                                  />
                                    : <DatePicker
                                      selected={start_date1}
                                      onChange={(date) => setStartDate1(date)}

                                      timeInputLabel="Time:"
                                      dateFormat="yyyy-MM-dd HH:mm"
                                      showTimeInput
                                      customInput={
                                        <input type="text" className="form-control" placeholder={I18n.t('Date')}
                                          onChange={e => setLunchDateTime(e.target.value)}
                                          value={lunch_date_time}
                                        />
                                      }
                                    />}
                                </div>
                              </div>
                            </div>

                          </div>
                          <br />
                          <div className="row g-3">
                            <div className="col-md-4 my-1">
                              <label className="form-label" for="validationCustom01">{I18n.t('Embedded Link Zoom')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setEmbedLink(e.target.value)} value={embed_link} />
                            </div>

                            <div className="col-md-4 my-1">
                              <label className="form-label" for="validationCustom01">{I18n.t('Youtube Embed Link')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setYoutubeEmbedLink(e.target.value)} value={youtube_embed_link} />
                            </div>
                            <div className="col-md-4 my-1">
                              <label className="form-label" for="validationCustom01">{I18n.t('Vimeo Link')}</label>
                              <input className="form-control" id="validationCustom01" type="text" data-bs-original-title="" title=""
                                onChange={e => setLink(e.target.value)} value={link} />
                            </div>
                          </div>
                          <br />
                          <div className="row g-3 mt-3 mt-md-0">
                            <div className="col-md-12">
                              <label className="form-check-label" for="checkbox7">{I18n.t('Offers')}   </label>
                              <div className="">
                                <div className="row">
                                  {
                                    (offer_list.length === 0) ?
                                      <div className="mt-1">
                                        <Alert key={'alert'} variant={'danger'}>
                                          {I18n.t('There are no Offers at the moment to add to this offer You can add them later!!')}
                                        </Alert>
                                      </div> : ''
                                  }
                                  {offer_list.map((offer, index) => (
                                    <div className="col-sm-4 m-2">
                                      <div className="form-check checkbox mb-0">
                                        <input className="form-check-input"
                                          id={"offer-list-" + index}
                                          type="checkbox"
                                          data-bs-original-title=""
                                          title=""
                                          onClick={e => { selectOffers(e, offer.id) }}
                                          defaultChecked={(offers !== undefined) ?
                                            (offers.includes(offer.id)) ? true : false : false
                                          } />
                                        <label className="form-check-label" for={"offer-list-" + index}>{offer.name}</label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="row my-1">
                            <div className="col-md-2" disabled>
                              <label className="form-label" for="validationCustom03">{I18n.t('Cross Sell')}</label>
                              <div className="col">
                                <div className="mb-3 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio5" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio5">{I18n.t('No')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio6" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio6">{I18n.t('Random')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio7" type="radio" name="radio3" value="option1" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio7">{I18n.t('Specific')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 align-self-center" disabled>
                              <select className="form-select" id="validationCustom04" required="">
                                <option selected="" disabled="" value="">Digital Marketing</option>
                                <option>Freedom Monthly</option>
                                <option>NITRO10X 2021 + Marketing</option>
                              </select>
                            </div>
                            <div className="col-md-3  align-self-center" disabled>
                              <label className="form-label" for="validationCustom03">{I18n.t('Start Time')}</label>
                              <div className="col">
                                <div className="mb-3 custom-radio-ml">
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio7" type="radio" name="radio4" value="option5" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio7">{I18n.t('Immediately')}</label>
                                  </div>
                                  <div className="form-check radio radio-primary">
                                    <input className="form-check-input" id="radio8" type="radio" name="radio4" value="option5" data-bs-original-title="" title="" />
                                    <label className="form-check-label" for="radio8">{I18n.t('starting From')}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3" disabled>
                              <label className="form-label">{I18n.t('Enter Start time')}</label>
                              <DatePicker
                                selected={start_date2}
                                onChange={(date) => setStartDate2(date)}
                                dateFormat="yyyy-MM-dd HH:mm"
                                showTimeSelect
                                dateFormat="Pp"
                                customInput={
                                  <input type="text" className="form-control" placeholder=" Date" />
                                }
                              />
                            </div>
                          </div> */}
                        </form>
                        <div className="card-footer p-2">
                          <button className="btn btn-primary" type="submit" data-bs-original-title="" title=""
                            onClick={updateLive}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i> {I18n.t('Save Changes')} </button>

                          &nbsp;
                          <Link to="/live">
                            <button className="btn btn-dark" type="submit" data-bs-original-title="" title="">
                              <i className="fa fa-undo" aria-hidden="true"></i> {I18n.t('Return')}</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLive);